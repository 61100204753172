import PrimaryButton from "components/button/PrimaryButton";
import Card from "components/card/Card";
import Row from "components/custom/Row";
import BaseModal from "components/modal/BaseModal";
import { NeutralOverlineText0, NeutralOverlineText100, NeutralTitleSmallTextBlack } from "components/text/Text";
import TeslaKeySendSmsForm from "components/vehicle-components/tesla/controls/TeslaKeySendSmsForm";

import React, { useState } from "react";

type Props = {};

function TeslaPinToDriveEditor({}: Props) {
  const [isSendSmsModalOpen, setIsSendSmsModalOpen] = useState(false);

  return (
    <>
      <BaseModal
        className="wide-sm"
        isCloseIconVisible
        isModalOpen={isSendSmsModalOpen}
        setIsModalOpen={setIsSendSmsModalOpen}
      >
        <TeslaKeySendSmsForm onFormReset={() => setIsSendSmsModalOpen(false)} />
      </BaseModal>


      <Card className="bg-cherry-1000 mb-4">
        <Row className="align-items-center">
          <div className={"warning-dot mr-1"} />
          <NeutralTitleSmallTextBlack>Enable Third-Party Virtual Key</NeutralTitleSmallTextBlack>
        </Row>

        <NeutralOverlineText100>
          Set up your Third-Party Virtual Key to access Pin to Drive and other vehicle features. This one-time setup in
          the Tesla app ensures:
        </NeutralOverlineText100>
        <NeutralOverlineText100>
          &#x2022; Full access to vehicle controls
        </NeutralOverlineText100>
        <NeutralOverlineText100>
          &#x2022; Uninterrupted connection
        </NeutralOverlineText100>
        <NeutralOverlineText100>
          &#x2022; Seamless integration
        </NeutralOverlineText100>

        <Row className="justify-between align-items-center mt-2">
          <NeutralOverlineText100>Tap here to receive your Bluedot setup link.</NeutralOverlineText100>
          <PrimaryButton
            isSmall={true}
            text="Send message"
            onClick={() => setIsSendSmsModalOpen(true)}
          />
        </Row>
      </Card>
    </>
  )
    ;
}

export default TeslaPinToDriveEditor;
