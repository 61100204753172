import restApiClient from "./client";
import { generateApiEndpoint } from "utils/api.utils";
import { PaginatedApiResponse } from "../types/api.types";
import {
  OrganizationDepotChargingLocation,
  OrganizationDepotChargingLocationPayload
} from "../types/depot-charging-location.types";

const ServiceName = "depot-charging-locations";


/**
 * ADD: '/admin/depot-charging-locations'
 */
export async function addDepotChargingLocationAsync(payload: OrganizationDepotChargingLocationPayload) {
  const endpoint = generateApiEndpoint(`${ServiceName}`);
  await restApiClient.post<Promise<void>>(endpoint, payload);
}

/**
 * GET: '/admin/depot-charging-locations'
 */
export async function getDepotChargingLocationListAsync(query?: string) {
  const endpoint = generateApiEndpoint(ServiceName);
  return (await restApiClient.get<PaginatedApiResponse<OrganizationDepotChargingLocation>>(endpoint + query)).data;
}

/**
 * PATCH: '/admin/depot-charging-locations'
 */
export async function updateDepotChargingLocationAsync(id: string, payload: OrganizationDepotChargingLocationPayload) {
  const endpoint = generateApiEndpoint(`${ServiceName}/${id}`);
  await restApiClient.patch<Promise<void>>(endpoint, payload);
}

/**
 * PATCH: '/admin/depot-charging-locations'
 */
export async function deleteDepotChargingLocationAsync(id: string) {
  const endpoint = generateApiEndpoint(`${ServiceName}/${id}`);
  await restApiClient.delete<Promise<void>>(endpoint);
}