import React, { useMemo } from "react";
import { InvoicePaymentState } from "types/invoice.types";

import Badge from "./Badge";
import { ChargingPaymentState } from "../../types/charging-sessions.types";

export default function PaidStatusBadge({ status }: { status: InvoicePaymentState | ChargingPaymentState }) {
  const badgeContent = useMemo(() => {
    const commonContainerStyle = "px-2 rounded-xxxl center";
    switch (status) {

      case InvoicePaymentState.UNPAID:
        return {
          text: "Unpaid",
          textStyle: `text-cherry-200 `,
          containerStyle: `bg-cherry-1000 ${commonContainerStyle}`
        };

      case InvoicePaymentState.PAID:
        return {
          text: "Paid",
          textStyle: `text-positive-200`,
          containerStyle: `bg-positive-950 ${commonContainerStyle}`
        };


      default:
        return {
          text: "Canceled",
          textStyle: "text-neutral-200",
          containerStyle: `bg-neutral-900 ${commonContainerStyle}`
        };
    }
  }, [status]);
  return <Badge {...badgeContent} />;
}
