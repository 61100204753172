import React, { Dispatch, SetStateAction } from "react";
import {
  BlackBodyText100,
  BlackOverlineText300,
  NeutralBodySmallText300,
  NeutralTitleSmallTextBlack
} from "../text/Text";
import Col from "../custom/Col";
import Row from "../custom/Row";
import IosSwitch from "../input/switch/IosSwitch";

type Props = {
  readonly isReimbursementActive?: boolean;
  readonly onStatusSwitchChanged?: Dispatch<SetStateAction<boolean | undefined>>
  readonly label: string;
  readonly description?: string;
  readonly isSmallTitleLabel?: boolean
};

export default function DriverReimbursementEditor({
                                                    label,
                                                    description,
                                                    isReimbursementActive,
                                                    onStatusSwitchChanged,
                                                    isSmallTitleLabel = true
                                                  }: Props) {
  return (
    <div className="mt-2 h-auto">
      <Row className="justify-between mb-2">
        <Col>
          {isSmallTitleLabel ? <>     <NeutralTitleSmallTextBlack>{label}</NeutralTitleSmallTextBlack>
            <NeutralBodySmallText300>{description}</NeutralBodySmallText300></> : <>
            <BlackBodyText100>{label}</BlackBodyText100>
            <BlackOverlineText300>{description}
            </BlackOverlineText300></>}

        </Col>


        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/*@ts-ignore */}
        <IosSwitch isChecked={isReimbursementActive} onToggle={() => onStatusSwitchChanged(!isReimbursementActive)} />
      </Row>
    </div>

  );
}