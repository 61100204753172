import React, { useCallback, useEffect, useMemo, useState } from "react";
import DriverReimbursementEditor from "../reimbursement/ReimbursementToggleEditor";
import EditorModal from "../modal/EditorModal";
import { useDriverDetailsReimbursementStates } from "../../hooks/driver.hooks";
import { handleApiErrorResponse } from "../../utils";
import { useParams } from "react-router";
import { useAlertManager } from "../../hooks/alert.hooks";
import { useForm } from "react-hook-form";
import Card from "../card/Card";
import Col from "../custom/Col";
import {
  BlackBodyText100,
  BlackBodyText200,
  BlackCaptionText0,
  BlackOverlineText300,
  NeutralBodyText300,
  NeutralTitleMediumTextBlack
} from "../text/Text";
import AlertConfirmDialog from "../notification/AlertConfirmDialog";
import CancelButton from "../button/CancelButton";
import PrimaryButton from "../button/PrimaryButton";
import { useIntercomTrigger } from "../../hooks/intercom.hooks";
import HorizontalLine from "../line/HorizontalLine";
import Row from "../custom/Row";
import PencilIcon from "../icon/icons/PencilIcon";
import { ReimbursementFormState } from "types/reimbursement.types";
import ReadonlyReimbursementMap from "../reimbursement/ReadonlyReimbursementMap";
import { getDriverDetailsReimbursementInfo, getFormattedCentForDefaultValue } from "../../utils/driver.utils";
import LoadingSkeleton from "../loading/LoadingSkeleton";
import { DriverHookStates } from "../../types/driver.types";
import DriverFormReimbursementFixedEnergyRateInput from "./form/DriverFormReimbursementFixedEnergyRateInput";
import { getFormattedNumberForUsdByCent } from "../../utils/format.utils";
import DriverDetailsPageReimbursementOnlyMapEditor from "./DriverDetailsPageReimbursementOnlyMapEditor";
import DriverDetailsPageEnableReimbursementModal from "./DriverDetailsPageEnableReimbursementModal";
import OutlinedButtonWithIcon from "../button/OutlinedButtonWithIcon";
import InfoBadge from "../badge/InfoBadge";

type Props = {
  driverHook: DriverHookStates
}

const DriverDetailsPageReimbursementSettings = ({ driverHook }: Props) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const driverFormReimbursementStepStates = useDriverDetailsReimbursementStates(driverHook);
  const readonlyReimbursementStates = useDriverDetailsReimbursementStates(driverHook);

  const { isLoading } = driverHook;

  const {
    isReimbursementActive, fixedRate, rectangleBoundsAndCenter
  } = getDriverDetailsReimbursementInfo(driverHook);

  const [isReimbursementDeactivationConfirmationOpen, setIsReimbursementDeactivationConfirmationOpen] = useState(false);
  const [isReimbursementFormModalOpen, setIsReimbursementFormModalOpen] = useState(false);

  const [isFixedRateEditorModalOpen, setIsFixedRateEditorModalOpen] = useState(false);
  const [isMapEditorModalOpen, setIsMapEditorModalOpen] = useState(false);


  const { handleSubmit, errors, reset, register, control } = useForm<ReimbursementFormState>({
    mode: "all"
  });


  /**
   *
   */
  useEffect(() => {
    if (isLoading || !fixedRate) return;

    reset({ fixedRate: getFormattedNumberForUsdByCent(fixedRate) });
  }, [fixedRate, isLoading, reset]);


  const { triggerIntercom } = useIntercomTrigger();

  const { handleOpenSuccessAlert, handleOpenErrorAlert } = useAlertManager();
  const { driverId } = useParams<{ driverId: string }>();


  const commonFormStepProps = useMemo(() => {
    return {
      register, control, errors
    };
  }, [register, control, errors]);

  const handleStatusSwitchToggled = useCallback(() => {
    if (isReimbursementActive) {
      setIsReimbursementDeactivationConfirmationOpen(true);
    } else {
      setIsReimbursementFormModalOpen(true);
    }
  }, [isReimbursementActive]);


  const handleSubmitDeactivateAsync = useCallback(async () => {
    try {
      setIsSubmitting(true);
      await driverFormReimbursementStepStates.handleSubmitDisableReimbursementCallback(driverId);
      handleOpenSuccessAlert("Reimbursement is disabled successfully!");
      driverHook.refetchDriver();
      setIsReimbursementDeactivationConfirmationOpen(false);
    } catch (e) {
      console.error(e);
      handleOpenErrorAlert(handleApiErrorResponse(e));
    } finally {
      setIsSubmitting(false);
    }
  }, [driverFormReimbursementStepStates, driverHook, driverId, handleOpenErrorAlert, handleOpenSuccessAlert]);


  const handleSubmitFixedEnergyPrice = useCallback(async (formData: ReimbursementFormState) => {
    try {
      setIsSubmitting(true);
      await driverFormReimbursementStepStates.handleSubmitUpdateReimbursementFixedEnergyPriceCallback(driverId, formData.fixedRate);
      handleOpenSuccessAlert("Reimbursement fixed energy price saved successfully!");

      setIsFixedRateEditorModalOpen(false);
      driverHook.refetchDriver();
    } catch (err) {
      handleOpenErrorAlert(handleApiErrorResponse(err));
      console.error(err);
    } finally {
      setIsSubmitting(false);
    }
  }, [driverFormReimbursementStepStates, driverHook, driverId, handleOpenErrorAlert, handleOpenSuccessAlert]);


  return (
    <>
      <AlertConfirmDialog
        title={`Disable Home Charging`}
        descriptionOverride={
          <>
            <BlackBodyText100>
              Are you sure you want to disable home charging?
              <br />
              Changing this status will result in the loss of your
              current settings, <br /> and no reimbursement receipt will be generated for this driver.
            </BlackBodyText100>
            <BlackBodyText200>
              If you need help or have feedback, please contact
              <span className="text-decoration-underline pointer-events-all clickable pl-1" onClick={triggerIntercom}>
                customer support
              </span>
              .
            </BlackBodyText200>
          </>
        }
        isDialogOpen={isReimbursementDeactivationConfirmationOpen}
        overrideButtons={
          <>
            <div className="mr-2">
              <CancelButton onClick={() => setIsReimbursementDeactivationConfirmationOpen(false)} />
            </div>

            <PrimaryButton
              type="button"
              text="Disable"
              isLoading={isSubmitting}
              onClick={handleSubmitDeactivateAsync}
            />
          </>
        }
      />

      {isReimbursementFormModalOpen && !isReimbursementActive &&
        <DriverDetailsPageEnableReimbursementModal
          driverHook={driverHook}
          setIsReimbursementFormModalOpen={setIsReimbursementFormModalOpen}
          isReimbursementFormModalOpen={isReimbursementFormModalOpen}
        />
      }


      {isFixedRateEditorModalOpen &&
        <EditorModal
          modalSize="wide-sm"
          setIsModalOpen={setIsFixedRateEditorModalOpen}
          isModalOpen={isFixedRateEditorModalOpen}
          onSubmit={handleSubmit(handleSubmitFixedEnergyPrice)}
          isLoading={isSubmitting}
        >
          <NeutralTitleMediumTextBlack>Home Charging Reimbursement Status</NeutralTitleMediumTextBlack>
          <NeutralBodyText300 className={"mb-3"}>Include driver for home charging reimbursement
            process.</NeutralBodyText300>
          <HorizontalLine />
          <Col md={"12"}>
            <DriverFormReimbursementFixedEnergyRateInput {...commonFormStepProps} />
          </Col>
        </EditorModal>
      }

      {isMapEditorModalOpen &&
        <DriverDetailsPageReimbursementOnlyMapEditor
          driverHook={driverHook}
          setIsMapEditorModalOpen={setIsMapEditorModalOpen}
          isMapEditorModalOpen={isMapEditorModalOpen} />
      }


      <Row>

        <Col md={"8"}>
          <Card>
            {isLoading ? <LoadingSkeleton count={4} /> : <>

              <DriverReimbursementEditor
                isSmallTitleLabel={false}
                onStatusSwitchChanged={handleStatusSwitchToggled}
                isReimbursementActive={isReimbursementActive}
                label={"Home charging reimbursement status"}
                description={"Include driver for home charging reimbursement process."} />

              {isReimbursementActive && (
                <>
                  <HorizontalLine />

                  <Row className="justify-between mb-2">
                    <Col>
                      <BlackBodyText100>Fixed rate</BlackBodyText100>
                      <BlackOverlineText300>This rate will remain the same until you change it</BlackOverlineText300>
                    </Col>


                    <Row className="align-items-center">
                      <BlackCaptionText0>{getFormattedCentForDefaultValue(fixedRate).value}/kWh</BlackCaptionText0>
                      <OutlinedButtonWithIcon
                        className={"ml-2"}
                        isSmall
                        leftIcon={<PencilIcon fill={"black"} />}
                        text={""}
                        onClick={() => setIsFixedRateEditorModalOpen(true)}
                      />
                    </Row>
                  </Row>
                </>
              )}
            </>}
          </Card>

          {isReimbursementActive && rectangleBoundsAndCenter && (
            <Card className={"mt-4"}>
              {isLoading ? <LoadingSkeleton count={4} /> :
                <ReadonlyReimbursementMap
                  {...readonlyReimbursementStates}
                  isReimbursementActive={true}
                  onMapEditButtonPressed={() => setIsMapEditorModalOpen(true)}
                />}
            </Card>
          )}
        </Col>
        <Col md={"4"}>
          {isReimbursementActive && <div className="pl-4">
            <InfoBadge
              className={"mt-0"}
              text={"Home charging reimbursement is currently available only for vehicles using Geotab. If you have activated HCR but it's not functioning, please ensure your vehicle is equipped with Geotab."} />
          </div>
          }
        </Col>
      </Row>
    </>
  );
};

export default DriverDetailsPageReimbursementSettings;