import OutlinedButtonWithIcon, { OutlinedButtonWithIconProps } from "components/button/OutlinedButtonWithIcon";
import MuiTooltip from "components/tooltip/MuiTooltip";
import { useTeslaInfoReadonly } from "hooks/data/vehicle-data-accessor.hooks";
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { setIsTeslaWakeUpModalOpen } from "redux/slices/teslaSlice";

type Props = OutlinedButtonWithIconProps & {
  isSupported: boolean;
  tooltipMessageOverride?: string
}
const TeslaControlledTooltipButton = ({ isSupported, tooltipMessageOverride, ...props }: Props) => {
  const { isSleeping } = useTeslaInfoReadonly();

  const getTooltipMessage = () => {
    if (!isSupported) return tooltipMessageOverride ?? "Virtual key must be added.";
    if (isSleeping) return tooltipMessageOverride ?? "Vehicle needs to wake up.";
    return null;
  };

  const tooltipMessage = getTooltipMessage();

  if (!tooltipMessage) {
    return <OutlinedButtonWithIcon
      isSmall={true}
      type={"button"}
      {...props}
    />;
  }

  return (
    <MuiTooltip title={tooltipMessage} placement="top">
      <div>
        <OutlinedButtonWithIcon
          isSmall={true}
          isDisabled={true}
          type={"button"}
          {...props}
        />
      </div>
    </MuiTooltip>
  );
};

export default TeslaControlledTooltipButton;