import React from "react";
import AddVehicleGeotabFlowForm from "./AddVehicleGeotabFlowForm";
import Col from "../../../../custom/Col";
import Row from "../../../../custom/Row";
import Card from "../../../../card/Card";
import VioletInfoBadge from "../../../../badge/VioletInfoBadge";
import { NeutralBodySmallText300 } from "../../../../text/Text";

const AddVehicleGeotabFlow = () => {

  return (
    <Row>
      <Col md={"8"} className={"pr-md-2"}>
        <AddVehicleGeotabFlowForm
        />
      </Col>
      <Col md={"4"} className={"pl-md-2"}>
        <Card>
          <VioletInfoBadge isNoWrap={false}
                           description={"Create a separate user in Geotab for the API user credentials rather than using an existing user’s credentials. That way, if you ever remove or change the password for the existing user it won’t affect the integration. Make sure that the Geotab user has all necessary permissions!"}
          />
          <NeutralBodySmallText300 className={'mt-4'}>VINs are correct for each asset in Bluedot and Geotab. VINs are used to automatically
            map Geotab devices to Bluedot, so an asset with an incorrect or missing VIN will need to be manually
            mapped.</NeutralBodySmallText300>
        </Card>

      </Col>

    </Row>
  );
};

export default AddVehicleGeotabFlow;