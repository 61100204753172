import { ChargerProviderDoughnutChart } from "components/charts/Chart";
import { useDashboardInsights } from "hooks/data/dashboard-data-accessor.hooks";
import { useMemo } from "react";
import { getProcessedChargingProviderGraphData } from "../../../utils/dashboard.utils";
import GraphPlaceholder from "../dashboard-placeholders/GraphPlaceholder";

export default function DashboardChargerProviderGraph() {
  const { insights } = useDashboardInsights();
  const chargingProvidersData = insights?.countByChargingProvider;

  const areThereAnyData = chargingProvidersData && chargingProvidersData.length > 0;

  const processedGraphData = useMemo(() => {
    if (!areThereAnyData) {
      return undefined;
    }
    return getProcessedChargingProviderGraphData(chargingProvidersData);
  }, [areThereAnyData, chargingProvidersData]);

  if (!processedGraphData) {
    return <GraphPlaceholder />;
  }

  const { data, labels, total: totalChargingProviders, backgroundColors } = processedGraphData;

  const doughnutData = {
    labels: labels,
    datasets: [
      {
        data: data,
        backgroundColor: backgroundColors
      }
    ]
  };

  return <ChargerProviderDoughnutChart data={doughnutData} totalChargers={totalChargingProviders} />;
}
