import React, { useCallback, useEffect, useState } from "react";
import { Polygon } from "types/location.types";
import { geocodeLocationAsync } from "../../services/location.services";
import { getRectangleBoundsAndCenter } from "../../utils/polygon.utils";
import LoadingSkeleton from "../loading/LoadingSkeleton";
import { useGoogleApiLoader } from "../../hooks/google-maps.hooks";

type Props = {
  polygon: Polygon
}

function DepotChargingLocationAddressColumnAsync({ polygon }: Props) {
  const { isLoaded: isGoogleApiLoaded } = useGoogleApiLoader();
  const [isLoading, setIsLoading] = useState(true);
  const [address, setAddress] = useState("");

  const handleSetAddressByPolygon = useCallback(async () => {
    setIsLoading(true);
    try {
      const boundsAndCenter = getRectangleBoundsAndCenter(polygon.coordinates);

      if (!boundsAndCenter) {
        return;
      }
      const center = boundsAndCenter.center;
      const geocodeResult = await geocodeLocationAsync(center.lat, center.lng);
      setAddress(geocodeResult.formatted_address);
    } catch (e: unknown) {
      console.error("error", e);
    } finally {
      setIsLoading(false);
    }
  }, [polygon.coordinates]);


  useEffect(() => {
    if (!isGoogleApiLoaded) {
      return;
    }
    handleSetAddressByPolygon();
  }, [handleSetAddressByPolygon, isGoogleApiLoaded]);


  if (isLoading) {
    return <div className={"w-200px"}><LoadingSkeleton count={1} height={20} /></div>;
  }

  return (
    <span title={address}>{address}</span>
  );
}

export default DepotChargingLocationAddressColumnAsync;