import { createSlice, PayloadAction, Slice } from "@reduxjs/toolkit";
import { TeslaControlsStatus, TeslaSliceState } from "types/tesla.types";
import { TeslaDrivePin, TeslaVehicleInfo } from "types/vehicle.types";

const initialState: TeslaSliceState = {
  helperSlice: {
    isWakeUpModalOpen: false,
    lastFetchedAt: null
  },
  controlsStatusSlice: {
    isLoading: false,
    controlsStatus: null
  },
  infoSlice: {
    info: null,
    isLoading: false,
    isSleeping: true
  },
  drivePinSlice: {
    drivePin: null,
    isLoading: false
  }
};

export const teslaSlice: Slice<TeslaSliceState> = createSlice({
    name: "tesla",
    initialState,
    reducers: {
      setIsTeslaWakeUpModalOpen: (state, action: PayloadAction<boolean>) => {
        state.helperSlice.isWakeUpModalOpen = action.payload;
      },
      setTeslaControlsStatus: (state, action: PayloadAction<TeslaControlsStatus>) => {
        state.controlsStatusSlice.controlsStatus = action.payload;
        state.controlsStatusSlice.isLoading = false;
      },
      setIsControlsStatusLoading: (state, action: PayloadAction<boolean>) => {
        state.controlsStatusSlice.isLoading = action.payload;
      },
      setTeslaInfo: (state, action: PayloadAction<TeslaVehicleInfo>) => {
        state.infoSlice.isSleeping = false;
        state.infoSlice.isLoading = false;
        state.infoSlice.info = action.payload;
        state.helperSlice.lastFetchedAt = new Date();
      },
      setIsTeslaSleeping: (state, action: PayloadAction<boolean>) => {
        state.infoSlice.isSleeping = action.payload;
      },
      setIsTeslaInfoLoading: (state, action: PayloadAction<boolean>) => {
        state.infoSlice.isLoading = action.payload;
      },
      setTeslaDrivePin: (state, action: PayloadAction<TeslaDrivePin>) => {
        state.drivePinSlice.drivePin = action.payload;
        state.drivePinSlice.isLoading = false;
      },
      setIsTeslaDrivePinLoading: (state, action: PayloadAction<boolean>) => {
        state.drivePinSlice.isLoading = action.payload;
      }
    }
  })
;

export const {
  setTeslaControlsStatus,
  setIsControlsStatusLoading,
  setTeslaInfo,
  setIsTeslaInfoLoading,
  setIsTeslaSleeping,
  setTeslaDrivePin,
  setIsTeslaDrivePinLoading,
  setIsTeslaWakeUpModalOpen
} = teslaSlice.actions;
export default teslaSlice.reducer;