import React from "react";
import Row from "../../custom/Row";
import DriverFromBackButton from "../../button/DriverFromBackButton";
import PrimaryButton from "../../button/PrimaryButton";
import ContinueButton from "../../button/ContinueButton";
import { getDriverFormButtonText, getDriverFormStepStatus } from "../../../utils/driver.utils";
import { DriverFormVehicleSetupStepStates, DriverNonFormState } from "../../../types/driver.types";
import { DriverFormReimbursementStepStateHooks } from "types/reimbursement.types";

type Props = {
  driverNonFormState: DriverNonFormState
  driverFormReimbursementStepStates: DriverFormReimbursementStepStateHooks,
  driverFormVehicleSetupStepStates: DriverFormVehicleSetupStepStates,
  handleCompleteFlow: () => void
  isLoading: boolean
}

const DriverFormButtonFooter = ({
                                  driverNonFormState,
                                  driverFormReimbursementStepStates,
                                  driverFormVehicleSetupStepStates,
                                  handleCompleteFlow,
                                  isLoading
                                }: Props) => {
  const { isChargingSetupStep, isVehicleSetupStep, isFirstStep, isReimbursementStep } =
    getDriverFormStepStatus(driverNonFormState);

  const isReimbursementActive = driverFormReimbursementStepStates.isReimbursementActive;
  const isVehicleAssigned = driverFormVehicleSetupStepStates.isVehicleSelected;

  if (isFirstStep) {
    return <Row className="justify-end">
      <ContinueButton type="submit" />
    </Row>;
  }


  const completeFlowWithoutAssignment = !isVehicleAssigned && isVehicleSetupStep;

  return (
    <Row className="justify-between">
      {isReimbursementStep || isVehicleSetupStep || isFirstStep ? <></> : <DriverFromBackButton />}
      {isChargingSetupStep ? (
        <PrimaryButton isLoading={isLoading} type="submit" text="Add & Continue" />
      ) : (
        <>
          {(isVehicleSetupStep || isReimbursementStep) ? (
            <Row className="w-100 justify-end">
              <PrimaryButton type={completeFlowWithoutAssignment ? "button" : "submit"}
                             onClick={
                               completeFlowWithoutAssignment ? handleCompleteFlow :
                                 () => {
                                 }
                             }
                             text={getDriverFormButtonText(driverNonFormState, !!isReimbursementActive, isVehicleAssigned)}
                             isLoading={isLoading} />

            </Row>
          ) : (
            <ContinueButton type="submit" />
          )}
        </>
      )}
    </Row>
  );
};

export default DriverFormButtonFooter;