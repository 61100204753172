import React from "react";
import { SvgProps } from "types/svg.types";

type Props = {};

function InboxPaymentIcon({ height = 24, width = 24 }: SvgProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="12" fill="#FFE5EE" />
      <path
        d="M9.00005 12.0002C10.32 12.0002 11.4 10.9202 11.4 9.6002C11.4 8.2802 10.32 7.2002 9.00005 7.2002C7.68005 7.2002 6.60005 8.2802 6.60005 9.6002C6.60005 10.9202 7.68005 12.0002 9.00005 12.0002ZM11.4 16.8002V13.6202C10.74 13.3802 9.90005 13.2002 9.00005 13.2002C6.66005 13.2002 4.80005 14.2802 4.80005 15.6002V16.8002H11.4ZM18 7.2002H13.8C13.14 7.2002 12.6 7.7402 12.6 8.4002V15.6002C12.6 16.2602 13.14 16.8002 13.8 16.8002H18C18.66 16.8002 19.2 16.2602 19.2 15.6002V8.4002C19.2 7.7402 18.66 7.2002 18 7.2002ZM15.6 15.6002H14.4V8.4002H15.6V15.6002Z"
        fill="#C4003E" />
    </svg>
  );
}

export default InboxPaymentIcon;
