import Row from "components/custom/Row";
import ChargerLighteningIcon from "components/icon/icons/ChargerLighteningIcon";
import VehicleStatusServiceIcon from "components/icon/icons/VehicleStatusServiceIcon";
import LoadingSkeleton from "components/loading/LoadingSkeleton";
import { BlackBody2Text0, BlackCaptionText200, GreenBodyText200 } from "components/text/Text";
import React from "react";
import { TeslaDataAccessorHook, TeslaVehicleStatus, VehicleDataAccessorHook } from "types/vehicle.types";
import { getMphByKm } from "utils/format.utils";
import {
  getTeslaVehicleStatusContent,
  getVehicleConnectionStatus,
  getVehicleTelemetryDetails
} from "utils/vehicle.utils";

type Props = {
  readonly vehicleHook: VehicleDataAccessorHook;
  readonly teslaHook: TeslaDataAccessorHook
}

function VehicleDetailsVehicleStateBlock({ vehicleHook, teslaHook }: Props) {
  const isLoading = vehicleHook.isLoading || teslaHook.isLoading;
  const telemetry = vehicleHook.vehicle?.telemetry;
  const isCharging = telemetry?.charge?.data.charging;

  const { batteryPercentRemainingDisplayText } = getVehicleTelemetryDetails(telemetry);

  const {
    icon: teslaVehicleStateIcon,
    label: teslaVehicleStateLabel,
    textClass
  } = getTeslaVehicleStatusContent(teslaHook?.teslaVehicleStatus);

  const { isTeslaConnected } = getVehicleConnectionStatus(vehicleHook.vehicle);


  if (isLoading) {
    return <LoadingSkeleton count={1} height={18} className={"w-200px"} />;
  }

  const isDriving = teslaHook?.teslaVehicleStatus === TeslaVehicleStatus.IN_MOTION;
  const speed = teslaHook.info?.speed;

  return (

    <>
      {(isTeslaConnected || isCharging) && <BlackCaptionText200 className={"mr-2"}>Vehicle state</BlackCaptionText200>}
      {isTeslaConnected ?
        (
          <Row>
            {teslaHook.info?.inService &&
              (
                <Row className={"mr-2"}>
                  <VehicleStatusServiceIcon />
                  <BlackBody2Text0 className={`ml-1`}>In service</BlackBody2Text0>
                </Row>
              )}

            <Row>
              {teslaVehicleStateIcon}
              <BlackBody2Text0
                className={`ml-1 ${textClass ?? ""}`}>{teslaVehicleStateLabel}</BlackBody2Text0>
              {isDriving && speed && <BlackBody2Text0 className={"ml-1"}>
                ({getMphByKm(speed)})
              </BlackBody2Text0>
              }
            </Row>
          </Row>
        ) : (
          isCharging ?
            (
              <Row className={"align-items-center"}>
                <ChargerLighteningIcon fill={"#23d206"} />
                <GreenBodyText200 className={"ml-1"}>Charging {batteryPercentRemainingDisplayText}</GreenBodyText200>
              </Row>
            ) : <></>
        )
      }
    </>

  );
}

export default VehicleDetailsVehicleStateBlock;