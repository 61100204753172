// React
import React, { useCallback, useState } from "react";
import { useHistory } from "react-router";

// Third party libraries
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Form } from "reactstrap";

// Components
// Services & Utilities & Hooks
import CancelButton from "components/button/CancelButton";
import SaveButton from "components/button/SaveButton";
import Col from "components/custom/Col";
import Row from "components/custom/Row";
import { useAlertManager } from "hooks/alert.hooks";
import { handleApiErrorResponse } from "utils";
import AuthPasswordInput from "../../../../auth-components/form-components/AuthPasswordInput";
import InputControllerWithValidationError from "../../../../form/InputControllerWithValidationError";
import Card from "../../../../card/Card";
import { BlackBodyText100, BlackOverlineText200 } from "../../../../text/Text";
import { authorizeGeotabAuthAsync } from "../../../../../services/vehicle.services";
import DriverSelfSignupApprovalPageLearnMoreModal from "./AddVehicleGeotabFlowFormDatabaseHelperModal";
import { useAddVehicleCompletedHandlers } from "../../../../../hooks/vehicle.hooks";

type Props = {};

type FormData = {
  username: string,
  password: string,
  database: string
}

/**
 * Component
 */
export default function AddVehicleGeotabFlowForm({}: Props) {


  /**
   * Initial Hooks
   */

  const history = useHistory();
  const { t } = useTranslation("common");

  /**
   * State Hooks
   */
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isHelperModalOpen, setIsHelperModalOpe] = useState(false);


  const { handleOpenErrorAlert, handleOpenSuccessAlert } = useAlertManager();
  const { handleForwardCompletedPage } = useAddVehicleCompletedHandlers();

  const { register, control, errors, handleSubmit, watch } = useForm<FormData>();


  /**
   * Handlers
   */


  /**
   *
   */
  const handleValidatedSubmit = useCallback(
    async (validatedData: FormData) => {
      try {
        setIsSubmitting(true);
        const response = await authorizeGeotabAuthAsync(validatedData);
        handleForwardCompletedPage(response, "geotab");
      } catch (err) {
        handleOpenErrorAlert(handleApiErrorResponse(err));
      } finally {
        setIsSubmitting(false);
      }
    },
    [handleForwardCompletedPage, handleOpenErrorAlert]
  );


  /**
   * Effects
   */
  return (
    <div className="center">
      <Card className={"w-100 w-max-100"}>
        <Form
          className="w-100 h-100"
          onReset={() => history.push("/vehicles")}
          onSubmit={handleSubmit(handleValidatedSubmit)}
        >
          <div className="w-100 h-100  p-2">
            <Col className="mb-3">
              <InputControllerWithValidationError
                formLabel={"Username"}
                overwriteFormHelper={<BlackOverlineText200 className={"mb-2"}>Your Geotab username (typically your email
                  address)
                </BlackOverlineText200>}
                type="text"
                name="username"
                placeholder={"Enter username"}
                registerRef={register({ required: "Required" })}
                errors={errors}
                control={control}
              />
            </Col>


            <Col className="mb-3">
              <BlackBodyText100 className="mb-1">Password</BlackBodyText100>
              <BlackOverlineText200 className={"mb-2"}>Your Geotab password. It will be encrypted in Bluedot
                database
              </BlackOverlineText200>
              <AuthPasswordInput
                data-testid="password"
                register={register}
                className="w-max-100 w-100 mb-0"
                placeholder={"Enter Password"}
              />
            </Col>

            <Col className="mb-4">
              <InputControllerWithValidationError
                formLabel={"Database"}
                overwriteFormHelper={<BlackOverlineText200 className={"mb-2"}>The name of your Geotab database. Find it
                  in the URL once logged in after “geotab.com” or in the header area next to the support menu on the
                  right. <DriverSelfSignupApprovalPageLearnMoreModal />
                </BlackOverlineText200>}
                type="text"
                name="database"
                placeholder={"Enter database"}
                registerRef={register({ required: "Required" })}
                errors={errors}
                control={control}
              />
            </Col>


            <Row className="w-100 justify-between align-center mt-4 mt-md-0">
              <CancelButton />
              <SaveButton isDisabled={isSubmitting} isLoading={isSubmitting} />
            </Row>
          </div>
        </Form>
      </Card>
    </div>
  );
}
