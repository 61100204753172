import * as React from "react";
import { styled } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Row from "../custom/Row";

interface StyledTabsProps {
  children?: React.ReactNode;
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

export const StyledTabs = styled((props: StyledTabsProps) => (
  <Tabs {...props} TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }} />
))({
  "& .MuiTabs-indicator": {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    backgroundColor: "black",
    height: 4,
    borderRadius: "10px"
  },
  "& .MuiTabs-indicatorSpan": {
    width: "100%",
    backgroundColor: "#111111",
    height: "100%",
    borderRadius: "10px"
  },
  "& .button:focus": {
    outline: "none !important"
  }
  //.css-1ehgvn4-MuiButtonBase-root-MuiTab-root.Mui-selected
});

interface StyledTabProps {
  label: string;
  isDot?: boolean;
  rightElement?: React.ReactNode;
}

export const StyledTab = styled((props: StyledTabProps) => <Row
  className={"position-relative"}><Tab disableRipple={true}  {...props} />
  {
    props.rightElement ? props.rightElement : (props.isDot ?
      <div className={"tab-warning-dot"} /> : <></>)
  }
</Row>)(({ theme }) => ({
  textTransform: "none",
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.pxToRem(17),
  paddingRight: theme.spacing(3),
  paddingLeft: theme.spacing(3),
  borderBottom: "4px solid #E9EBEC",
  outline: 0,
  color: "#6F7981",
  "&.Mui-selected": {
    color: "#111111"
  }
}));
