import React from "react";

type Props = {};

function ChargingSessionTablePlaceholderIcon({}: Props) {
  return (
    <svg width="154" height="128" viewBox="0 0 154 128" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_3259_7795)">
        <ellipse cx="77.2326" cy="104.651" rx="68.3078" ry="5.28348" fill="#D9D9D9" />
      </g>
      <rect x="21.2471" y="0.753906" width="113.972" height="73.9687" fill="#CBD5E1" />
      <path d="M151.824 43.0217L135.219 0.753906V54.3435L151.824 83.78V43.0217Z" fill="#A4B1C5" />
      <path d="M21.247 0L2 43.7774L21.247 57.7409V0Z" fill="#A4B1C5" />
      <path
        d="M2.5 44.1133H151.5V111.613C151.5 114.099 149.485 116.113 147 116.113H7C4.51472 116.113 2.5 114.099 2.5 111.613V44.1133Z"
        fill="white"
        stroke="#CBD5E1"
      />
      <path
        d="M69.9253 77.3722L67.6159 75.2148C69.2391 73.4817 71.3208 72.222 73.6381 71.5947C76.6072 70.791 79.766 71.0788 82.541 72.4058C85.3159 73.7328 87.5229 76.0111 88.7611 78.8268C89.9993 81.6424 90.1866 84.8088 89.289 87.7509C88.3914 90.6929 86.4684 93.2154 83.8693 94.8604C81.2702 96.5053 78.1673 97.1635 75.1242 96.7154C72.0811 96.2674 69.2996 94.7428 67.2848 92.4186C65.3503 90.1868 64.2459 87.3599 64.1508 84.4153C64.1443 84.2129 63.9801 84.0493 63.7776 84.05L59.0287 84.0679C58.8262 84.0686 58.6623 84.2335 58.6671 84.436C58.7682 88.6928 60.3483 92.7862 63.1423 96.0094C66.0165 99.3251 69.9844 101.5 74.3256 102.139C78.6668 102.778 83.0932 101.839 86.8011 99.4928C90.5089 97.1462 93.2521 93.5477 94.5326 89.3507C95.8131 85.1537 95.5459 80.6366 93.7795 76.6199C92.0131 72.6031 88.8647 69.3531 84.9061 67.46C80.9475 65.567 76.4412 65.1564 72.2057 66.303C68.8976 67.1984 65.9261 68.9973 63.6098 71.4723L61.6046 69.5991C61.5041 69.5052 61.3819 69.4386 61.2525 69.4072C61.123 69.3757 60.9916 69.3807 60.8737 69.4215C60.7557 69.4622 60.6561 69.5372 60.5865 69.6375C60.5169 69.7378 60.4802 69.8593 60.4806 69.9878L60.5098 78.2965C60.5113 78.7191 60.9007 79.0828 61.3224 79.0556L69.6139 78.52C69.7421 78.5117 69.8609 78.4668 69.9562 78.3906C70.0515 78.3143 70.1195 78.2098 70.1522 78.0894C70.1849 77.969 70.1809 77.8375 70.1407 77.7105C70.1005 77.5835 70.0258 77.4662 69.9253 77.3722Z"
        fill="black"
      />
      <path
        d="M80.9792 74.3708C80.9311 74.2479 80.8427 74.1449 80.7285 74.0787C80.6146 74.0127 80.4818 73.9859 80.3515 74.005C80.2212 74.0241 80.1012 74.0866 80.011 74.1825L70.6775 84.0994C70.5993 84.1823 70.5472 84.2863 70.5274 84.3985C70.5077 84.5107 70.5212 84.6262 70.5664 84.7308C70.6116 84.8354 70.6864 84.9245 70.7816 84.9871C70.8768 85.0496 70.9882 85.083 71.1022 85.0829H74.9791L72.8779 91.9116C72.839 92.0375 72.8439 92.1729 72.8919 92.2957C72.9398 92.4185 73.0279 92.5215 73.1419 92.5878C73.2558 92.6541 73.3888 92.6799 73.5193 92.6609C73.6498 92.6419 73.7699 92.5793 73.8602 92.4832L83.1938 82.5664C83.2719 82.4835 83.3241 82.3795 83.3438 82.2673C83.3636 82.1551 83.35 82.0395 83.3049 81.935C83.2597 81.8304 83.1849 81.7413 83.0897 81.6787C82.9945 81.6161 82.883 81.5828 82.7691 81.5829H78.8922L80.9934 74.7554C81.0323 74.6293 81.0273 74.4937 80.9792 74.3708Z"
        fill="black"
      />
      <defs>
        <filter
          id="filter0_d_3259_7795"
          x="0.924805"
          y="99.3672"
          width="152.615"
          height="28.5664"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="10" />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3259_7795" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3259_7795" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}

export default ChargingSessionTablePlaceholderIcon;
