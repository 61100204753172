// Generic wrappers

export type WithTotal<T extends {}> = {
  total: number;
} & T;

export type WithPeriod<T extends {}> = {
  period: string;
} & T;

export type WithPeriodAndTotal<T extends {}> = WithPeriod<WithTotal<{}> & T>


export type WithPercentageAndCost<T extends {}> = {
  cost: number
  percentage: number
} & T;

export type WithCount<T extends {}> = {
  count: number;
} & T;


export enum DashboardInsightFilter {
  ThisWeek = "thisWeek",
  LastWeek = "lastWeek",
  ThisMonth = "thisMonth",
  LastMonth = "lastMonth",
  ThisQuarter = "thisQuarter",
  LastQuarter = "lastQuarter",
  ThisYear = "thisYear"
}

export enum DashboardInsightFilterReturnDataType {
  Weekly,
  Monthly,
  Quarterly,
  Yearly
}

/** Header */
export type HeaderStats = {
  sustainabilityScore: SustainabilityScore,
  vehicleStats: WithTotal<{}>
}


/** Overviews */


export type DashboardOverview = WithTotal<{
  monthlyTrend: {
    lastMonth: number
    thisMonth: number
    percentageChange: number
  }
}>


export type DashboardOverviews = {
  totalEnergyOverview: DashboardOverview
  totalCostOverview: DashboardOverview
  totalVehicleOverview: DashboardOverview
}


/** Insights */

// Total Energy & Total Cost
export type TotalCostOrEnergyByPeriod = WithPeriodAndTotal<{}>


// Most Costly Groups


export type MostCostlyGroup = WithPercentageAndCost<{
  group: {
    id: string,
    name: string
  }
  isPlaceholder?: boolean
}>;

export interface VehicleForDashboardInsights {
  id?: string;
  name: string;
  brand: string;
  model: string;
  plate?: string;
}

export type MostCostlyVehicle = WithPercentageAndCost<{
  vehicle: VehicleForDashboardInsights
  isPlaceholder?: boolean
}>;


// Locations & Charging Types & Charging Providers


export type CountByChargingType = WithCount<{
  type: string
}>;

export type CountByChargingProvider = WithCount<{
  provider: string
}>;

export type CountByChargingLocation = WithCount<{
  location: string
}>;


// SustainabilityScore

export type SustainabilityScore = WithTotal<{}>


// Dashboard Insights

export type DashboardInsights = {
  totalEnergyByPeriod: TotalCostOrEnergyByPeriod[]
  totalCostByPeriod: TotalCostOrEnergyByPeriod[]
  mostCostlyGroups: MostCostlyGroup[]
  mostCostlyVehicles: MostCostlyVehicle[]
  countByChargingType: CountByChargingType[]
  countByChargingProvider: CountByChargingProvider[]
  countByChargingLocation: CountByChargingLocation[]
  sustainabilityScore: SustainabilityScore
}


// Dashboard slice for global state (Redux)

export type InsightsState = {
  insights: DashboardInsights | null,
  isLoading: boolean
  filter: DashboardInsightFilter
}

export type OverviewsState = {
  overviews: DashboardOverviews | null,
  isLoading: boolean
}


export type DashboardSliceState = {
  insightsState: InsightsState
  overviewsState: OverviewsState
}