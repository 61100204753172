import React from "react";

const TableCheckboxClearableIcon = () => {
  return (

    <svg width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_30885_118714)">
        <rect x="0.416667" y="0.416667" width="19.1667" height="19.1667" rx="3.75" stroke="#0C0C0C"
              strokeWidth="0.833333" />
        <rect x="0.416667" y="0.416667" width="19.1667" height="19.1667" rx="3.75" stroke="black" strokeOpacity="0.2"
              strokeWidth="0.833333" />
        <rect x="0.416667" y="0.416667" width="19.1667" height="19.1667" rx="3.75" stroke="black" strokeOpacity="0.2"
              strokeWidth="0.833333" />
        <rect x="0.416667" y="0.416667" width="19.1667" height="19.1667" rx="3.75" stroke="black" strokeOpacity="0.2"
              strokeWidth="0.833333" />
        <rect x="0.416667" y="0.416667" width="19.1667" height="19.1667" rx="3.75" stroke="black" strokeOpacity="0.2"
              strokeWidth="0.833333" />
        <path
          d="M6.66699 10.0007C6.66699 9.89014 6.71089 9.78416 6.78903 9.70602C6.86717 9.62788 6.97315 9.58398 7.08366 9.58398H12.917C13.0275 9.58398 13.1335 9.62788 13.2116 9.70602C13.2898 9.78416 13.3337 9.89014 13.3337 10.0007C13.3337 10.1112 13.2898 10.2171 13.2116 10.2953C13.1335 10.3734 13.0275 10.4173 12.917 10.4173H7.08366C6.97315 10.4173 6.86717 10.3734 6.78903 10.2953C6.71089 10.2171 6.66699 10.1112 6.66699 10.0007Z"
          fill="black" />
      </g>
      <defs>
        <clipPath id="clip0_30885_118714">
          <rect width="20" height="20" rx="4.16667" fill="white" />
        </clipPath>
      </defs>
    </svg>

  );
};

export default TableCheckboxClearableIcon;