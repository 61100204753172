// React

import React, { useCallback, useEffect, useMemo, useState } from "react";

// Third-party
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { Prompt } from "react-router-dom";
import { Form } from "reactstrap";

// Components
import Card from "../card/Card";

import Col from "../custom/Col";
import Row from "../custom/Row";
import AlertConfirmDialog from "../notification/AlertConfirmDialog";
import DriverFormStepper from "./DriverFormStepper";

// Hooks & Utils & Services
import { getStateCode } from "consts/state.consts";
import { getDriverFormStepStatus, getFormattedCreditValueForAddDriverService } from "utils/driver.utils";
import { addDriverAsync } from "../../services/driver.services";
import { handleApiErrorResponse } from "../../utils";
import { isEmpty } from "../../utils/Utils";
// State
import {
  clearDriverNonFormError,
  forwardDriverFormReimbursementSetupStep,
  forwardDriverFormVehicleSetupStep,
  incrementDriverNonFormStep,
  resetDriverFormState,
  setDriverFormSuccessMessageHelper,
  setDriverNonFormStateAsCompleted,
  updateDriverFormState
} from "redux/slices/driverSlice";

import { useAlertManager } from "hooks/alert.hooks";
import { useAppSelector } from "hooks/redux.hooks";
import { Country, DriverAddPayload, DriverFormState, DriverFormStep } from "types/driver.types";

import {
  useDriverFormAddressSetupStepStates,
  useDriverFormPersonalInformationStepStates,
  useDriverFormReimbursementStepStates,
  useDriverFormVehicleSetupStepStates
} from "hooks/driver.hooks";

import DriverFormAddressStep from "./form/DriverFormAddressStep";
import DriverFormChargingStep from "./form/DriverFormChargingStep";
import DriverFormVehicleSetupStep from "./form/DriverFormVehicleSetupStep";

import InfoBadge from "components/badge/InfoBadge";
import CancelButton from "components/button/CancelButton";
import PrimaryButton from "components/button/PrimaryButton";
import PageHeader from "components/header/PageHeader";
import { BlackBody2Text100, BodyText, NeutralBodySmallText50 } from "components/text/Text";
import DriverFormReimbursementSetupStep from "./form/DriverFormReimbursementSetupStep";
import DriverFormPersonalInformationStep from "./form/DriverFormPersonalInformationStep";
import DriverFormButtonFooter from "./form/DriverFormButtonFooter";
import CustomerSupportLinkButton from "../button/CustomerSupportLinkButton";

export default function AddDriverForm() {
  const { t } = useTranslation("common");

  // Hooks
  const { handleOpenErrorAlert } = useAlertManager();

  const history = useHistory();
  const dispatch = useDispatch();

  const driverNonFormState = useAppSelector((state) => state.driver.driverNonFormState);
  const driverFormState = useAppSelector((state) => state.driver.driverFormState);

  const { isAddressSetupStep, isChargingSetupStep, isVehicleSetupStep, isLastStep, isFirstStep, isReimbursementStep } =
    getDriverFormStepStatus(driverNonFormState);
  const { currentStep, isError, errorMessage } = driverNonFormState;

  const { handleSubmit, errors, trigger, reset, formState, register, control } = useForm<DriverFormState>({
    mode: "all",
    defaultValues: driverFormState
  });

  const [driverFullName, setDriverFullName] = useState("");

  const [forwardingLocationAfterConfirm, setForwardingLocationAfterConfirm] = useState("");
  const [isNavigationAllowing, setIsNavigationAllowing] = useState(false);
  const [isExitFormWarningModalOpen, setIsExitFormWarningModalOpen] = useState(false);
  const [isSubmitDriverAlertOpen, setIsSubmitDriverAlertOpen] = useState(false);

  const [validatedDriverSubmitFormData, setValidatedDriverSubmitFormData] = useState<DriverFormState | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const [driverId, setDriverId] = useState<string | undefined>(undefined);

  const [isInfoBadgeOpen, setIsInfoBadgeOpen] = useState(true);

  /**
   * Personal information Setup
   */
  const personalInformationStepStates = useDriverFormPersonalInformationStepStates();

  /**
   * Address Setup
   */
  const addressSetupStepStates = useDriverFormAddressSetupStepStates();
  const { validateAddressSelectors } = addressSetupStepStates;

  /**
   * Vehicle Setup
   */
  const driverFormVehicleSetupStepStates = useDriverFormVehicleSetupStepStates();


  /**
   * Reimbursement Setup
   */
  const driverFormReimbursementStepStates = useDriverFormReimbursementStepStates(driverId);


  const commonFormStepProps = useMemo(() => {
    return {
      register, control, errors
    };
  }, [register, control, errors]);

  /**
   * Effects
   */

  /**
   *
   */
  useEffect(() => {
    window?.scrollTo(0, 0);
  }, [currentStep]);

  /**
   *
   */
  useEffect(() => {
    if (isNavigationAllowing && forwardingLocationAfterConfirm) {
      history.goBack();
    }
  }, [forwardingLocationAfterConfirm, history, isNavigationAllowing]);

  /**
   *
   */
  useEffect(() => {
    reset(driverFormState);
  }, [driverFormState, reset]);

  /**
   *
   */
  useEffect(() => {
    const areThereAnyErrorInForm = !isEmpty(errors);

    if (areThereAnyErrorInForm && !isError) {
      // handleOpenErrorAlert("");
      return;
    }

    if (!areThereAnyErrorInForm && isError && !errorMessage) {
      dispatch(clearDriverNonFormError());
    }
  }, [dispatch, errorMessage, errors, isError, handleOpenErrorAlert]);

  /**
   * Handlers
   */

  /**
   *
   */
  const handleDialogConfirmed = useCallback(() => {
    if (forwardingLocationAfterConfirm) {
      setIsNavigationAllowing(true);
    }
  }, [forwardingLocationAfterConfirm]);

  /**
   *
   */
  const handleOpenSubmitDriverAlert = useCallback((formData: DriverFormState) => {
    setValidatedDriverSubmitFormData(formData);
    setIsSubmitDriverAlertOpen(true);
  }, []);

  /**
   *
   */
  const handleValidateAndSwitchToNextStep = useCallback(
    (formData: DriverFormState) => {
      const context = "handleValidateStepAndForwardNext";
      if (isLastStep) {
        console.log(context, "it should not be happening.");
        return;
      }


      if (isAddressSetupStep) {
        const isAddressSelectorsValidated = validateAddressSelectors();

        if (!isAddressSelectorsValidated) {
          return;
        }
      }

      dispatch(updateDriverFormState(formData));
      dispatch(incrementDriverNonFormStep());
    },
    [dispatch, isLastStep, isAddressSetupStep, validateAddressSelectors]
  );

  useEffect(() => {
    dispatch(
      setDriverFormSuccessMessageHelper(driverFullName)
    );
  }, [dispatch, driverFullName]);


  /**
   *
   */
  const handleSubmitDriver = useCallback(async () => {
    const formData = validatedDriverSubmitFormData;
    const { fleetGroupSelector, selectedDialCode } = personalInformationStepStates;
    const { addressSelectors } = addressSetupStepStates;


    const groups = fleetGroupSelector.data.map((g) => g.value);

    const country = addressSelectors.country.data.value as Country;
    const city = addressSelectors.city?.data?.value;
    const state = addressSelectors.state?.data?.value;

    const stateCode = getStateCode(country, state ?? "");


    if (!city || !stateCode || !country) {
      return;
    }


    const { street1, street2, postalCode, credit, phoneNumber, payer, firstName, lastName, email, fixedRate } = {
      ...driverFormState,
      ...formData
    };

    const requestPayload: DriverAddPayload = {
      firstName,
      lastName,
      email,
      phoneNumber: selectedDialCode.value + phoneNumber.trim(),
      address: {
        street: street1,
        street2,
        city,
        state: stateCode,
        postalCode: postalCode.trim(),
        country
      },
      credit: getFormattedCreditValueForAddDriverService(credit),
      groups,
      payer
    };

    try {
      setIsLoading(true);
      setIsNavigationAllowing(true);

      const addedDriver = await addDriverAsync(requestPayload);
      setDriverId(addedDriver?.data.id);
      setIsSubmitDriverAlertOpen(false);

      setDriverFullName(`${requestPayload.firstName} ${requestPayload.lastName}`);

      dispatch(
        forwardDriverFormReimbursementSetupStep()
      );
    } catch (err) {
      handleOpenErrorAlert(handleApiErrorResponse(err));
      setIsSubmitDriverAlertOpen(false);
      setIsNavigationAllowing(true);
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  }, [validatedDriverSubmitFormData, personalInformationStepStates, addressSetupStepStates, driverFormState, dispatch, handleOpenErrorAlert]);

  const handleCompleteFlow = useCallback(() => {
    dispatch(resetDriverFormState());
    dispatch(setDriverNonFormStateAsCompleted());
    history.push("/add-driver/completed");
  }, [dispatch, history]);

  /**
   *
   */
  const handleSubmitDriverVehicleStep = useCallback(async () => {
    const { handleSubmitAddScheduleAsync } = driverFormVehicleSetupStepStates;

    if (!driverId) {
      return;
    }

    try {
      setIsLoading(true);
      await handleSubmitAddScheduleAsync(driverId);
      handleCompleteFlow();
    } catch (err) {
      handleOpenErrorAlert(handleApiErrorResponse(err));
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  }, [driverFormVehicleSetupStepStates, driverId, handleCompleteFlow, handleOpenErrorAlert]);


  /**
   *
   */
  const handleSubmitDriverReimbursementStep = useCallback(async (formData: DriverFormState) => {
    const { isReimbursementActive } = driverFormReimbursementStepStates;

    const { fixedRate, firstName, lastName } = formData;
    if (!driverId || !fixedRate || !isReimbursementActive) {
      dispatch(
        forwardDriverFormVehicleSetupStep()
      );
      return;
    }

    try {
      setIsLoading(true);
      await driverFormReimbursementStepStates.handleSubmitEnableReimbursementCallback(driverId, fixedRate);

      dispatch(
        forwardDriverFormVehicleSetupStep()
      );
    } catch (err) {
      handleOpenErrorAlert(handleApiErrorResponse(err));
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, driverFormReimbursementStepStates, driverId, handleOpenErrorAlert]);


  /**
   *
   */
  const handleNextStepSubmit = useCallback(
    async (formData: DriverFormState) => {
      const context = "handleNextStepSubmit";

      console.log(context, formData);

      handleValidateAndSwitchToNextStep(formData);
    },
    [handleValidateAndSwitchToNextStep]
  );

  /**
   *
   */
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    console.log(e);

    await trigger();

    if (isChargingSetupStep) {
      await handleSubmit(handleOpenSubmitDriverAlert)(e);
      return;
    }

    if (isReimbursementStep) {
      await handleSubmit(handleSubmitDriverReimbursementStep)(e);
      return;
    }

    if (isVehicleSetupStep) {
      await handleSubmitDriverVehicleStep();
      return;
    }

    handleSubmit(handleNextStepSubmit)(e);
  };

  // Values to render
  const FormStepContent = useMemo(() => {
    switch (currentStep) {
      case DriverFormStep.PERSONAL_INFORMATION:
        //return <DriverFormReimbursementSetupStep {...commonFormStepProps} {...driverFormReimbursementStepStates} />;
        return <DriverFormPersonalInformationStep {...personalInformationStepStates} {...commonFormStepProps} />;

      case DriverFormStep.ADDRESS:
        return <DriverFormAddressStep {...addressSetupStepStates} {...commonFormStepProps} />;

      case DriverFormStep.CHARGING:
        return <DriverFormChargingStep {...commonFormStepProps} />;

      case DriverFormStep.REIMBURSEMENT:
        return (
          <DriverFormReimbursementSetupStep  {...commonFormStepProps} {...driverFormReimbursementStepStates} />
        );

      case DriverFormStep.VEHICLE:
        return (
          <DriverFormVehicleSetupStep
            driverFormVehicleSetupStates={driverFormVehicleSetupStepStates}
            {...commonFormStepProps}
          />
        );

      default:
        return <>Ups... You should not see this.</>;
    }
  }, [currentStep, personalInformationStepStates, commonFormStepProps, addressSetupStepStates, driverFormReimbursementStepStates, driverFormVehicleSetupStepStates]);

  return (
    <>
      <div className="w-100">
        <PageHeader title="Add Driver" description={"Ideal for Regular, Long-Term Employees"} />
      </div>

      <AlertConfirmDialog
        isDialogOpen={isExitFormWarningModalOpen}
        title="Unsaved Changes"
        description="You have unsaved changes. If you leave, you'll lose these changes."
        onDialogConfirmed={handleDialogConfirmed}
        toggleIsDialogOpen={() => setIsExitFormWarningModalOpen(false)}
      />

      <AlertConfirmDialog
        isDialogOpen={isSubmitDriverAlertOpen}
        title="Are you sure to add driver ?"
        description="At this stage the driver will be added, it cannot be undone."
        toggleIsDialogOpen={() => setIsSubmitDriverAlertOpen(false)}
        overrideButtons={
          <>
            <div className="mr-2">
              <CancelButton onClick={() => setIsSubmitDriverAlertOpen(false)} />
            </div>

            <PrimaryButton type="button" text="Add & Continue" onClick={handleSubmitDriver} isLoading={isLoading} />
          </>
        }
      />
      <Row className="w-100 w-max-100 d-flex justify-center mt-3">
        <Col lg="8" className="w-100 w-max-100 d-flex flex-col align-items-start justify-center pr-2">
          <Col className="justify-center flex-column ">
            <Form className="w-100 " onSubmit={handleFormSubmit}>
              <Prompt
                when={formState.isDirty && !isNavigationAllowing}
                message={(location) => {
                  if (formState.isDirty) {
                    setForwardingLocationAfterConfirm(location.pathname);
                    setIsExitFormWarningModalOpen(true);
                    return false;
                  }
                }}
              />
              <Card className={"mb-3"}>
                {FormStepContent}

                {isInfoBadgeOpen && isVehicleSetupStep && (
                  <div className="mt-4">
                    <InfoBadge
                      onClose={() => setIsInfoBadgeOpen(false)}
                      textOverride={
                        <>
                          <BlackBody2Text100>
                            Create one vehicle assignment period now. For additional assignments, please visit the
                            Vehicle or Driver pages.
                          </BlackBody2Text100>
                        </>
                      }
                    />
                  </div>
                )}
              </Card>

              <DriverFormButtonFooter
                driverFormReimbursementStepStates={driverFormReimbursementStepStates}
                driverFormVehicleSetupStepStates={driverFormVehicleSetupStepStates}
                driverNonFormState={driverNonFormState}
                handleCompleteFlow={handleCompleteFlow}
                isLoading={isLoading}
              />

            </Form>
          </Col>
        </Col>

        <Col lg="4" className="w-100 pl-2"> <DriverFormStepper currentStep={currentStep} isError={isError} />

          {isReimbursementStep && driverFormReimbursementStepStates.isReimbursementActive &&
            <Card padding="p-2" className="my-4">
              <BodyText className="text-blue-500">What’s Next:</BodyText>
              <NeutralBodySmallText50 className={"mt-2"}>Your driver’s charging sessions within the specified adress
                will be recorded as
                home charging. You can easily manage your home charging reimbursements directly from the Reimbursement
                tab.</NeutralBodySmallText50>
              <NeutralBodySmallText50 className={"mt-3"}>To update home locations and rates, go to the driver detail
                page. Select the ‘Reimbursement’ tab and click ‘Edit.’ For more assistance, please
                contact <CustomerSupportLinkButton />.</NeutralBodySmallText50>
            </Card>
          }
        </Col>
      </Row>
    </>
  );
}
