import React from "react";
import { SvgProps } from "types/svg.types";

const DashboardGraphPlaceholderIcon = ({ fill, width, height, onClick }: SvgProps) => {
  return (
    <svg
      width="112"
      height="109"
      viewBox="0 0 112 109"
      className="h-100px"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1866_28574)">
        <path
          d="M71.0473 24.5055H40.967C40.2815 24.5063 39.6244 24.779 39.1397 25.2637C38.655 25.7484 38.3823 26.4056 38.3815 27.091V93.9448L38.0367 94.0499L30.6575 96.3097C30.3078 96.4163 29.9301 96.3798 29.6073 96.2082C29.2844 96.0366 29.0429 95.7439 28.9356 95.3944L6.98595 23.6971C6.87913 23.3473 6.91554 22.9695 7.08717 22.6466C7.2588 22.3237 7.55161 22.0822 7.90125 21.9751L19.2725 18.4932L52.2383 8.40267L63.6095 4.92079C63.7826 4.86753 63.9644 4.84892 64.1447 4.86602C64.3249 4.88312 64.5 4.9356 64.66 5.02046C64.8199 5.10531 64.9616 5.22087 65.0768 5.36053C65.192 5.50019 65.2786 5.6612 65.3315 5.83435L70.9421 24.1607L71.0473 24.5055Z"
          fill="#CBD5E1"
        />
        <path
          d="M77.6158 24.1609L70.8537 2.07336C70.7412 1.70534 70.5572 1.36309 70.3124 1.06618C70.0676 0.769271 69.7667 0.523512 69.4268 0.342947C69.087 0.162382 68.7149 0.0505492 68.3318 0.0138432C67.9487 -0.0228628 67.5622 0.0162774 67.1943 0.129023L51.2069 5.02261L18.2429 15.1149L2.25559 20.0102C1.5129 20.2382 0.891043 20.7517 0.526504 21.4377C0.161965 22.1238 0.0845322 22.9265 0.311205 23.6696L23.4226 99.1556C23.6067 99.7555 23.9783 100.281 24.4827 100.654C24.9871 101.027 25.5979 101.229 26.2254 101.229C26.5158 101.229 26.8046 101.186 27.082 101.1L38.0413 97.7457L38.3861 97.6388V97.2785L38.0413 97.3837L26.9803 100.771C26.3248 100.971 25.6167 100.902 25.0115 100.581C24.4063 100.259 23.9533 99.7107 23.7519 99.0557L0.642226 23.5679C0.542439 23.2433 0.507661 22.9022 0.539881 22.5642C0.572101 22.2261 0.670685 21.8978 0.829993 21.5979C0.989301 21.298 1.2062 21.0325 1.46827 20.8165C1.73035 20.6006 2.03244 20.4384 2.35726 20.3394L18.3446 15.4441L51.3087 5.35356L67.296 0.458253C67.5424 0.383065 67.7985 0.344728 68.0561 0.344488C68.6089 0.34573 69.1468 0.523953 69.591 0.853053C70.0352 1.18215 70.3623 1.64484 70.5245 2.17334L77.2555 24.1609L77.3624 24.5056H77.7209L77.6158 24.1609Z"
          fill="black"
        />
        <path
          d="M21.3311 22.0315C20.9989 22.0313 20.6755 21.9246 20.4084 21.7271C20.1412 21.5296 19.9444 21.2517 19.8468 20.9341L17.6266 13.6822C17.5669 13.4874 17.5462 13.2827 17.5657 13.0799C17.5851 12.8771 17.6443 12.6801 17.7399 12.5002C17.8355 12.3203 17.9656 12.161 18.1227 12.0314C18.2799 11.9017 18.461 11.8043 18.6559 11.7447L48.9825 2.46019C49.3759 2.34014 49.8009 2.38108 50.1641 2.57403C50.5274 2.76699 50.7992 3.09618 50.92 3.48936L53.1402 10.7414C53.2602 11.1348 53.2192 11.5597 53.0263 11.923C52.8333 12.2862 52.5042 12.5581 52.1111 12.6789L21.7844 21.9634C21.6375 22.0085 21.4847 22.0315 21.3311 22.0315Z"
          fill="#1e44ff"
        />
        <path
          d="M32.9591 7.74832C34.8631 7.74832 36.4065 6.20486 36.4065 4.30092C36.4065 2.39697 34.8631 0.853516 32.9591 0.853516C31.0552 0.853516 29.5117 2.39697 29.5117 4.30092C29.5117 6.20486 31.0552 7.74832 32.9591 7.74832Z"
          fill="#1e44ff"
        />
        <path
          d="M32.9603 6.48417C34.166 6.48417 35.1433 5.5068 35.1433 4.30117C35.1433 3.09553 34.166 2.11816 32.9603 2.11816C31.7547 2.11816 30.7773 3.09553 30.7773 4.30117C30.7773 5.5068 31.7547 6.48417 32.9603 6.48417Z"
          fill="white"
        />
        <path
          d="M104.066 100.349H45.805C45.4165 100.348 45.0441 100.194 44.7695 99.9189C44.4948 99.6443 44.3403 99.2719 44.3398 98.8834V29.0735C44.3403 28.6851 44.4948 28.3127 44.7695 28.038C45.0441 27.7633 45.4165 27.6088 45.805 27.6084H104.066C104.455 27.6088 104.827 27.7634 105.102 28.038C105.376 28.3127 105.531 28.6851 105.531 29.0735V98.8834C105.531 99.2719 105.376 99.6443 105.102 99.9189C104.827 100.194 104.455 100.348 104.066 100.349Z"
          fill="#CBD5E1"
        />
        <path
          d="M77.2572 24.1616H40.9733C40.1964 24.1627 39.4517 24.4718 38.9024 25.0211C38.3531 25.5704 38.0441 26.3151 38.043 27.0919V97.3844L38.3877 97.2793V27.0919C38.3885 26.4064 38.6612 25.7493 39.1459 25.2646C39.6306 24.7799 40.2878 24.5072 40.9733 24.5064H77.3641L77.2572 24.1616ZM108.887 24.1616H40.9733C40.1964 24.1627 39.4517 24.4718 38.9024 25.0211C38.3531 25.5704 38.0441 26.3151 38.043 27.0919V106.037C38.0441 106.814 38.3531 107.559 38.9024 108.108C39.4517 108.658 40.1964 108.967 40.9733 108.968H108.887C109.664 108.967 110.409 108.658 110.958 108.108C111.507 107.559 111.816 106.814 111.817 106.037V27.0919C111.816 26.3151 111.507 25.5704 110.958 25.0211C110.409 24.4718 109.664 24.1627 108.887 24.1616ZM111.473 106.037C111.472 106.723 111.199 107.38 110.714 107.865C110.23 108.349 109.573 108.622 108.887 108.623H40.9733C40.2878 108.622 39.6306 108.349 39.1459 107.865C38.6612 107.38 38.3885 106.723 38.3877 106.037V27.0919C38.3885 26.4064 38.6612 25.7493 39.1459 25.2646C39.6306 24.7799 40.2878 24.5072 40.9733 24.5064H108.887C109.573 24.5072 110.23 24.7799 110.714 25.2646C111.199 25.7493 111.472 26.4064 111.473 27.0919V106.037Z"
          fill="black"
        />
        <path
          d="M90.787 31.7455H59.0709C58.6596 31.7451 58.2652 31.5815 57.9744 31.2907C57.6836 30.9998 57.52 30.6055 57.5195 30.1942V22.6099C57.52 22.1986 57.6836 21.8043 57.9744 21.5135C58.2653 21.2226 58.6596 21.0591 59.0709 21.0586H90.787C91.1983 21.0591 91.5926 21.2226 91.8834 21.5135C92.1742 21.8043 92.3378 22.1986 92.3383 22.6099V30.1942C92.3378 30.6055 92.1742 30.9998 91.8834 31.2907C91.5926 31.5815 91.1983 31.7451 90.787 31.7455Z"
          fill="#1e44ff"
        />
        <path
          d="M74.9279 21.5755C76.8318 21.5755 78.3753 20.032 78.3753 18.1281C78.3753 16.2241 76.8318 14.6807 74.9279 14.6807C73.0239 14.6807 71.4805 16.2241 71.4805 18.1281C71.4805 20.032 73.0239 21.5755 74.9279 21.5755Z"
          fill="#1e44ff"
        />
        <path
          d="M74.924 20.2279C76.0837 20.2279 77.0238 19.2878 77.0238 18.1281C77.0238 16.9684 76.0837 16.0283 74.924 16.0283C73.7643 16.0283 72.8242 16.9684 72.8242 18.1281C72.8242 19.2878 73.7643 20.2279 74.924 20.2279Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1866_28574">
          <rect width="111.633" height="108.968" fill="white" transform="translate(0.183594 -0.000488281)" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default DashboardGraphPlaceholderIcon;
