import CancelButton from "components/button/CancelButton";
import PrimaryButton from "components/button/PrimaryButton";
import AlertConfirmDialog from "components/notification/AlertConfirmDialog";
import TeslaBasicControlsButton from "components/vehicle-components/tesla/controls/TeslaBasicControlsButton";
import VehicleDetailsPageCardBlock from "components/vehicle-components/vehicle-details/VehicleDetailsPageCardBlock";
import { useTeslaCommandHandlersAsync } from "hooks/data/vehicle-data-accessor.hooks";
import React, { useCallback } from "react";
import { TeslaCommand, TeslaDataAccessorHook } from "types/vehicle.types";

type Props = {
  teslaHook: TeslaDataAccessorHook;
};

function TeslaPortStatusEditor({ teslaHook }: Props) {
  const { info } = teslaHook;

  const {
    handleSubmitBasicCommandAsync,
    isSubmitting,
    isConfirmDialogOpen,
    setIsConfirmDialogOpen,
    isSubmittingByCommand
  } = useTeslaCommandHandlersAsync();


  const isOpen = info?.charge.port.open;
  const submitCommand = isOpen ? TeslaCommand.CLOSE_CHARGE_PORT : TeslaCommand.OPEN_CHARGE_PORT;


  const handleTogglePortStatusAsync = useCallback(async () => {
    await handleSubmitBasicCommandAsync(submitCommand);
  }, [handleSubmitBasicCommandAsync, submitCommand]);

  return (
    <>
      <AlertConfirmDialog
        isDialogOpen={isConfirmDialogOpen}
        overrideButtons={
          <>
            <div className="mr-2">
              <CancelButton onClick={() => setIsConfirmDialogOpen(false)} />
            </div>

            <PrimaryButton
              type="button"
              text={isOpen ? "Close" : "Open"}
              isLoading={isSubmitting}
              onClick={handleTogglePortStatusAsync}
            />
          </>
        }
        toggleIsDialogOpen={() => setIsConfirmDialogOpen(false)}
        title={`Are you sure you want to ${isOpen ? "Close" : "Open"} charging port of this vehicle?`}
      />

      <VehicleDetailsPageCardBlock
        columnClass="h-28px"
        isLoading={teslaHook.isLoading}
        leftLabel="Charge port"
        leftText={teslaHook.isSleeping ? "Unavailable in Sleep Mode" : (isOpen ? "Opened" : "Closed")}
        rightTextOverwrite={
          <TeslaBasicControlsButton
            text={isOpen ? "Close" : "Open"}
            isLoading={isSubmittingByCommand(submitCommand)}
            onClick={() => setIsConfirmDialogOpen(true)}
          />
        }
      />
    </>
  );
}

export default TeslaPortStatusEditor;
