import { SyntheticEvent, useCallback, useState } from "react";
import { useForm } from "react-hook-form";

import { Auth } from "aws-amplify";

import AuthFormInput from "./AuthFormInput";
import { handleApiErrorResponse, handleAwsErrorResponse } from "../../../utils";

import { useSignUpResendConfirmationEmailSubmitAsync } from "../../../hooks/auth.hooks";
import AuthResendEmailCard from "./AuthResendEmailCard";
import VerifyButton from "components/button/VerifyButton";
import { useTranslation } from "react-i18next";
import { BlackBodyText200, BlackH3Text100 } from "components/text/Text";
import { useAlertManager } from "hooks/alert.hooks";

type Props = {
  readonly emailAddressCodeSent: {
    encrypted?: string;
    original: string;
  };
};
type Code = {
  readonly code: string;
};
type FormValues = Code

function SignUpFormFlowConfirmationStep({ emailAddressCodeSent }: Props) {
  const { register, handleSubmit, errors, trigger, setValue, reset, formState, control, getValues } =
    useForm<FormValues>({
      mode: "all"
    });

  const { handleOpenErrorAlert } = useAlertManager();
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation("common");

  const { SignUpResendConfirmationEmailSubmitAsync, isLoading: isResendCodeLoading } =
    useSignUpResendConfirmationEmailSubmitAsync();

  /**
   *
   */
  const confirmSignUpSubmitAsync = useCallback(
    async (values: FormValues) => {
      try {
        setIsLoading(true);
        await Auth.confirmSignUp(emailAddressCodeSent.original, values.code);
      } catch (error) {
        handleOpenErrorAlert(handleAwsErrorResponse(error));
      } finally {
        setIsLoading(false);
      }
    },
    [emailAddressCodeSent.original, handleOpenErrorAlert]
  );

  const handleResendCodeButtonPressed = useCallback(() => {
    SignUpResendConfirmationEmailSubmitAsync({ email: emailAddressCodeSent.original });
  }, [emailAddressCodeSent.original, SignUpResendConfirmationEmailSubmitAsync]);

  /**
   *
   */
  const handleFormSubmit = useCallback(
    async (e: SyntheticEvent<HTMLFormElement, SubmitEvent>) => {
      e.preventDefault();

      await trigger();

      handleSubmit(confirmSignUpSubmitAsync)(e);
    },
    [handleSubmit, confirmSignUpSubmitAsync, trigger]
  );

  return (
    <form onSubmit={handleFormSubmit} autoComplete="false">
      <BlackH3Text100 className="mb-2">{t("auth.sign_up.flow_steps.confirmation.title")}</BlackH3Text100>
      <BlackBodyText200 className="mb-4">
        {t("auth.sign_up.flow_steps.confirmation.description", { email: emailAddressCodeSent?.original })}
      </BlackBodyText200>
      <AuthFormInput
        register={register}
        placeholder="Verification code"
        autocomplete="one-time-code"
        role="presentation"
        required
        name="code"
        className="w-max-100 w-100"
      />

      <VerifyButton isLoading={isLoading} isDisabled={isLoading} type="submit" className="w-100 mt-4" />
      <AuthResendEmailCard isLoading={isResendCodeLoading} onResendCodeButtonPressed={handleResendCodeButtonPressed} />
    </form>
  );
}

export default SignUpFormFlowConfirmationStep;
