import { TeslaDrivePin, TeslaKeyStatus, TeslaVehicleInfo } from "./vehicle.types";

export enum TeslaControlCapability {
  NOT_SUPPORTED = "NOT_SUPPORTED",
  KEY_REQUIRED = "KEY_REQUIRED",
  SUPPORTED = "SUPPORTED",
}


export type TeslaControlsStatus = {
  key: TeslaKeyStatus & { supported: boolean };
  controls: {
    basic: TeslaControlCapability;
    antiTheft: TeslaControlCapability;
    pinToDrive: TeslaControlCapability;
  }
}

export type TeslaSliceState = {
  helperSlice: {
    isWakeUpModalOpen: boolean
    lastFetchedAt:Date|null
  }
  controlsStatusSlice: {
    controlsStatus: TeslaControlsStatus | null
    isLoading: boolean,
  }
  infoSlice: {
    info: TeslaVehicleInfo | null,
    isLoading: boolean,
    isSleeping: boolean
  }
  drivePinSlice: {
    drivePin: TeslaDrivePin | null,
    isLoading: boolean,
  }
}

