import CustomerSupportAvatarList from "components/avatar-list/CustomerSupportAvatarList";
import Row from "components/custom/Row";
import React from "react";

import { useIntercom } from "react-use-intercom";
import HoverUnderlinedTextButton from "./HoverUnderlinedTextButton";

type Props = {
  className?: string
};

function CustomerSupportTextButton({ className }: Props) {
  const intercom = useIntercom();
  const handleCustomerSupportLinkPressed = () => {
    intercom.show();
  };
  return (
    <Row className={`clickable align-items-center ${className ?? ""}`} onClick={handleCustomerSupportLinkPressed}>
      <CustomerSupportAvatarList />
      <HoverUnderlinedTextButton text={"Customer Support"} />
    </Row>
  );
}

export default CustomerSupportTextButton;
