import React, { useCallback, useState } from "react";

// Third-party
import SendbirdProviderBase from "@sendbird/uikit-react/SendbirdProvider";

import { generateAccessToken } from "../services/driver-support.services";
import SessionHandler from "@sendbird/uikit-react/handlers/SessionHandler";
import { useAuthStatus } from "hooks/auth.hooks";

export default function SendbirdDataProvider({ children }) {
  const { user } = useAuthStatus();

  const [accessToken, setAccessToken] = useState(undefined);

  const isExpired = useCallback((expiryDate) => {
    if (!expiryDate) {
      return true;
    }
    let now = new Date().getTime();
    return now > expiryDate;
  }, []);

  const configureSession = (sdk) => {
    try {
      const sessionHandler = new SessionHandler();
      sessionHandler.onSessionTokenRequired = (resolve, reject) => {
        console.log("SessionHandler.onSessionTokenRequired()");
        getAccessToken();
      };
      sessionHandler.onSessionRefreshed = () => {
        console.log("SessionHandler.onSessionRefreshed()");
      };
      sessionHandler.onSessionError = (err) => {
        console.log("SessionHandler.onSessionError()", err);
        getAccessToken();
      };
      sessionHandler.onSessionClosed = () => {
        console.log("SessionHandler.onSessionClosed()");
      };

      return sessionHandler;
    } catch (error) {
      console.error(error);
    }
  };

  const getAccessTokenFromBackend = useCallback(async () => {
    console.log("access token is retrieving from backend...");
    try {
      const res = await generateAccessToken();

      if (res.data) {
        setAccessToken(res.data.token);
        const accessObjectStringify = JSON.stringify(res.data);
        sessionStorage.setItem("storedAccessObject", accessObjectStringify);
      }
    } catch (error) {
      console.error("error", error);
    }
  }, []);

  const getAccessTokenFromCache = useCallback(() => {
    console.log("access token is retrieving from cache...");
    const storedAccessObject = sessionStorage.getItem("storedAccessObject");

    try {
      if (storedAccessObject && storedAccessObject !== "undefined") {
        const parsedData = JSON.parse(storedAccessObject);

        if (parsedData && !isExpired(parsedData.expiresAt)) {
          setAccessToken(parsedData.token);
          return;
        } else {
          throw Error("token is expired");
        }
      } else {
        throw Error("token is empty");
      }
    } catch (error) {
      getAccessTokenFromBackend();
    }
  }, [getAccessTokenFromBackend, isExpired]);

  const getAccessToken = useCallback(() => {
    if (!accessToken) {
      return getAccessTokenFromCache();
    }

    getAccessTokenFromBackend();
  }, [accessToken, getAccessTokenFromBackend, getAccessTokenFromCache]);

  const appId = process.env.REACT_APP_SEND_BIRD_APP_ID;
  const userId = user?.id;

  return (
    <SendbirdProviderBase
      key={userId + accessToken}
      allowProfileEdit={false}
      appId={appId} // Specify your Sendbird application ID.
      userId={userId}
      accessToken={accessToken}
      children={children}
      configureSession={configureSession}
    />
  );
}
