import React from "react";
import HoverUnderlinedTextButton from "./HoverUnderlinedTextButton";

type Props = {
  onClick?: () => void
  textClassName?:string
}

const LearnMoreTextButton = ({ onClick,textClassName }: Props) => {
  return (
    <HoverUnderlinedTextButton textClassName={textClassName} text={"Learn more"} onClick={onClick} />
  );
};

export default LearnMoreTextButton;