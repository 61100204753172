/* eslint-disable no-unused-vars */
import { Pagination, PaginationUrlQuery } from "types/api.types";
import { Driver } from "./driver.types";
import { Period, SortContext } from "./filter.types";
import { PayerType } from "./payment.types";
import { Vehicle } from "./vehicle.types";


export enum ChargingDriverFilterOptionKey {
  NO_ASSIGNED_DRIVER = "NO_ASSIGNED_DRIVER"
}

export enum ChargingPaymentState {
  UNPAID = "UNPAID",
  PAID = "PAID",
  INAPPLICABLE = "INAPPLICABLE"
}

export enum ChargingProvider {
  // HOME is specific to fleets service for identifying home charging records
  HOME = "HOME",
  // The rest are for the providers in the station service (StationProvider enum)
  DEPOT = "DEPOT",
  // The rest are for the providers in the station service (StationProvider enum)
  CHARGE_POINT = "CHARGE_POINT",
  ELECTRIFY_AMERICA = "ELECTRIFY_AMERICA",
  EVGO = "EVGO",
  EV_CONNECT = "EV_CONNECT",
  FLO = "FLO",
  TESLA = "TESLA",
  TESLA_DESTINATION = "TESLA_DESTINATION",
}

export type ChargingFilters = {
  provider?: {
    $in?: (keyof typeof ChargingProvider)[]
    $nin?: (keyof typeof ChargingProvider)[]
  },
  vehicle?: {
    $in: string[]
  },
  user?: {
    $in: string[]
  },
  payer?: {
    $in: PayerType[]
  },
  paymentState?: {
    $in: ChargingPaymentState[]
  },
  createdAt?: {
    $within?: Period,
    $gte?: string | Date | null,
    $lt?: string | Date | null
  }
  q?: {
    $eq?: string
  }
}


export interface ChargingQuery {
  pagination?:PaginationUrlQuery
  filters: ChargingFilters;
  sort?: SortContext;
}


export type TotalEnergyAndCostByPeriodAndProvider = {
  period: string
  provider: string
  totalEnergy: number
  totalCost: number
}

export enum ChargingState {
  INITIAL = "INITIAL",
  START_WAITING = "START_WAITING",
  ACTIVE = "ACTIVE",
  STOP_WAITING = "STOP_WAITING",
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
}


export type BaseCharging = {
  id: string
  user?: Driver
  vehicle?: Vehicle
  cost: number
  energyDelivered: string
  provider: string
}


export type Charging = BaseCharging & {
  organizationId: string
  vehicleId: string
  userId: string
  connectorId: string
  chargerName: string
  state: ChargingState,
  status?: string,
  type: string
  payer: number
  duration: number
  maxSessionDuration: number
  startDate: number
  paymentState: ChargingPaymentState
  invoiceId: string
  source: string
  chargingType: string
  createdAt: string
  updatedAt: string
  version: number
}


export type ChargingForCsv = Charging | {
  cost: string
  user?: string
  vehicleName?: string
  vehicleMake?: string
  vehicleVin?: string
  vehicleModel?: string
}

export enum ConnectionStatus {
  CONNECTED = "CONNECTED",
  DISCONNECTED = "DISCONNECTED",
  NOT_CONNECTED = "NOT_CONNECTED"
}

export enum ChargingUpdateCommands {
  ASSIGN_DRIVER = "AssignDriver",
  UPDATE_AS_ORGANIZATION_PAYS = "UpdateAsOrganizationPays",
}


export type ChargingAssignDriverPayload = {
  command: ChargingUpdateCommands.ASSIGN_DRIVER,
  driverId: string
}

export type UpdateChargingPayerToOrgPayload = {
  command: ChargingUpdateCommands.UPDATE_AS_ORGANIZATION_PAYS
}