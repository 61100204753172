import qs from "qs";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import { setDriver, setDriversAndMeta, setIsDriversLoading } from "redux/slices/driverSlice";
import { getChargingSessionsAsync } from "services/charging.service";
import { getDriverAsync, getDriversAsync, getDriverSignupAsync, getDriverSignupsAsync } from "services/driver.services";
import { PaginationMeta } from "types/api.types";
import { Charging, ChargingQuery } from "types/charging-sessions.types";
import { Driver, DriverSignUpApplication, DriverSignUpApplicationStatus } from "types/driver.types";
import { Query } from "types/filter.types";
import { getAllPaginatedListByLoopAsync } from "utils/data.utils";
import { getQueryWithDefaultPagination } from "utils/query.utils";
import { useAppDispatch, useAppSelector } from "../redux.hooks";

export const useDrivers = () => {
  const driverState = useAppSelector(state => state.driver);
  return { ...driverState };
};


export const useAllDriversAsync = () => {
  const [allDrivers, setAllDrivers] = useState<Driver[]>([]);
  const [isLoading, setIsLoading] = useState(false);


  const getAllDriversAsyncCallback = useCallback(
    async () => {
      try {
        setIsLoading(true);
        const response = await getAllPaginatedListByLoopAsync<Driver>(getDriversAsync);
        setAllDrivers(response);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    },
    []
  );


  useEffect(() => {
    getAllDriversAsyncCallback();
  }, [getAllDriversAsyncCallback]);


  return { allDrivers, isLoading };
};


export const useDriversAsync = () => {

  const dispatch = useAppDispatch();
  const { search } = useLocation();


  const handleGetDriversAsyncCallback = useCallback(
    async (search: string) => {
      try {
        dispatch(setIsDriversLoading(true));

        const queryString = getQueryWithDefaultPagination(search);
        const res = await getDriversAsync(queryString);

        dispatch(setDriversAndMeta({ drivers: res.data, meta: res.meta }));
      } catch (err) {
        console.error(err);
      } finally {
        dispatch(setIsDriversLoading(false));
      }
    },
    [dispatch]
  );

  useEffect(() => {
    handleGetDriversAsyncCallback(search);
  }, [handleGetDriversAsyncCallback, search]);


  return { ...useAppSelector(state => state.driver) };
};


export const useDriverAsync = (driverId_?: string) => {
  const { driverId } = useParams<{ driverId: string }>();
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useAppDispatch();


  const handleGetDriverAsyncCallback = useCallback(
    async () => {
      try {
        setIsLoading(true);
        const res = await getDriverAsync(driverId ?? driverId_);
        dispatch(setDriver(res.data ?? null));
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch, driverId, driverId_]
  );

  useEffect(() => {
    handleGetDriverAsyncCallback();
  }, [handleGetDriverAsyncCallback]);


  return { isLoading, ...useAppSelector(state => state.driver), refetchDriver: handleGetDriverAsyncCallback };
};


export const useDriverChargingListAsync = () => {
  const { driverId } = useParams<{ driverId: string }>();
  const [driverChargingList, setDriverChargingList] = useState<Charging[]>([]);
  const [chargingListMeta, setChargingListMeta] = useState<PaginationMeta | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const { search } = useLocation();

  const fetchDriverChargingListAsync = useCallback(
    async (query: string) => {

      const defaultQuery: ChargingQuery = {
        pagination: { page: "0", size: "20" },
        filters: {
          user: {
            $in: [driverId]
          }
        }
      };

      const queryObject = qs.parse(query, { ignoreQueryPrefix: true }) as Query<Charging>;
      // Merge filters deeply
      const mergedFilters = {
        ...defaultQuery,
        filters: {
          ...(queryObject?.filters || {}),
          user: defaultQuery.filters.user
        },
        pagination: {
          ...defaultQuery.pagination,
          ...(queryObject?.pagination || {})
        }
      };

      const finalQueryString = qs.stringify(mergedFilters, {
        skipNulls: true,
        addQueryPrefix: true
      });


      return getChargingSessionsAsync(finalQueryString);
    },
    [driverId]
  );

  const handleGetDriverChargingListByIdAsync = useCallback(
    async (queryString: string) => {
      try {
        setIsLoading(true);

        const response = await fetchDriverChargingListAsync(queryString);

        setChargingListMeta(response.meta);
        setDriverChargingList(response.data ?? []);

      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    },
    [fetchDriverChargingListAsync]
  );


  useEffect(() => {
    handleGetDriverChargingListByIdAsync(search);
  }, [handleGetDriverChargingListByIdAsync, search]);


  return { isLoading, driverChargingList, chargingListMeta, fetchDriverChargingListAsync };
};


export const useDriverSignupsAsync = () => {
  const [driverSignups, setDriverSignups] = useState<DriverSignUpApplication[]>([]);
  const [meta, setMeta] = useState<PaginationMeta>();
  const [isLoading, setIsLoading] = useState(false);


  const { search } = useLocation();


  const handleGetDriverSignupsAsyncCallback = useCallback(
    async (queryString: string) => {

      const defaultQuery = qs.stringify({
        filters: {
          status: { $in: [DriverSignUpApplicationStatus.PENDING_REVIEW] }
        }
      }, {
        skipNulls: true,
        addQueryPrefix: !queryString
      });
      try {
        setIsLoading(true);
        const res = await getDriverSignupsAsync(queryString + (queryString ? "&" : "") + defaultQuery);
        setDriverSignups(res.data ?? []);
        setMeta(res.meta);
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    },
    []
  );

  useEffect(() => {
    handleGetDriverSignupsAsyncCallback(search);
  }, [handleGetDriverSignupsAsyncCallback, search]);


  return { isLoading, meta, driverSignups };
};


export const useDriverSignupAsync = () => {
  const { driverId } = useParams<{ driverId: string }>();
  const [isLoading, setIsLoading] = useState(false);

  const [driverSignup, setDriverSignup] = useState<DriverSignUpApplication | undefined>(undefined);


  const handleGetDriverSignupAsyncCallback = useCallback(
    async () => {
      try {
        setIsLoading(true);
        const res = await getDriverSignupAsync(driverId);
        setDriverSignup(res.data);
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    },
    [driverId]
  );

  useEffect(() => {
    handleGetDriverSignupAsyncCallback();
  }, [handleGetDriverSignupAsyncCallback]);


  return { isLoading, driverSignup };
};
