import qs from "qs";
import { useCallback, useMemo } from "react";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";


export function useSearchParams() {
  const { search } = useLocation();

  return useMemo(
    () => new URLSearchParams(search),
    [search]
  );
}


export function useUrlQuery<T>() {
  const { pathname, search } = useLocation();

  const parsedQuery = useMemo(() => qs.parse(search, { ignoreQueryPrefix: true }), [search]) as T;

  const history = useHistory();


  const handleReplaceUrl = useCallback(
    (obj) => {
      const query = qs.stringify(obj, {
        skipNulls: true,
        addQueryPrefix: true
      });
      history.replace(pathname + query);
    },
    [history, pathname]
  );


  const handleSetQuery = useCallback(
    (callback: (currentQuery: T) => T) => {
      const newQuery = {
        ...parsedQuery,
        ...callback(parsedQuery)
      };
      handleReplaceUrl(newQuery);
    },
    [handleReplaceUrl, parsedQuery]
  );


  const resetQuery = useCallback(
    () => {
      history.replace(pathname);
    },
    [history, pathname]
  );


  return { query: parsedQuery, setQuery: handleSetQuery, resetQuery };

}