import { RotateLeft, RotateRight } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { Autocomplete, GoogleMap, Rectangle } from "@react-google-maps/api";
import React, { useRef, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useResizeMapFullWidthHeight } from "../../hooks/google-maps.hooks";
import { MapWithRectangleBoundsHooks } from "../../types/map.types";
import { NeutralTitleSmallTextBlack } from "../text/Text";


type Props = MapWithRectangleBoundsHooks & {
  titleOverrides?: JSX.Element;
  title?: string;
  isReadonly?: boolean;
  mapId: string
}

const MapWithRectangleBounds: React.FC<Props> = ({
                                                   mapBounds,
                                                   setMapBounds,
                                                   setBoundsToSubmit,
                                                   readOnlyAddress,
                                                   setAutoCompleteAddress,
                                                   center,
                                                   zoom,
                                                   isLoaded,
                                                   isReadonly,
                                                   title,
                                                   titleOverrides,
                                                   mapId = "map-container"
                                                 }: Props) => {


  const [map, setMap] = useState<google.maps.Map | null>(null);

  const { mapDimensions } = useResizeMapFullWidthHeight(map, mapId);

  const rectangleRef = useRef<google.maps.Rectangle | null>(null);
  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);


  const onEdit = () => {
    if (rectangleRef.current) {
      const newBounds = rectangleRef.current?.getBounds()?.toJSON();
      if (newBounds) {
        setMapBounds(newBounds);
      }
    }
  };

  const onBoundsChanged = () => {
    if (rectangleRef.current) {
      const bounds = rectangleRef.current?.getBounds()?.toJSON();
      if (bounds) {
        setBoundsToSubmit(bounds); // Optionally set the bounds as before
      }
    }
  };


  const onPlaceChanged = () => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current?.getPlace();
      if (place?.formatted_address)
        setAutoCompleteAddress(place.formatted_address);
    }
  };

  const handleRotateRight = () => {
    if (map) {
      const currentHeading = map.getHeading() ?? 0;
      map.setHeading(currentHeading + 15);
    }
  };

  const handleRotateLeft = () => {
    if (map) {
      const currentHeading = map.getHeading() ?? 0;
      map.setHeading(currentHeading - 15);
    }
  };


  return (
    <div id={mapId} className={"h-100 w-100"}>
      {(!isLoaded || !center) ? (
          <div className={"center h-300px w-100"}>
            <CircularProgress size={75} style={{ color: "#1E44FF" }} />
          </div>
        ) :
        <>
          {titleOverrides ?? <NeutralTitleSmallTextBlack className={"mb-1"}>{title}</NeutralTitleSmallTextBlack>}
          <Autocomplete onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
                        onPlaceChanged={onPlaceChanged}>
            <input
              className={"form-control mb-3"}
              id={"google-autocomplete-address"}
              defaultValue={readOnlyAddress}
              placeholder={readOnlyAddress}
            />
          </Autocomplete>

          {<GoogleMap
            options={{
            //  mapId: "a062ab7602d14776",
            //  rotateControl: true,
              streetViewControl: false,
              fullscreenControl: false
            }}
            mapContainerStyle={mapDimensions}
            mapContainerClassName="map-container"
            center={center}
            zoom={zoom}
            onLoad={mapInstance => setMap(mapInstance)}
          >
            <Rectangle

              bounds={mapBounds}
              editable={!isReadonly}
              draggable={!isReadonly}
              onBoundsChanged={onBoundsChanged}
              onDragEnd={onEdit}
              onLoad={rectangle => (rectangleRef.current = rectangle)}
              onUnmount={() => (rectangleRef.current = null)}
            />

            {/*

            <div className={"rotate-controls-container"}>
              <RotateRight onClick={handleRotateRight} width={75} height={75} className={"m-1"} />
              <RotateLeft onClick={handleRotateLeft} width={75} height={75} className={"m-1"} />
            </div>
            */}

          </GoogleMap>}
        </>
      }
    </div>
  );
};

const MapWithRectangleBoundsErrorBoundary = (props: Props) => {
  return (
    <ErrorBoundary fallback={<>Something went wrong related map.</>}>
      <MapWithRectangleBounds {...props} />
    </ErrorBoundary>
  );
};

export default MapWithRectangleBoundsErrorBoundary;

