import { CSVLink } from "react-csv";
import { Semaphore } from "async-mutex";

import React, { useCallback, useEffect, useRef, useState } from "react";
import { PaginatedApiResponse } from "types/api.types";
import { flattenMapToSortedArray } from "utils/array.utils";
import qs from "qs";
import { useUrlQuery } from "./router.hooks";
import { Query } from "types/filter.types";
import { useHistory } from "react-router";


const semaphore = new Semaphore(2);

export const useExportTableWithSemaphore = (
  fetchListAsync: (query: string) => Promise<PaginatedApiResponse<any>>,
  csvRowFormatter: (c: any) => any,
  totalPages: number,
  rowSizePerPage: number
) => {
  const { query: parsedQuery } = useUrlQuery<Query<any>>();


  const excelRef = useRef<CSVLink>();
  const [allListForCsv, setAllListForCsv] = useState<any[]>([]);
  const [progress, setProgress] = useState(0);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [fetchedPageCount, setFetchedPageCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (totalPages) {
      const percent = (fetchedPageCount / totalPages) * 100;
      setProgress(percent);
    }
  }, [fetchedPageCount, totalPages]);


  useEffect(() => {
    // Reset the cache since we have a new query
    setAllListForCsv([]);
  }, [parsedQuery]);


  const fetchListAsyncWithSemaphore = useCallback(
    async (page: number, fetchedMapList: Map<number, any[]>) => {
      const [_, release] = await semaphore.acquire();
      try {
        const queryString = qs.stringify({
          ...parsedQuery, pagination: {
            page, size: parsedQuery.pagination?.size
          }
        }, {
          skipNulls: true,
          addQueryPrefix: true
        });
        const response = await fetchListAsync(queryString);
        setFetchedPageCount((c) => c + 1);

        const newEntries = response?.data ?? [];
        fetchedMapList.set(page, newEntries);
      } finally {
        release();
      }
    },
    [fetchListAsync, parsedQuery]
  );


  const handleExportTableWithSemaphore = useCallback(async () => {
    if (isLoading) {
      return;
    }

    const downloadCsvFile = () => excelRef?.current?.link?.click();

    setFetchedPageCount(0);
    setErrorMessage(undefined);

    // Download cached data
    if (allListForCsv.length > 0) {
      downloadCsvFile();
      return;
    }

    setIsLoading(true);

    const fetchedMapList = new Map<number, any[]>();
    const promises: Promise<void>[] = [];

    try {
      for (let page = 0; page <= totalPages; page++) {
        promises.push(fetchListAsyncWithSemaphore(page, fetchedMapList));
      }

      await Promise.all(promises);
    } catch (error: any) {
      console.error(error);
      // Break the process
      return setErrorMessage(error?.message ?? "Sorry, we couldn't complete your action right now. Please try again.");
    } finally {
      setIsLoading(false);
    }

    const listsForCsv = flattenMapToSortedArray<any>(fetchedMapList).map((c) => {
      return csvRowFormatter(c);
    });

    setAllListForCsv(listsForCsv);
    downloadCsvFile();
  }, [isLoading, allListForCsv.length, totalPages, fetchListAsyncWithSemaphore, csvRowFormatter]);

  return {
    progressText: errorMessage ?? progress.toFixed(0) + "%",
    allListForCsv,
    isLoading,
    handleExportTableWithSemaphore,
    excelRef
  };
};


export const useTableFilter = (list: any[] = [], filterKeys: string[]) => {
  const [filteredList, setFilteredList] = useState(list);

  useEffect(() => {
    setFilteredList(list);
  }, [list]);

  const handleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value.toLowerCase();
    if (!query) {
      setFilteredList(list);
      return;
    }

    const filtered = list.filter((item) => {
      let conditionCounter = 0;

      filterKeys.forEach((k) => {
        if (item[k]?.toLowerCase()?.includes(query)) {
          ++conditionCounter;
        }
      });

      return conditionCounter > 0;
    });

    setFilteredList(filtered);
  };

  return { handleFilter, filteredList };
};


/**
 * useTableRowHtmlElementReplacementWithRef - A custom hook that simulates `<a>` tag behavior over table row `<div>` elements.
 *
 * This hook selects all `div` elements with `role="row"` and the class `rdt_TableRow`, and adds an invisible `<a>` tag overlay.
 * The `href` attribute of the new `<a>` tags is dynamically generated using the provided `getRoutePath` function.
 * The hook also adds a click event listener to each row to navigate using React Router's `history.push`.
 *
 * @param {function} [getRoutePath] - Function to generate the route path. Takes an `id` as an argument and returns a string path.
 * @param {React.RefObject<HTMLDivElement>} tableRef - A ref object to the table container element.
 *
 * @example
 * const getRoutePath = (id) => `details/${id}`;
 * useTableRowHtmlElementReplacementWithRef(getRoutePath, tableRef);
 */

export const useTableRowHtmlElementReplacementWithRef = (
  getRoutePath?: (id: string) => string | undefined,
  tableRef?: React.RefObject<HTMLDivElement>
) => {
  const history = useHistory();

  useEffect(() => {
    if (!tableRef?.current) {
      console.log("No table ref available");
      return;
    }

    if (!getRoutePath) {
      console.log("No getRoutePath function provided. Table rows will not be updated.");
      return;
    }

    const rows = tableRef.current.querySelectorAll("div[role='row'].rdt_TableRow");

    rows.forEach((row) => {
      const rowId = row.getAttribute("id");
      const className = row.getAttribute("class");

      if (!rowId || !className) {
        console.warn("Row is missing a className or rowId. Skipping row update.", { row });
        return;
      }

      const extractedId = rowId.replace(/^row-/, "");
      const routePath = getRoutePath(extractedId);

      if (!routePath) {
        console.warn("Route path is missing. Skipping row update.", { row });
        return;
      }

      row.setAttribute("role", "link");
      row.setAttribute("tabindex", "0");


      const anchor = document.createElement("a");
      anchor.setAttribute("href", routePath);
      anchor.setAttribute("target", "_blank");
      anchor.style.position = "absolute";
      anchor.style.top = "0";
      anchor.style.left = "0";
      anchor.style.width = "100%";
      anchor.style.height = "100%";
      anchor.style.zIndex = "1";
      anchor.style.opacity = "0";
      anchor.style.cursor = "pointer";
      anchor.style.pointerEvents = "auto";

      const divRow = row as HTMLDivElement;
      divRow.style.position = "relative";

      // Attach click event to the row
      // Attach the click event to the anchor
      anchor.addEventListener("click", (e) => {
        e.preventDefault();

        history.push(routePath);
      });

      row.appendChild(anchor);
    });
  }, [getRoutePath, history, tableRef]);
};
