import { CircularProgress } from "@mui/material";
import React from "react";
import Row from "../custom/Row";
import { NeutralOverlineText200 } from "../text/Text";


function ProcessingCircularBar() {
  return (
    <Row className={"align-items-center"}>
      <CircularProgress size={28} style={{ color: "#6F7981FF" }} />
      <NeutralOverlineText200 className={"ml-2"}>Processing...</NeutralOverlineText200>
    </Row>
  );
}

export default ProcessingCircularBar;