import CancelButton from "components/button/CancelButton";
import PrimaryButton from "components/button/PrimaryButton";
import Row from "components/custom/Row";
import IosSwitch from "components/input/switch/IosSwitch";
import AlertConfirmDialog from "components/notification/AlertConfirmDialog";
import { BlackOverlineText200, NeutralTitleSmallTextBlack } from "components/text/Text";
import TooltipWithInfoIcon from "components/tooltip/TooltipWithInfoIcon";
import { useTeslaCommandHandlersAsync } from "hooks/data/vehicle-data-accessor.hooks";
import { useCallback } from "react";
import { TeslaCommand, TeslaDrivePinHook, TeslaManagementMode } from "types/vehicle.types";

type Props = {
  teslaDrivePinHook: TeslaDrivePinHook
};

function TeslaSystemStatusEditor({ teslaDrivePinHook }: Props) {
  const { drivePin } = teslaDrivePinHook;

  const {
    handleSubmitPinToDriveCommandAsync,
    isSubmitting,
    isConfirmDialogOpen,
    setIsConfirmDialogOpen
  } = useTeslaCommandHandlersAsync();

  const isEnabled = drivePin?.drive?.managementMode === TeslaManagementMode.SYSTEM;

  const handleToggleSystemStatusAsync = useCallback(async () => {
    await handleSubmitPinToDriveCommandAsync(isEnabled
      ? TeslaCommand.DISABLE_SYSTEM_MANAGED_DRIVE_PIN
      : TeslaCommand.ENABLE_SYSTEM_MANAGED_DRIVE_PIN);
  }, [handleSubmitPinToDriveCommandAsync, isEnabled]);


  return (
    <>
      <AlertConfirmDialog
        isDialogOpen={isConfirmDialogOpen}
        overrideButtons={
          <>
            <div className="mr-2">
              <CancelButton onClick={() => setIsConfirmDialogOpen(false)} />
            </div>

            <PrimaryButton
              text={isEnabled ? "Disable" : "Enable"}
              isLoading={isSubmitting}
              onClick={handleToggleSystemStatusAsync}
            />
          </>
        }
        toggleIsDialogOpen={() => setIsConfirmDialogOpen(false)}
        title={`Are you sure you want to ${isEnabled ? "disable" : "enable"} system manged PIN to drive?`}
        description={
          isEnabled
            ? "When disabled, the system will not automatically activate the PIN to Drive feature for your vehicle under specific conditions."
            : "When enabled, the system may automatically activate the PIN to Drive feature for your vehicle under specific conditions."
        }
      />

      <Row className="justify-between align-items-center">
        <Row className="h-100 align-items-center">
          <NeutralTitleSmallTextBlack>System managed</NeutralTitleSmallTextBlack>
          <TooltipWithInfoIcon
            description="When enabled, the system may automatically activate the PIN to Drive feature for your vehicle under specific conditions, such as failure to pay the driver invoice within 48 hours or not adding a payment method to your profile within 12 hours." />
        </Row>


        <IosSwitch
          onToggle={() => {
            setIsConfirmDialogOpen(true);
          }}
          label={isEnabled ? "Enabled" : "Disabled"}
          isChecked={isEnabled}
        />


      </Row>

      <BlackOverlineText200 className="mt-1">
        When enabled, the system can automatically assign a PIN to the drive due to non-payment of the driver's bill or
        in certain situations.
      </BlackOverlineText200>
    </>
  );
}

export default TeslaSystemStatusEditor;
