import React from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import PageHeader from "components/header/PageHeader";
import InfoBadge from "../../components/badge/InfoBadge";
import DepotChargingLocationsTable from "../../components/depot-charging-location/DepotChargingLocationsTable";

const DepotChargingLocationsPage = () => {
  return (
    <React.Fragment>
      <Head title={"Depot Chargers"} />
      <Content className="h-auto">
        <PageHeader title={"Depot Chargers"}
                    description={"Add and manage depot charging locations to track charging sessions. Charging sessions at these locations will be automatically identified as depot charging."} />

        <div className="mt-2 mb-4">
          <InfoBadge
            text={"Depot charging data is currently available only for vehicles using Geotab or Samsara. If you have added depot chargers but no charging data, please ensure your vehicle is equipped with either system."} />
        </div>
        <DepotChargingLocationsTable />
      </Content>
    </React.Fragment>
  );

};

export default DepotChargingLocationsPage;
