import React, { useState } from "react";
import Col from "../../custom/Col";
import Row from "../../custom/Row";
import CustomModalWithXIcon from "../../modal/CustomModalWithXIcon";
import { BlackBodyText100, BlackBodyText200, BlackH6Text100 } from "../../text/Text";

import { CustomerServiceLink } from "../../links/Links";
import AddVehicleNeedHelpImage from "../../../assets/images/vehicle/AddVehicleNeedHelpImage.png";
import { useTranslation } from "react-i18next";
import OutlinedButtonWithIcon from "../../button/OutlinedButtonWithIcon";
import InfoIcon from "../../icon/icons/InfoIcon";
import { AppColors } from "../../../utils/colors.utils";
import CustomerSupportTextButton from "../../button/CustomerSupportTextButton";

export default function VehicleDetailsInfoButtonAndModal() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation("common");
  return (
    <>
      <CustomModalWithXIcon isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}>
        <Row className="w-100 h-100 ">
          <Col md="5" className="bg-blue-1000  center p-4">
            <img src={AddVehicleNeedHelpImage} alt="AddVehicleNeedHelpImage" />
          </Col>
          <Col md="7" className="p-5 h-max-80vh overflow-auto">
            <BlackH6Text100 className={"mb-2"}>{t("vehicle.vehicle_details.info_modal.title1")}</BlackH6Text100>

            <BlackBodyText100 className="mt-4">{t("vehicle.vehicle_details.info_modal.subtitle1")}</BlackBodyText100>
            <BlackBodyText200>{t("vehicle.vehicle_details.info_modal.description1")}</BlackBodyText200>

            <BlackBodyText100 className="mt-4">{t("vehicle.vehicle_details.info_modal.subtitle2")}</BlackBodyText100>
            <BlackBodyText200>{t("vehicle.vehicle_details.info_modal.description2")}</BlackBodyText200>

            <CustomerSupportTextButton className={'mt-4'}/>
          </Col>
        </Row>
      </CustomModalWithXIcon>

      <OutlinedButtonWithIcon isSmall={true} className="ml-3 clickable" leftIcon={<InfoIcon fill={AppColors.neutral100} />}
                              onClick={() => setIsModalOpen(true)} />


    </>
  );
}
