import Col from "components/custom/Col";
import Row from "components/custom/Row";
import HorizontalLine from "components/line/HorizontalLine";
import LoadingSkeleton from "components/loading/LoadingSkeleton";
import { BlackOverlineText200, NeutralBodyMediumText0, NeutralTitleSmallTextBlack } from "components/text/Text";
import TeslaControlledTooltipToggle from "components/vehicle-components/tesla/controls/TeslaControlledTooltipToggle";
import VehicleDetailsPageCardBlock from "components/vehicle-components/vehicle-details/VehicleDetailsPageCardBlock";
import {
  useTeslaCommandHandlersAsync,
  useTeslaControlsStatusReadonly,
  useTeslaInfoReadonly
} from "hooks/data/vehicle-data-accessor.hooks";
import { useCallback } from "react";
import { TeslaCommand, TeslaDrivePinHook } from "types/vehicle.types";
import { getMphByKm } from "utils/format.utils";
import CancelButton from "../../button/CancelButton";
import PrimaryButton from "../../button/PrimaryButton";
import AlertConfirmDialog from "../../notification/AlertConfirmDialog";

type Props = {
  teslaDrivePinHook: TeslaDrivePinHook
};

function VehicleDetailsAntiTheftModeEditor({ teslaDrivePinHook }: Props) {
  const {
    handleSubmitPinToDriveCommandAsync,
    isSubmitting,
    isConfirmDialogOpen,
    setIsConfirmDialogOpen,
    isSubmittingByCommand
  } = useTeslaCommandHandlersAsync();

  const { isAntiTheftControlsSupported } = useTeslaControlsStatusReadonly();
  const teslaInfo = useTeslaInfoReadonly();

  const { drivePin, isLoading } = teslaDrivePinHook;

  const isEnabled = drivePin?.antiTheft.enabled;

  const submitCommand = isEnabled ? TeslaCommand.DISABLE_ANTI_THEFT : TeslaCommand.ENABLE_ANTI_THEFT;

  const isBlockLoading = isSubmittingByCommand(submitCommand) || isLoading;

  const handleToggleTheftModeAsync = useCallback(async () => {
    await handleSubmitPinToDriveCommandAsync(submitCommand);
  }, [handleSubmitPinToDriveCommandAsync, submitCommand]);


  if (isBlockLoading) {
    return <div className={"py-2"}><LoadingSkeleton count={2} /></div>;
  }

  const speedLimit = teslaInfo.info?.speedLimit?.limit ?? 0;
  const chargeLimit = teslaInfo.info?.charge?.limit?.toString();

  return (
    <>
      <AlertConfirmDialog
        isDialogOpen={isConfirmDialogOpen}
        overrideButtons={
          <>
            <div className="mr-2">
              <CancelButton onClick={() => setIsConfirmDialogOpen(false)} />
            </div>

            <PrimaryButton
              //leftIcon={isOpen ? <UnlockFilledIcon className="mr-1" /> : <LockClosedFilledIcon className="mr-1" />}
              type="button"
              text={isEnabled ? "Disable" : "Enable"}
              isLoading={isSubmitting}
              onClick={handleToggleTheftModeAsync}
            />
          </>
        }
        toggleIsDialogOpen={() => setIsConfirmDialogOpen(false)}
        title={`${isEnabled ? "Disabled" : "Enable"} Anti-Theft Mode?`}
        description={
          isEnabled ? "Disabling Anti-Theft Mode will return the vehicle to its standard operational state." : ""
        }
        descriptionOverride={isEnabled ? undefined : <>
          <NeutralBodyMediumText0>
            Anti-Theft Mode will make the vehicle inconvenient to drive when theft is suspected.
            This mode:
          </NeutralBodyMediumText0>
          <NeutralBodyMediumText0>
            &#x2022; Limits speed and acceleration <br />
            &#x2022; Prevents charging above 20% <br />
            &#x2022; Boosts vehicle monitoring
          </NeutralBodyMediumText0>

          <NeutralBodyMediumText0 className={"mt-3"}>
            Anti-Theft Mode activates the next time the vehicle is put in Park, given that mobile data access is
            available.
          </NeutralBodyMediumText0>
        </>}
      />

      <Row className="justify-between align-items-center">
        <Col>
          <Row>
            <NeutralTitleSmallTextBlack>Anti-theft mode</NeutralTitleSmallTextBlack>
          </Row>

          <BlackOverlineText200 className="mt-1"> Anti-Theft Mode will make the vehicle inconvenient to drive when theft
            is suspected.</BlackOverlineText200>

        </Col>
        <div className="position-relative">
          <TeslaControlledTooltipToggle
            isSupported={isAntiTheftControlsSupported}
            onToggle={() => {
              setIsConfirmDialogOpen(true);
            }}
            label={""}
            isChecked={isEnabled}
          />
        </div>
      </Row>


      {isEnabled && (
        <>

          <HorizontalLine />
          <VehicleDetailsPageCardBlock
            paddingClass={"pt-0"}
            isBorderBottomVisible={false}
            columnClass="h-28px"
            isLoading={teslaInfo.isLoading}
            leftLabel="Charge limit"
            leftText={teslaInfo.isSleeping ? "Unavailable in Sleep Mode" : chargeLimit + "%"}
            rightLabel="Speed limit"
            rightText={teslaInfo.isSleeping ? "Unavailable in Sleep Mode" : getMphByKm(speedLimit)}
          />
        </>
      )}
    </>
  );
}

export default VehicleDetailsAntiTheftModeEditor;
