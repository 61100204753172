import CancelButton from "components/button/CancelButton";
import PrimaryButton from "components/button/PrimaryButton";
import LockClosedFilledIcon from "components/icon/icons/LockClosedFilledIcon";
import UnlockFilledIcon from "components/icon/icons/UnlockFilledIcon";
import AlertConfirmDialog from "components/notification/AlertConfirmDialog";
import TeslaBasicControlsButton from "components/vehicle-components/tesla/controls/TeslaBasicControlsButton";
import VehicleDetailsPageCardBlock from "components/vehicle-components/vehicle-details/VehicleDetailsPageCardBlock";
import { useTeslaCommandHandlersAsync } from "hooks/data/vehicle-data-accessor.hooks";
import { useCallback } from "react";
import { TeslaCommand, TeslaDataAccessorHook } from "types/vehicle.types";

type Props = {
  teslaHook: TeslaDataAccessorHook;
};

function TeslaLockStatusEditor({ teslaHook }: Props) {
  const { info } = teslaHook;

  const {
    handleSubmitBasicCommandAsync,

    isSubmittingByCommand,
    isConfirmDialogOpen,
    setIsConfirmDialogOpen
  } = useTeslaCommandHandlersAsync();

  const isLocked = info?.locked;
  const submitCommand = isLocked ? TeslaCommand.UNLOCK : TeslaCommand.LOCK;

  const handleToggleDriverStatusAsync = useCallback(async () => {
    await handleSubmitBasicCommandAsync(submitCommand);
  }, [handleSubmitBasicCommandAsync, submitCommand]);

  return (
    <>
      <AlertConfirmDialog
        isDialogOpen={isConfirmDialogOpen}
        overrideButtons={
          <>
            <div className="mr-2">
              <CancelButton onClick={() => setIsConfirmDialogOpen(false)} />
            </div>

            <PrimaryButton
              type="button"
              text={isLocked ? "Unlock" : "Lock"}
              isLoading={ isSubmittingByCommand(submitCommand)}
              onClick={handleToggleDriverStatusAsync}
            />
          </>
        }
        toggleIsDialogOpen={() => setIsConfirmDialogOpen(false)}
        title={`Are you sure you want to ${isLocked ? "Unlock" : "Lock"} this vehicle?`}
        description={
          isLocked
            ? "When you unlock the vehicle, it will be accessible from the outside again."
            : "When you lock the vehicle, it will be blocked from outside access."
        }
      />

      <VehicleDetailsPageCardBlock
        paddingClass={"pb-1"}
        columnClass="h-28px"
        isLoading={teslaHook.isLoading}
        leftLabel="Door status"
        leftText={teslaHook.isSleeping ? "Unavailable in Sleep Mode" : (isLocked ? "Locked" : "Unlocked")}
        rightTextOverwrite={
          <TeslaBasicControlsButton
            leftIcon={isLocked ? <UnlockFilledIcon className="mr-1" /> : <LockClosedFilledIcon className="mr-1" />}
            text={isLocked ? "Unlock" : "Lock"}
            isLoading={ isSubmittingByCommand(submitCommand)}
            onClick={() => setIsConfirmDialogOpen(true)}
          />
        }
      />
    </>
  );
}

export default TeslaLockStatusEditor;
