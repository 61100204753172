import { Driver } from "types/driver.types";
import DriverDetailsSectionCard from "./DriverDetailsSectionCard";
import DriverDetailsSectionFormBox from "../DriverDetailsSectionFormBox";
import { BlackBody2Text100, BlackOverlineText300, BlackSubtitleText100 } from "../../text/Text";
import { getFormattedNumberForUsdByCent } from "../../../utils/format.utils";
import Row from "../../custom/Row";
import React from "react";

type Props = {
  isLoading: boolean;
  driver?: Driver;
};


export default function DriverDetailsPageDepositCard({ driver, isLoading }: Props) {
  const depositedAmount = driver?.deposit.depositedAmount ?? 0;
  const liabilityAmount = driver?.deposit.liabilityAmount ?? 0;
  const isDepositRequired = liabilityAmount - depositedAmount > 0;

  /**
   * Effects
   */
  return (
    <>
      <DriverDetailsSectionCard
        loadingCount={3}
        isLoading={isLoading}
        contentClassName={"mt-0"}
        content={
          <>
            <Row className="center  justify-between flex-nowrap">
              <BlackSubtitleText100>Deposit</BlackSubtitleText100>
              <BlackBody2Text100>{isDepositRequired ? "Required" : "Not required"}</BlackBody2Text100>
            </Row>
            <BlackOverlineText300 className={"mt-1"}>You can review the deposit information for the driver under your
              organization.</BlackOverlineText300>

            {isDepositRequired && <>
              <DriverDetailsSectionFormBox
                className={"mt-4"}
                label={"Deposited Amount"}
                value={getFormattedNumberForUsdByCent(depositedAmount)}
                descriptionElement={
                  <BlackOverlineText300>This is the amount the driver has deposited.</BlackOverlineText300>
                }
              />

              <DriverDetailsSectionFormBox
                isBorderHidden
                label={"Liability Amount"}
                value={getFormattedNumberForUsdByCent(liabilityAmount)}
                descriptionElement={
                  <BlackOverlineText300>
                    This is the total deposit amount you've requested.
                  </BlackOverlineText300>
                }
              />
            </>}
          </>
        }
      />
    </>
  );
}
