import React from "react";
import { SvgProps } from "types/svg.types";

type Props = {};

function InboxPinToDriverIcon({ height = 24, width = 24 }: SvgProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="12" fill="#FFEFE6" />
      <path
        d="M10.8149 9.22406C10.8149 9.63031 10.8149 10.0241 10.943 10.3928C9.59614 11.9741 6.03989 16.1522 5.70864 16.4709C5.64281 16.53 5.59014 16.6023 5.55407 16.683C5.518 16.7638 5.49932 16.8512 5.49927 16.9397C5.49927 17.2053 5.66177 17.4616 5.79927 17.6053C6.00552 17.8209 6.88677 18.6366 7.04927 18.4803C7.53052 18.0116 7.62739 17.8866 7.82427 17.6928C8.12114 17.4022 7.79302 16.8084 7.89614 16.5678C7.99927 16.3272 8.10864 16.2803 8.28677 16.2428C8.46489 16.2053 8.78052 16.3334 9.02739 16.3366C9.28677 16.3397 9.42739 16.2303 9.62114 16.0491C9.77739 15.9053 9.88989 15.7709 9.89302 15.5616C9.89927 15.2803 9.49302 14.9084 9.79614 14.6116C10.0993 14.3147 10.5368 14.8053 10.8586 14.7678C11.1805 14.7303 11.5711 14.2834 11.6118 14.0928C11.6524 13.9022 11.2461 13.4116 11.3086 13.1334C11.3305 13.0397 11.5211 12.8209 11.6649 12.7897C11.8086 12.7584 12.4461 13.0053 12.5899 12.9741C12.7649 12.9366 12.968 12.7522 13.1336 12.6491C13.618 12.8584 14.0586 12.9428 14.6243 12.9428C16.7649 12.9428 18.4993 11.2741 18.4993 9.21781C18.4993 7.16156 16.7649 5.5 14.6243 5.5C12.4836 5.5 10.8149 7.16781 10.8149 9.22406ZM16.4993 8.5C16.4993 8.69778 16.4406 8.89112 16.3307 9.05557C16.2209 9.22002 16.0647 9.34819 15.882 9.42388C15.6992 9.49957 15.4982 9.51937 15.3042 9.48079C15.1102 9.4422 14.932 9.34696 14.7922 9.20711C14.6523 9.06725 14.5571 8.88907 14.5185 8.69509C14.4799 8.50111 14.4997 8.30004 14.5754 8.11732C14.6511 7.93459 14.7792 7.77841 14.9437 7.66853C15.1081 7.55865 15.3015 7.5 15.4993 7.5C15.7645 7.5 16.0188 7.60536 16.2064 7.79289C16.3939 7.98043 16.4993 8.23478 16.4993 8.5Z"
        fill="#FE5D02" />
    </svg>
  );
}

export default InboxPinToDriverIcon;
