import { BlackBodyText100, NeutralBody2Text100 } from "components/text/Text";
import { useAppSelector } from "hooks/redux.hooks";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { Icon } from "../../../../components/Component";
import { LinkList } from "../../../../components/links/Links";
import { signOut } from "../../../../utils/auth.utils";

const User = () => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation("common");
  const toggle = () => setOpen((prevState) => !prevState);

  const user = useAppSelector((state) => state.user.currentUser);

  return (
    <Dropdown isOpen={open} className="user-dropdown " toggle={toggle}>
      <DropdownToggle
        tag="a"
        href="#toggle"
        className="dropdown-toggle"
        onClick={(ev) => {
          ev.preventDefault();
        }}
      >
        <div className="user-toggle">
          <div className="user-avatar fs-14px h-24px w-24px bg-blue-400">
            {user?.name?.charAt(0).toUpperCase()}
          </div>
          <div className="user-info d-none d-md-block">
            <NeutralBody2Text100 className="avatar-dropdown-indicator">{user?.name}</NeutralBody2Text100>
          </div>
        </div>
      </DropdownToggle>
      <DropdownMenu right className="dropdown-menu-md dropdown-menu-s1">
        <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
          <div className="user-card sm">
            <div className="user-avatar">
              <span className="fs-16px">{user?.name?.charAt(0).toUpperCase()}</span>
            </div>
            <div className="user-info">
              <BlackBodyText100>{user?.name}</BlackBodyText100>
              <span className="sub-text fs-14px">{user?.businessName}</span>
            </div>
          </div>
        </div>
        {/*         <div className="dropdown-inner">
          <LinkList>
            <LinkItem link="/profile" icon="user-alt" onClick={toggle}>
              {t('user_menu.view_profile')}
            </LinkItem>
          </LinkList>
        </div> */}
        <div className="dropdown-inner">
          <LinkList>
            <a
              href="#logout"
              onClick={async (e) => {
                e.preventDefault();
                signOut();
              }}
            >
              <Icon name="signout"></Icon>
              <span>{t("user_menu.logout")}</span>
            </a>
          </LinkList>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default User;
