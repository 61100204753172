import { useState } from "react";
import Col from "../../custom/Col";
import Row from "../../custom/Row";
import CustomModalWithXIcon from "../../modal/CustomModalWithXIcon";
import { BlackBody2Text200, BlackBodyText100, BlackBodyText200, BlackH6Text100, BlackH6Text200 } from "../../text/Text";

import { CustomerServiceLink } from "../../links/Links";

import InfoIconWithCard from "components/icon/icons/InfoIconWithCard";
import { useTranslation } from "react-i18next";
import VehicleDetailsInfoModalIcon from "../../icon/icons/VehicleDetailsInfoModalIcon";
import Card from "../../card/Card";
import LearnMoreTextButton from "../../button/LearnMoreTextButton";

export default function VehicleDetailsAssignDriverInfoButtonAndModal() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation("common");
  return (
    <>
      <CustomModalWithXIcon isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}>
        <Row className="w-100 h-100 ">
          <Col md="5" className="bg-cherry-300  center p-4">
            <VehicleDetailsInfoModalIcon />
          </Col>
          <Col md="7" className="p-5 h-max-80vh overflow-auto">
            <BlackH6Text100 className={"mb-2"}>
              {t("vehicle.vehicle_details.assign_driver_info_modal.title1")}
            </BlackH6Text100>
            <BlackH6Text200 className={"mb-2"}>
              {t("vehicle.vehicle_details.assign_driver_info_modal.description1")}
            </BlackH6Text200>
            <BlackBodyText100 className="mt-4">
              {t("vehicle.vehicle_details.assign_driver_info_modal.title2")}
            </BlackBodyText100>
            <BlackBodyText200>{t("vehicle.vehicle_details.assign_driver_info_modal.description2")}</BlackBodyText200>
            <Col className={"pl-2"}>
              <BlackBodyText200>
                <span className="mr-1"> &#x2022;</span> {t("vehicle.vehicle_details.assign_driver_info_modal.article1")}
              </BlackBodyText200>
              <BlackBodyText200>
                <span className="mr-1"> &#x2022;</span> {t("vehicle.vehicle_details.assign_driver_info_modal.article2")}
              </BlackBodyText200>
              <BlackBodyText200>
                <span className="mr-1"> &#x2022;</span> {t("vehicle.vehicle_details.assign_driver_info_modal.article3")}
              </BlackBodyText200>
            </Col>

            <BlackBodyText100 className="mt-4">
              {t("vehicle.vehicle_details.assign_driver_info_modal.title3")}
            </BlackBodyText100>
            <BlackBodyText200 className={"mt-1"}>
              {t("vehicle.vehicle_details.assign_driver_info_modal.description3")}
            </BlackBodyText200>

            <CustomerServiceLink />
          </Col>
        </Row>
      </CustomModalWithXIcon>

      <Card className={"mt-4"}>
        <InfoIconWithCard className="clickable my-1 " onClick={() => setIsModalOpen(true)} />
        <BlackBody2Text200>Why is it essential to assign drivers to your vehicles?
          <LearnMoreTextButton textClassName={"ml-1"} onClick={() => setIsModalOpen(true)} />
        </BlackBody2Text200>
      </Card>

    </>
  );
}
