import FilterMenu from "components/filter/FilterMenu";
import React, { useMemo } from "react";
import FilterProviderSelector from "./FilterProviderSelector";
import ChargingSortingSelector from "./ChargingSortingSelector";
import FilterDateSelector from "components/filter/FilterDateSelector";
import FilterPaymentStateSelector from "./ChargingPaymentStateSelector";
import ChargingFilterVehicleSelector from "./ChargingFilterVehicleSelector";
import ChargingFilterDriverSelector from "./ChargingFilterDriverSelector";
import FilterDriverPayerStatusSelector from "../../driver-components/filter/FilterDriverPayerStatus";

type Props = {
  isVehicleMenu?: boolean;
  isDriverMenu?: boolean;
};

function ChargingFilterMenu({ isVehicleMenu, isDriverMenu }: Props) {
  const selectors = useMemo(() => {
    const baseSelectors = [
      <FilterProviderSelector key="provider" />,
      <FilterDriverPayerStatusSelector key="payer" />,
      <FilterPaymentStateSelector key="state" />,
      <FilterDateSelector key="date" />,
    ];

    
    if (isDriverMenu) {
      return [<ChargingFilterVehicleSelector key="vehicle" />, ...baseSelectors];
    }

    if (isVehicleMenu) {
      return [<ChargingFilterDriverSelector key="driver" />, ...baseSelectors];
    }

    // Default case: Include both vehicle and driver selectors
    return [
      <ChargingFilterDriverSelector key="driver" />,
      <ChargingFilterVehicleSelector key="vehicle" />,
      ...baseSelectors,
    ];
  }, [isVehicleMenu, isDriverMenu]);

  return (
    <FilterMenu
      selectors={selectors}
      sortingSelector={<ChargingSortingSelector />}
    />
  );
}

export default ChargingFilterMenu;