export enum AudienceType {
  USER = "USER",
  ORGANIZATION = "ORGANIZATION"
}

export enum InvoiceType {
  PLATFORM = "PLATFORM",
  CHARGING = "CHARGING",
  DEBIT = "DEBIT"
}


export type AddManualInvoicePayload = {
  audienceId: string
  audienceType: AudienceType
  description: string
  amount: number
}


export enum InvoicePaymentState {
  UNPAID = "UNPAID",
  PAID = "PAID",
  VOID = "VOID"
}


export enum InvoiceGeneratedAs {
  SETTLEMENT = "SETTLEMENT"
}

export enum BillingCycle {
  ONE_OFF = "one-off",
  WEEKLY = "weekly",
  MONTHLY = "monthly"
}

export enum LineItemType {
  DRIVER = "DRIVER",
  VEHICLE = "VEHICLE",
  CHARGING = "CHARGING",
  DEBIT = "DEBIT"
}

export type DriverData = {
  __typename: LineItemType.DRIVER;
  id: string;
  firstName: string;
  lastName: string;
  activatedAt: string;
  expiredAt: string;
  active: boolean;
};

export type VehicleData = {
  __typename: LineItemType.VEHICLE;
  id: string;
  name: string;
  plate: string;
  vin: string;
  createdAt: string;
  deletedAt: string;
};

export type ChargingData = {
  __typename: LineItemType.CHARGING;
  id: string;
  provider: string;
  chargerName: string;
  startedAt: string;
  stoppedAt: string;
  duration: number;
  energy: number;
  driverName: string;
  vehicleName: string;
  vehicleVin: string;
};

export type DebitData = {
  __typename: LineItemType.DEBIT;
  id: string;
  description: string;
};

export type LineItemData = DriverData | VehicleData | ChargingData | DebitData;


export type LineItem = {
  id: string
  objectId: string
  objectData: LineItemData
  quantity: number
  unitPrice: number
  grossAmount: number
  discountRate: number
  discountAmount: number
  netAmount: number
  orderBy: string
  createdAt: string
  updatedAt: string
}


export type Invoice = {
  id: string
  billingCycle: BillingCycle
  invoiceType: InvoiceType
  audienceType: AudienceType
  period: string
  periodStartedAt: string
  periodEndedAt: string
  stripeInvoiceId: string
  paymentState: InvoicePaymentState
  lineItems: LineItem[]
  grossAmount: number
  discountAmount: number
  netAmount: number
  createdAt: string
  updatedAt: string
  generatedAs: InvoiceGeneratedAs
  dueDate: string
  url: string
}


export type ChargingInvoiceLineItem = LineItem & { objectData: ChargingData }
export type PlatformInvoiceLineItem = LineItem & { objectData: VehicleData | DriverData }