import React, { useMemo } from "react";
import { InboxMessageType, MessageLevel } from "types/inbox.types";
import InboxSuspiciousChargingIcon from "../icon/icons/InboxSuspiciousChargingIcon";
import InboxUnpaidInvoiceIcon from "../icon/icons/InboxUnpaidInvoiceIcon";
import InboxPaymentIcon from "../icon/icons/InboxPaymentIcon";
import InboxNewSignupIcon from "../icon/icons/InboxNewSignupIcon";
import InboxUpcomingAssignmentIcon from "../icon/icons/InboxUpcomingAssignmentIcon";
import InboxPinToDriverIcon from "../icon/icons/InboxPinToDriverIcon";
import InboxUnassignedVehicleIcon from "../icon/icons/InboxUnassignedVehicleIcon";
import InboxMessageLevelIcon from "./InboxMessageLevelIcon";

type Props = {
  level?: MessageLevel;
  type?: InboxMessageType
  isLarge?: boolean;
};

function InboxMessageIcon({ level, isLarge, type }: Props) {
  const icon = useMemo(() => {
    const height = isLarge ? 48 : 28;
    const width = isLarge ? 48 : 28;
    switch (type) {
      case InboxMessageType.CHARGING_SUSPICIOUS:
        return <InboxSuspiciousChargingIcon width={width} height={height} />;
      case InboxMessageType.DRIVER_INVOICE_UNPAID:
        return <InboxUnpaidInvoiceIcon width={width} height={height} />;

      case InboxMessageType.DRIVER_PAYMENT_METHOD_MISSING:
        return <InboxPaymentIcon width={width} height={height} />;

      case InboxMessageType.DRIVER_SIGNUP_CREATED:
        return <InboxNewSignupIcon width={width} height={height} />;

      case InboxMessageType.VEHICLE_ASSIGNMENT_ENDS_SOON:
        return <InboxUpcomingAssignmentIcon width={width} height={height} />;

      case InboxMessageType.VEHICLE_DRIVE_PIN_SET:
        return <InboxPinToDriverIcon width={width} height={height} />;

      case InboxMessageType.VEHICLE_NOT_ASSIGNED:
        return <InboxUnassignedVehicleIcon width={width} height={height} />;

      default:
        return <InboxMessageLevelIcon isLarge={isLarge} level={level} />;
    }
  }, [isLarge, level, type]);

  return icon;
}


export default InboxMessageIcon;
