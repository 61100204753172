import Card from "components/card/Card";
import Row from "components/custom/Row";
import { BlackBodyText300, BlackSubtitleText100 } from "components/text/Text";
import React from "react";
import { useParams } from "react-router";
import HoverUnderlinedTextButton from "../../button/HoverUnderlinedTextButton";
import { TextButtonMode } from "../../button/TextButton";
import Col from "../../custom/Col";

type Props = {
  title?: string,
  titleOverwrite?: React.JSX.Element
  linkText?: string,
  linkRoute?: string,
  fullLink?: string
  onLinkClick?: () => void
  description?: string | React.JSX.Element
  content: React.JSX.Element
  className?: string
}

function VehicleDetailsOverviewCard({
                                      title,
                                      titleOverwrite,
                                      linkText,
                                      linkRoute,
                                      fullLink,
                                      onLinkClick,
                                      description,
                                      content,
                                      className
                                    }: Props): JSX.Element {
  const { vehicleId } = useParams<{ vehicleId: string }>();

  return (
    <Card className={className}>
      <Col className="mb-4">
        <Row className="w-100 flex-nowrap justify-between align-items-center">
          {titleOverwrite ?? <BlackSubtitleText100>{title}</BlackSubtitleText100>}

          {linkText && linkRoute &&
            <HoverUnderlinedTextButton href={fullLink ?? `/vehicles/${vehicleId}/${linkRoute}`}
                                       text={linkText} mode={TextButtonMode.BLUE} onClick={() => {
              if (onLinkClick) {
                onLinkClick();
                return;
              }
            }} />}
        </Row>
        {description && <BlackBodyText300>{description}</BlackBodyText300>}
      </Col>
      {content}
    </Card>
  );
}

export default VehicleDetailsOverviewCard;