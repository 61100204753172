import React from "react";

type Props = {};

function CircleBluedotLogoIcon({}: Props) {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ zIndex: 3 }}
    >
      <rect
        x="0.635802"
        y="0.635802"
        width="26.2222"
        height="26.2222"
        rx="13.1111"
        stroke="white"
        strokeWidth="0.728395"
      />
      <circle cx="13.7469" cy="13.7469" r="12.7469" fill="#1E44FF" />
      <g clipPath="url(#clip0_22784_39705)">
        <path
          d="M10.9355 10.9433C11.341 10.5377 11.8235 10.2097 12.3596 9.98299L11.2688 7.40039C10.3976 7.76925 9.61269 8.30244 8.95364 8.96149C8.29458 9.62054 7.7614 10.4055 7.39254 11.2766C7.02426 12.1478 6.82031 13.1063 6.82031 14.1121C6.82031 15.1179 7.02426 16.0759 7.39254 16.9476C7.7614 17.8188 8.29458 18.6037 8.95364 19.2627C9.61269 19.9218 10.3976 20.455 11.2688 20.8238L12.3596 18.2412C11.8235 18.0146 11.3404 17.6865 10.9355 17.2809C10.5299 16.8754 10.2018 16.3929 9.97514 15.8568C9.74846 15.3207 9.62318 14.731 9.62318 14.1127C9.62318 13.4944 9.74846 12.9047 9.97514 12.3686C10.2018 11.8325 10.5299 11.3495 10.9355 10.9445V10.9433ZM19.2549 8.96149C18.5958 8.30244 17.8109 7.76925 16.9398 7.40039L15.8489 9.98299C16.385 10.2097 16.8681 10.5377 17.2731 10.9433C17.6786 11.3489 18.0067 11.8314 18.2334 12.3675C18.4601 12.9036 18.5853 13.4933 18.5853 14.1115C18.5853 14.7298 18.4601 15.3195 18.2334 15.8556C18.0067 16.3917 17.6786 16.8748 17.2731 17.2798C16.8675 17.6853 16.385 18.0134 15.8489 18.2401L16.9398 20.8227C17.8109 20.4538 18.5958 19.9206 19.2549 19.2616C19.9139 18.6025 20.4471 17.8176 20.816 16.9464C21.1848 16.0753 21.3882 15.1167 21.3882 14.1109C21.3882 13.1052 21.1843 12.1472 20.816 11.2755C20.4471 10.4043 19.9139 9.61937 19.2549 8.96032V8.96149Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_22784_39705">
          <rect width="14.5679" height="13.4229" fill="white" transform="translate(6.82031 7.40039)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default CircleBluedotLogoIcon;
