import React from 'react';
import AddVehicleNeedHelpImage from "assets/images/vehicle/AddVehicleNeedHelpImage.png";
import NeedHelpButton from "components/button/NeedHelpLinkButton";
import Col from "components/custom/Col";
import Row from "components/custom/Row";
import { CustomerServiceLink } from "components/links/Links";
import CustomModalWithXIcon from "components/modal/CustomModalWithXIcon";
import { BlackBodyText200, BlackH6Text100, BlackSubtitleText100 } from "components/text/Text";
import { useState } from "react";
import { useTranslation } from "react-i18next";

type Props = {};

export default function AddVehicleFlowSelectionNeedHelpButtonAndModal({}: Props) {
  const { t } = useTranslation("common");
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <>
      <CustomModalWithXIcon isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}>
        <Row className="w-100 h-100 ">
          <Col md="5" className="bg-blue-1000  center p-4">
            <img src={AddVehicleNeedHelpImage} alt="AddVehicleNeedHelpImage" />
          </Col>
          <Col md="7" className="p-5 h-max-80vh overflow-auto">
            <BlackH6Text100 className={"mb-2"}>{t("vehicle.form.starter.smartcar.help_modal.title")}</BlackH6Text100>
            <BlackBodyText200>{t("vehicle.form.starter.smartcar.help_modal.description")}</BlackBodyText200>
            <BlackSubtitleText100 className={"mt-4"}>
              {t("vehicle.form.starter.smartcar.help_modal.subtitle1")}
            </BlackSubtitleText100>
            <BlackBodyText200>{t("vehicle.form.starter.smartcar.help_modal.description1")}</BlackBodyText200>
            <BlackSubtitleText100 className={"mt-4"}>
              {t("vehicle.form.starter.smartcar.help_modal.subtitle2")}
            </BlackSubtitleText100>
            <BlackBodyText200>{t("vehicle.form.starter.smartcar.help_modal.description2")}</BlackBodyText200>
            <BlackSubtitleText100 className={"mt-4"}>
              {t("vehicle.form.starter.smartcar.help_modal.subtitle3")}
            </BlackSubtitleText100>
            <BlackBodyText200>{t("vehicle.form.starter.smartcar.help_modal.description3")}</BlackBodyText200>
            <CustomerServiceLink />
          </Col>
        </Row>
      </CustomModalWithXIcon>
      <NeedHelpButton onClick={() => setIsModalOpen((isModalOpen) => !isModalOpen)} />
    </>
  );
}
