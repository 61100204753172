// React
import { useCallback } from "react";

// Utils & Hooks
// Store
// Components
import SelectionCard from "components/card/SelectionCard";
import Col from "components/custom/Col";
import { BlackBody2Text200, BlackH5Text0, BlackOverlineText300 } from "components/text/Text";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { AddVehicleFlowType } from "types/vehicle.types";
import { getAddVehicleFlowStarterContentInfo } from "utils/vehicle.utils";
import { useSamsaraConnection } from "../../../../hooks/vehicle.hooks";

type Props = {
  type: AddVehicleFlowType;
};

export default function AddVehicleFlowSelectionCard({ type }: Props) {
  const { t } = useTranslation("common");
  const history = useHistory();


  const { description, footer, icon, miniTitle, route, title } = getAddVehicleFlowStarterContentInfo(type);

  /**
   *
   */
  const handleVehicleFormTypeSelectionCardClicked = useCallback(() => {
    history.push(route);
  }, [history, route]);

  return (
    <SelectionCard
      padding={"p-3"}
      className="h-min-150px w-100"
      widthClassName={"w-100"}
      content={
        <Col>
          {miniTitle && <BlackOverlineText300>{miniTitle}</BlackOverlineText300>}
          <BlackH5Text0 className="mb-1">{title}</BlackH5Text0>
          <BlackBody2Text200>{description}</BlackBody2Text200>
          {footer}
        </Col>
      }
      description={description}
      icon={icon}
      onClick={handleVehicleFormTypeSelectionCardClicked}
      title={title}
    />
  );
}
