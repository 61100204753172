import { AxiosError } from "axios";
import { useAlertManager } from "hooks/alert.hooks";
import { useAppDispatch, useAppSelector } from "hooks/redux.hooks";
import { useVehicleIdFromParams } from "hooks/vehicle.hooks";
import qs from "qs";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import {
  setIsControlsStatusLoading,
  setIsTeslaDrivePinLoading,
  setIsTeslaInfoLoading,
  setIsTeslaSleeping,
  setTeslaControlsStatus,
  setTeslaDrivePin,
  setTeslaInfo
} from "redux/slices/teslaSlice";
import { setIsVehiclesLoading, setVehiclesAndMeta } from "redux/slices/vehicleSlice";
import { getChargingSessionsAsync } from "services/charging.service";

import {
  getTeslaChargingInvoicesAsync,
  getVehicleAsync,
  getVehicleChargingSummaryByIdAsync,
  getVehicleLocationsAsync,
  getVehiclesAsync,
  getVehicleTeslaAlertsAsync,
  getVehicleTeslaControlsStatusAsync,
  getVehicleTeslaDrivePinAsync,
  getVehicleTeslaInfoAsync,
  updateVehicleTeslaAsync
} from "services/vehicle.services";
import { PaginationMeta } from "types/api.types";
import { Charging } from "types/charging-sessions.types";
import { Query } from "types/filter.types";
import {
  CachedVehicleData,
  TeslaAlerts,
  TeslaChargingWithTeslaInvoice,
  TeslaCommand,
  TeslaError,
  TeslaVehicleStatus,
  Vehicle,
  VehicleChargingSummary,
  VehicleLocation
} from "types/vehicle.types";
import { getAllPaginatedListByLoopAsync } from "utils/data.utils";
import { handleApiErrorResponse } from "utils/error.utils";
import { getQueryWithDefaultPagination } from "utils/query.utils";
import {
  getTeslaCommandAlertSuccessMessage,
  getTeslaControlsStatusExtras,
  getTeslaControlsStatusKeyDetails
} from "utils/tesla.utils";
import { getVehicleConnectionStatus } from "utils/vehicle.utils";


export const useAllVehiclesAsync = () => {
  const [allVehicles, setAllVehicles] = useState<Vehicle[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const getAllVehiclesAsyncCallback = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await getAllPaginatedListByLoopAsync<Vehicle>(getVehiclesAsync);
      setAllVehicles(response);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    getAllVehiclesAsyncCallback();
  }, [getAllVehiclesAsyncCallback]);

  return { allVehicles, isLoading };
};


export const useAllTeslaVehiclesAsync = () => {
  const [allVehicles, setAllVehicles] = useState<Vehicle[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const getAllVehiclesAsyncCallback = useCallback(async () => {
    try {
      setIsLoading(true);

      const teslaBrandFilterQueryString = qs.stringify({
        filters: {
          brand: {
            $in: ["TESLA"]
          }
        }
      }, { addQueryPrefix: false, skipNulls: true });
      const response = await getAllPaginatedListByLoopAsync<Vehicle>((q) => getVehiclesAsync(q + "&" + teslaBrandFilterQueryString));
      setAllVehicles(response);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    getAllVehiclesAsyncCallback();
  }, [getAllVehiclesAsyncCallback]);

  return { allVehicles, isLoading };
};


export const useVehiclesAsync = () => {
  const dispatch = useAppDispatch();

  const { search } = useLocation();

  const getVehiclesAsyncCallback = useCallback(
    async (search: string) => {
      try {
        dispatch(setIsVehiclesLoading(true));
        const queryString = getQueryWithDefaultPagination(search);
        const res = await getVehiclesAsync(queryString);
        dispatch(setVehiclesAndMeta({ meta: res.meta, vehicles: res.data }));
      } catch (error) {
        console.error(error);
      } finally {
        dispatch(setIsVehiclesLoading(false));
      }
    },
    [dispatch]
  );

  useEffect(() => {
    getVehiclesAsyncCallback(search);
  }, [getVehiclesAsyncCallback, search]);

  return { ...useAppSelector((state) => state.vehicle), refetch: getVehiclesAsyncCallback };
};

export const useVehicles = () => {
  return { ...useAppSelector((state) => state.vehicle) };
};

export const useVehicleAsync = () => {
  const { vehicleId } = useParams<{ vehicleId: string }>();

  const [vehicle, setVehicle] = useState<Vehicle | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const handleGetVehicleCallbackAsync = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await getVehicleAsync(vehicleId);
      setVehicle(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [vehicleId]);

  useEffect(() => {
    handleGetVehicleCallbackAsync();
  }, [handleGetVehicleCallbackAsync]);

  return { isLoading, vehicle, refetch: handleGetVehicleCallbackAsync, ...getVehicleConnectionStatus(vehicle) };
};

export const useVehicleChargingSummaryAsync = () => {
  const { vehicleId } = useParams<{ vehicleId: string }>();
  const [vehicleChargingSummary, setVehicleChargingSummary] = useState<VehicleChargingSummary | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const handleGetVehicleChargingSummaryCallbackAsync = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await getVehicleChargingSummaryByIdAsync(vehicleId);
      setVehicleChargingSummary(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [vehicleId]);

  useEffect(() => {
    handleGetVehicleChargingSummaryCallbackAsync();
  }, [handleGetVehicleChargingSummaryCallbackAsync]);

  return { isLoading, vehicleChargingSummary };
};


export const useVehicleChargingListAsync = (isOverview?: boolean) => {
  const { vehicleId } = useParams<{ vehicleId: string }>();
  const [vehicleChargingList, setVehicleChargingList] = useState<Charging[]>([]);
  const [chargingListMeta, setChargingListMeta] = useState<PaginationMeta | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const { search } = useLocation();


  const fetchVehicleChargingListAsync = useCallback(
    async (query: string) => {
      const defaultFilters = {
        pagination: isOverview
          ? { page: 0, size: 3 }
          : { page: 0, size: 20 },
        filters: {
          vehicle: {
            $in: [vehicleId]
          }
        }
      };

      const queryObject = qs.parse(query, { ignoreQueryPrefix: true }) as Query<Charging>;

      // Merge filters deeply
      const mergedFilters = {
        ...defaultFilters,
        filters: {
          ...(queryObject?.filters || {}),
          vehicle: defaultFilters.filters.vehicle
        },
        pagination: {
          ...defaultFilters.pagination,
          ...(queryObject?.pagination || {})
        }
      };

      // Stringify the merged object into a query string
      const finalQueryString = qs.stringify(mergedFilters, {
        skipNulls: true,
        addQueryPrefix: true
      });

      return getChargingSessionsAsync(finalQueryString);
    },
    [isOverview, vehicleId]
  );

  const handleGetVehicleChargingListAsync = useCallback(
    async (query: string) => {
      try {
        setIsLoading(true);

        const response = await fetchVehicleChargingListAsync(query);
        setChargingListMeta(response?.meta);
        setVehicleChargingList(response?.data ?? []);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    },
    [fetchVehicleChargingListAsync]
  );

  useEffect(() => {
    handleGetVehicleChargingListAsync(search);
  }, [handleGetVehicleChargingListAsync, search]);

  return { isLoading, vehicleChargingList, chargingListMeta, fetchVehicleChargingListAsync };
};

export const useVehicleLocationsAsync = () => {
  const [vehicleLocations, setVehicleLocations] = useState<Array<VehicleLocation> | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const handleGetVehicleLocationsAsync = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await getVehicleLocationsAsync();
      setVehicleLocations(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    handleGetVehicleLocationsAsync();
  }, [handleGetVehicleLocationsAsync]);

  return { isLoading, vehicleLocations };
};


export const useVehicleTeslaAlertsAsync = (isConnected?: boolean) => {

  const { vehicleId } = useVehicleIdFromParams();
  const [alerts, setAlerts] = useState<TeslaAlerts | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const handleGetVehicleAlertsAsync = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await getVehicleTeslaAlertsAsync(vehicleId);
      setAlerts(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [vehicleId]);

  useEffect(() => {
    if (!isConnected) {
      return;
    }
    handleGetVehicleAlertsAsync();
  }, [handleGetVehicleAlertsAsync, isConnected]);

  return { isLoading, alerts };
};


export const useCachedVehicleSmartcarDataAsync = (vehicleId: string) => {
  const cacheDuration = 60000;

  // Use Map<string, CachedVehicleData> for caching vehicles
  const [cachedData, setCachedData] = useState<Map<string, CachedVehicleData>>(new Map());

  const [vehicle, setVehicle] = useState<Vehicle | undefined>(undefined);

  const [isLoading, setIsLoading] = useState(false);

  const handleCachedVehicleSmartcarDataAsync = useCallback(async () => {
    const currentTime = new Date().getTime();
    const cachedVehicleData = cachedData.get(vehicleId);

    if (cachedVehicleData && currentTime - cachedVehicleData.lastFetchedAt < cacheDuration) {
      setVehicle(cachedVehicleData.vehicle);
      return;
    }

    try {
      setIsLoading(true);

      const response = await getVehicleAsync(vehicleId);
      const vehicleData = response.data;
      setVehicle(vehicleData);

      const currentTime = new Date().getTime();
      setCachedData(
        new Map(cachedData).set(vehicleId, {
          vehicle: vehicleData,
          lastFetchedAt: currentTime
        })
      );
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [cachedData, vehicleId]);

  useEffect(() => {
    handleCachedVehicleSmartcarDataAsync();

    // Do not set handleCachedVehicleSmartcarDataAsync as a dependency since it triggers this effect when the cache is changed which causes an infinite loop.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleId]);

  return {
    isLoading,
    vehicle
  };
};

/**
 * Tesla
 */
export const useTeslaControlsStatusReadonly = () => {
  const controlsStatusSlice = useAppSelector(state => state.tesla.controlsStatusSlice);
  return {
    controls: controlsStatusSlice.controlsStatus?.controls,
    ...getTeslaControlsStatusKeyDetails(controlsStatusSlice.controlsStatus),
    isLoading: controlsStatusSlice.isLoading
  };
};


export const useVehicleTeslaControlsStatusAsync = (isConnected?: boolean) => {
  const { handleFetchTeslaControlsStatusCallbackAsync } = useTeslaCommandHandlersAsync();


  useEffect(() => {
    if (!isConnected) {
      return;
    }

    handleFetchTeslaControlsStatusCallbackAsync();
  }, [handleFetchTeslaControlsStatusCallbackAsync, isConnected]);


  return { ...useTeslaControlsStatusReadonly() };
};


export const useTeslaCommandHandlersAsync = () => {
  const { vehicleId } = useVehicleIdFromParams();
  const { handleOpenSuccessAlert, handleOpenErrorAlert } = useAlertManager();

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const dispatch = useAppDispatch();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submittingCommand, setSubmittingCommand] = useState<TeslaCommand | null>();


  const isSubmittingByCommand = (command: TeslaCommand) => {
    return submittingCommand === command;
  };

  const handleFetchTeslaControlsStatusCallbackAsync = useCallback(async () => {
    try {
      dispatch(setIsControlsStatusLoading(true));
      const response = await getVehicleTeslaControlsStatusAsync(vehicleId);
      dispatch(setTeslaControlsStatus(response.data));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setIsControlsStatusLoading(false));
    }
  }, [dispatch, vehicleId]);

  /**
   *
   */
  const handleGetVehicleTeslaInfoCallbackAsync = useCallback(async () => {
    try {
      dispatch(setIsTeslaInfoLoading(true));
      const response = await getVehicleTeslaInfoAsync(vehicleId);
      dispatch(setTeslaInfo(response.data));
      return {
        isTeslaSleeping: false
      };
    } catch (error) {
      console.error(error);
      const isTeslaSleeping = error instanceof AxiosError && error?.response?.data?.error === TeslaError.E_ASLEEP;
      dispatch(setIsTeslaSleeping(isTeslaSleeping));

      if (!isTeslaSleeping) {
        handleOpenErrorAlert(handleApiErrorResponse((error)));
      }
      return {
        isTeslaSleeping
      };
    } finally {
      dispatch(setIsTeslaInfoLoading(false));
    }
  }, [dispatch, handleOpenErrorAlert, vehicleId]);


  /**
   *
   */
  const handleGetVehicleTeslaDrivePinCallbackAsync = useCallback(async () => {
    try {
      dispatch(setIsTeslaDrivePinLoading(true));
      const response = (await getVehicleTeslaDrivePinAsync(vehicleId));
      dispatch(setTeslaDrivePin(response.data));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setIsTeslaDrivePinLoading(false));
    }
  }, [dispatch, vehicleId]);


  /**
   *
   */
  const revalidateTeslaInfo = useCallback(async () => {
    dispatch(setIsTeslaInfoLoading(true));

    // Give timeout to Prevent the inconsistency related lock data since Tesla api latency
    await new Promise((resolve) => setTimeout(resolve, 500));
    await handleGetVehicleTeslaInfoCallbackAsync();
  }, [dispatch, handleGetVehicleTeslaInfoCallbackAsync]);


  /**
   *
   */
  const revalidateTeslaDrivePin = useCallback(async () => {
    dispatch(setIsTeslaDrivePinLoading(true));

    // Give timeout to Prevent the inconsistency related lock data since Tesla api latency
    await new Promise((resolve) => setTimeout(resolve, 500));

    // Revalidate Tesla data
    handleGetVehicleTeslaDrivePinCallbackAsync();
    handleGetVehicleTeslaInfoCallbackAsync();

  }, [dispatch, handleGetVehicleTeslaDrivePinCallbackAsync, handleGetVehicleTeslaInfoCallbackAsync]);


  const handleSubmitCommandAsync = useCallback(async (command: TeslaCommand, callbackAsync: () => Promise<void>) => {
    try {
      setIsSubmitting(true);
      setSubmittingCommand(command);

      await updateVehicleTeslaAsync(vehicleId, {
        command: command
      });

      if (callbackAsync) {
        await callbackAsync();
      }

      handleOpenSuccessAlert(getTeslaCommandAlertSuccessMessage(command));
    } catch (error) {
      handleOpenErrorAlert(handleApiErrorResponse(error));
    } finally {
      setIsSubmitting(false);
      setSubmittingCommand(null);
      setIsConfirmDialogOpen(false);
    }
  }, [handleOpenErrorAlert, handleOpenSuccessAlert, vehicleId]);

  const handleSubmitBasicCommandAsync = useCallback(async (command: TeslaCommand) => {
    await handleSubmitCommandAsync(command, revalidateTeslaInfo);
  }, [handleSubmitCommandAsync, revalidateTeslaInfo]);


  const handleSubmitPinToDriveCommandAsync = useCallback(async (command: TeslaCommand) => {
    await handleSubmitCommandAsync(command, revalidateTeslaDrivePin);
  }, [handleSubmitCommandAsync, revalidateTeslaDrivePin]);

  return {
    isSubmitting,
    isSubmittingByCommand,
    isConfirmDialogOpen,
    setIsConfirmDialogOpen,
    handleSubmitBasicCommandAsync,
    handleSubmitPinToDriveCommandAsync,
    handleGetVehicleTeslaInfoCallbackAsync,
    handleFetchTeslaControlsStatusCallbackAsync,
    handleGetVehicleTeslaDrivePinCallbackAsync,
    revalidateTeslaDrivePin
  };
};


export const useTeslaDrivePinReadonly = () => {
  return useAppSelector(state => state.tesla.drivePinSlice);
};


export const useTeslaDrivePinAsync = (isConnected: boolean) => {
  const {
    handleGetVehicleTeslaDrivePinCallbackAsync,
    revalidateTeslaDrivePin
  } = useTeslaCommandHandlersAsync();

  useEffect(() => {
    if (!isConnected) {
      return;
    }

    handleGetVehicleTeslaDrivePinCallbackAsync();
  }, [handleGetVehicleTeslaDrivePinCallbackAsync, isConnected]);


  return { ...useTeslaDrivePinReadonly(), revalidate: revalidateTeslaDrivePin };
};


export const useTeslaInfoReadonly = () => {
  const teslaSlice = useAppSelector(state => state.tesla);
  const teslaInfoSlice = teslaSlice.infoSlice;
  const teslaHelperSlice = teslaSlice.helperSlice;

  const teslaVehicleStatus: TeslaVehicleStatus | undefined = teslaInfoSlice?.isSleeping
    ? TeslaVehicleStatus.ASLEEP
    : teslaInfoSlice?.info?.state;

  return {
    ...teslaInfoSlice, ...getTeslaControlsStatusExtras(teslaInfoSlice.info),
    teslaVehicleStatus, ...teslaHelperSlice
  };
};


export const useTeslaInfoAsync = (isConnected: boolean) => {
  const { vehicleId } = useVehicleIdFromParams();
  const { handleOpenErrorAlert, handleOpenSuccessAlert } = useAlertManager();

  const { handleGetVehicleTeslaInfoCallbackAsync } = useTeslaCommandHandlersAsync();

  const teslaInfo = useTeslaInfoReadonly();
  const isSleeping = teslaInfo.isSleeping;
  const lastFetchedAt = teslaInfo.lastFetchedAt;

  const [isWakeUpSequenceRunning, setIsWakeUpSequenceRunning] = useState(false);


  /**
   * Triggers a sequence of wake-up commands to a Tesla vehicle, attempting up to 5 times if the vehicle remains asleep.
   */
  const runTeslaWakeUpSequence = useCallback(async () => {
    console.log("Initiating wake-up sequence for the vehicle...");

    setIsWakeUpSequenceRunning(true);

    for (let attempt = 0; attempt < 5; attempt++) {
      console.log(`Wake-up attempt ${attempt + 1} started.`);

      try {
        console.log("Sending wake-up command to the vehicle...");
        await updateVehicleTeslaAsync(vehicleId, { command: TeslaCommand.WAKE_UP });

        console.log("Wake-up command sent. Waiting for 3 seconds before checking status...");
        await new Promise((resolve) => setTimeout(resolve, 3000)); // Wait for 3 seconds

        const { isTeslaSleeping } = await handleGetVehicleTeslaInfoCallbackAsync();

        if (!isTeslaSleeping) {
          console.log("Vehicle is awake.");
          handleOpenSuccessAlert("Your vehicle has been successfully awakened");
          break; // Exit the loop if the vehicle is awake
        } else if (attempt === 4) {
          console.log("Maximum attempts reached. The vehicle remains asleep.");
          handleOpenErrorAlert("Unable to wake up your vehicle. Please try again. If the issue continues, contact customer support for assistance.");
        }
      } catch (error) {
        console.error("Error during wake-up process: ", error);
      } finally {
        console.log(`Wake-up attempt ${attempt + 1} completed.`);
      }

      if (attempt < 4 && isSleeping) {
        console.log("Vehicle is still sleeping. Preparing for another attempt.");
      }
    }

    setIsWakeUpSequenceRunning(false);
    console.log("Wake-up sequence completed.");
  }, [isSleeping, vehicleId, handleGetVehicleTeslaInfoCallbackAsync, handleOpenSuccessAlert, handleOpenErrorAlert]);

  /**
   *
   */
  const triggerTeslaWakeUpSequence = useCallback(async () => {
    try {
      await updateVehicleTeslaAsync(vehicleId, { command: TeslaCommand.WAKE_UP });
    } catch (error) {
      console.error(error);
    } finally {
      if (!isWakeUpSequenceRunning) {
        await runTeslaWakeUpSequence();
      } else {
        console.log("Wake up sequence is already running.");
      }
    }
  }, [isWakeUpSequenceRunning, runTeslaWakeUpSequence, vehicleId]);


  useEffect(() => {
    if (!isConnected) {
      return;
    }

    handleGetVehicleTeslaInfoCallbackAsync();
  }, [handleGetVehicleTeslaInfoCallbackAsync, isConnected]);

  return {
    ...teslaInfo,
    isWakeUpSequenceRunning,
    triggerTeslaWakeUpSequence
  };
};


export const useTeslaInvoiceListAsync = () => {
  const [invoices, setInvoices] = useState<Array<TeslaChargingWithTeslaInvoice>>([]);
  const [meta, setMeta] = useState<PaginationMeta | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);

  const { search } = useLocation();


  const getChargingListAsyncCallback = useCallback(
    async (queryString: string) => {
      try {
        setIsLoading(true);
        const res = await getTeslaChargingInvoicesAsync(queryString);
        setInvoices(res.data);
        setMeta(res.meta);

      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    },
    []
  );


  useEffect(() => {
    getChargingListAsyncCallback(search);
  }, [getChargingListAsyncCallback, search]);


  return { invoices, meta, isLoading, getChargingListAsyncCallback };
};