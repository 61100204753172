import React from "react";

import Content from "layout/content/Content";
import Head from "layout/head/Head";

import MessageButton from "components/button/MessageButton";

import Col from "components/custom/Col";
import Row from "components/custom/Row";
import DriverDetailsChargingTable from "components/driver-components/driver-details/DriverDetailsChargingTable";
import DriverDetailsPageTab from "components/driver-components/driver-details/DriverDetailsPageTab";
import LoadingSkeleton from "components/loading/LoadingSkeleton";
import { BlackBody2Text100, BlackBody2Text200, BlackH5Text100 } from "components/text/Text";
import { useDriverAsync } from "hooks/data/driver-data-accessor.hooks";
import { getLocalFormattedDateAsDateMed } from "utils/date.utils";
import { getDriverFullName, getDriverNameInitials } from "utils/driver.utils";
import DriverDetailsPageReimbursementSettings
  from "../../components/driver-components/DriverDetailsPageReimbursementSettings";
import NotFoundContent from "../../components/not-found/NotFoundContent";
import { Route, Switch, useRouteMatch } from "react-router";
import DriverDetailsAboutSection from "../../components/driver-components/driver-details/DriverDetailsAboutSection";

const DriverDetailsPage = () => {
  const driverHook = useDriverAsync();


  const { path } = useRouteMatch();


  const { isLoading, driver } = driverHook;


  if (!isLoading && !driver) {
    return <>
      <Head title={"Driver Details"}></Head>
      <NotFoundContent />
    </>;
  }

  return (
    <React.Fragment>
      <Head title={"Driver Details"} />
      <Content>
        <Row className="flex-wrap align-center justify-between mt-2 mb-2">
          {isLoading ? (
            <div className="w-100">
              <LoadingSkeleton count={2} />
            </div>
          ) : (
            <Row className="center mb-2 mb-md-0">
              <div className="driver-details-avatar-badge">{getDriverNameInitials(driver)}</div>
              <Col className="ml-2">
                <BlackH5Text100>{getDriverFullName(driver)}</BlackH5Text100>
                <Row className="align-items-center mt-1">
                  <BlackBody2Text200 className="mr-1">Added:</BlackBody2Text200>
                  <BlackBody2Text100>{getLocalFormattedDateAsDateMed(driver?.createdAt)}</BlackBody2Text100>
                </Row>
              </Col>
            </Row>
          )}

          {!isLoading && <div className={'ml-auto'}><MessageButton text="Send Message" userId={driver?.id} /> </div>}
        </Row>
        <div className="mb-4">
          <DriverDetailsPageTab />
        </div>
        <Switch>
          <Route exact path={`${path}/charging-sessions`}
                 render={() => <DriverDetailsChargingTable driver={driver} />} />
          <Route exact path={`${path}/reimbursement`}
                 render={() => <DriverDetailsPageReimbursementSettings driverHook={driverHook} />} />
          <Route path={`${path}`} render={() => <DriverDetailsAboutSection driverHook={driverHook} />} />
        </Switch>
      </Content>
    </React.Fragment>
  );
};

export default DriverDetailsPage;
