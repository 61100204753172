import AddVehicleButton from "components/button/AddVehicleButton";
import { BlackBodyText100, BlackH5Text100 } from "components/text/Text";
import { useTranslation } from "react-i18next";

import Col from "components/custom/Col";
import Row from "components/custom/Row";

export default function DashboardPageOverviewHeader() {
  const { t } = useTranslation("common");
  const title = t("dashboard.page_header");
  const description = t("dashboard.page_description");

  return (
    <Row className="justify-between align-items-center">
      <Col className="my-2">
        <BlackH5Text100 page>
          {title}
        </BlackH5Text100>
        <BlackBodyText100 className="page_description mt-1">{description}</BlackBodyText100>
      </Col>

      <Row className="align-items-center mb-3 mb-md-0">
        <AddVehicleButton />
      </Row>
    </Row>
  );
}
