import React, { useEffect } from "react";
import { useParams } from "react-router";

import Content from "../../layout/content/Content";

import { useSendbirdSdkAccessor } from "../../hooks/driver-support.hooks";
import Head from "../../layout/head/Head";
import { ErrorBoundary } from "react-error-boundary";
import { GroupChannelProvider, useGroupChannelContext } from "@sendbird/uikit-react/GroupChannel/context";
import GroupChannel from "@sendbird/uikit-react/GroupChannel";
import CircularLoadingSpinner from "../../components/loading/CircularLoadingSpinner";

function DriverSupportMessageRoomBase() {
  const context = useGroupChannelContext();
  const { messageRoomId } = useParams();

  useEffect(() => {
    if (!context?.loading) {
      context?.scrollToBottom();
      window.scrollTo(0, document.body.scrollHeight);
    }
  }, [context]);


  return (
    <GroupChannel channelUrl={messageRoomId} />
  );
}


export default function DriverSupportMessageRoom() {
  const { messageRoomId } = useParams();
  const { isSenbirdSdkAvailable, sdkInstance } = useSendbirdSdkAccessor();

  if (!messageRoomId) {
    return <>Message room id could not retrieve from urls</>;
  }

  return (<div className="flex flex-1 ">
      <Head title={"Messages"}></Head>
      <Content>
        <div className="w-100  position-relative py-2  message-room-container">
          {isSenbirdSdkAvailable ? (
            <ErrorBoundary onError={(e) => console.log("ErrorBoundary", e)}>
              <GroupChannelProvider scrollBehavior={'smooth'} channelUrl={messageRoomId}>
                <DriverSupportMessageRoomBase />
              </GroupChannelProvider>
            </ErrorBoundary>
          ) : (
            <CircularLoadingSpinner />
          )}
        </div>
      </Content>
    </div>
  );

}
