import { User } from "./user.types";
import { Driver } from "./driver.types";
import { PaymentState } from "./payment.types";
import { Control, DeepMap, FieldError, RegisterOptions } from "react-hook-form";
import { useDriverFormsReimbursementStepStates } from "../hooks/driver.hooks";
import { LatLng, LocationRectangleBounds } from "./location.types";

export type Reimbursement = {
  createdAt: string;
  updatedAt: string;
  version: number;
  id: string;
  organizationId: string;
  userId: string;
  period: string;
  periodStartedAt: string;
  periodEndedAt: string;
  paymentState: PaymentState;
  sessionCount: number;
  energyDelivered: string;
  duration: number;
  payoutRate: number;
  taxRate: number;
  grossAmount: number;
  taxAmount: number;
  netAmount: number;
  organization: User;
  user: Driver;
}

export type UpdateReimbursementPayload = {
  "paymentState": PaymentState
}

export type ReimbursementMapReadonlyProps = {
  zoom: number,
  center: LatLng | undefined,
  mapBounds: LocationRectangleBounds | undefined,
  onMapEditButtonPressed?: () => void,
  isLoaded: boolean,
  isReimbursementActive: boolean
} & Partial<DriverFormReimbursementStepStateHooks>


export type DriverFormReimbursementStepStateHooks = ReturnType<typeof useDriverFormsReimbursementStepStates>;

export type ReimbursementFormState = {
  fixedRate: string
}


export type ReimbursementFormStepsCommonProps = {
  register: (options: RegisterOptions) => React.LegacyRef<HTMLInputElement> | undefined;
  control: Control<any>;
  errors: DeepMap<any, FieldError>;
  isEditMode?: boolean;
  stepTitleOverride?: JSX.Element;
};


export type MonthlyTrend = {
  lastMonth: number;
  thisMonth: number;
  percentageChange: any;
}


export type TotalEnergyOverview = {
  total: number;
  monthlyTrend: MonthlyTrend;
}

export type TotalCostOverview = {
  total: number;
  monthlyTrend: MonthlyTrend;
}


export type ReimbursementOverview = {
  totalEnergyOverview: TotalEnergyOverview;
  totalCostOverview: TotalCostOverview;
}


export enum ReimbursementPaymentState {
  UNPAID = "UNPAID",
  PAID = "PAID",
  VOID = "VOID"
}


export type ReimbursementFilters = {
  paymentState?: {
    $in: ReimbursementPaymentState[]
  },
  q?: {
    $eq?: string
  }
}
