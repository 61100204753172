import { CircularProgress } from "@mui/material";
import { GoogleMap, Marker } from "@react-google-maps/api";
import defaultMarkerIconUrl from "assets/gifs/vehicle/vehicle.gif";
import React, { useCallback, useEffect, useState } from "react";
import { useGoogleApiLoader } from "../../../hooks/google-maps.hooks";
import { VehicleDataAccessorHook } from "../../../types/vehicle.types";
import NoDataPlaceholder from "../../table/placeholder/NoDataPlaceholder";


type Props = {
  readonly isOverview?: boolean
  readonly vehicleHook: VehicleDataAccessorHook
}

function VehicleDetailsLocationMap({ vehicleHook }: Props) {

  const { isLoaded } = useGoogleApiLoader();

  const [mapDimensions, setMapDimensions] = useState({ width: "500px", height: "350px" });

  const [map, setMap] = React.useState<google.maps.Map | null>(null);

  const location = vehicleHook.vehicle?.telemetry?.location;

  const resizeMap = useCallback(() => {
    const parentDiv = document.getElementById("map-container");

    if (!parentDiv) {
      return;
    }


    const mapHeight = parentDiv.clientHeight + "px";

    setMapDimensions({
      width: parentDiv.clientWidth + "px",
      height: mapHeight
    });

    if (map) {
      window.google.maps.event.trigger(map, "resize");
    }
  }, [map]);

  useEffect(() => {
    window.addEventListener("resize", resizeMap);
    return () => {
      window.removeEventListener("resize", resizeMap);
    };
  }, [resizeMap]);

  useEffect(() => {
    resizeMap();
  }, [resizeMap]);


  useEffect(() => {
    if (isLoaded && map && location) {

      const bounds = new google.maps.LatLngBounds();
      map.fitBounds(bounds);

      map.setZoom(14);
      map.panTo({ lat: location.data.latitude, lng: location.data.longitude });
    }
  }, [isLoaded, map, location]);


  if (vehicleHook.isLoading || !isLoaded) {
    return <div className={`w-100 h-350px center`}><CircularProgress size={75}
                                                                     style={{ color: "#1E44FF" }} /></div>;
  }


  if (!location) {
    return <div className={`w-100 h-350px center`}><NoDataPlaceholder /></div>;
  }


  return (
    <div id="map-container" className={`w-100 h-350px`}>
      <GoogleMap
        mapContainerStyle={mapDimensions}
        onLoad={setMap}
        onUnmount={() => setMap(null)}
      >
        <Marker
          position={{
            lat: location?.data.latitude,
            lng: location?.data.longitude
          }}
          icon={{
            url: defaultMarkerIconUrl,
            scale: 5,
            scaledSize: new google.maps.Size(100, 100),
            anchor: new google.maps.Point(50, 50),
          }}
        />

      </GoogleMap>

    </div>
  );
}


export default VehicleDetailsLocationMap;