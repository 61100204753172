import OutlinedButtonWithIcon from "components/button/OutlinedButtonWithIcon";
import Row from "components/custom/Row";
import DynamicListWithTooltip from "components/list/DynamicListWithTooltip";
import { BlackBody2Text200 } from "components/text/Text";
import { useHistory, useParams } from "react-router";
import { getDriverFullName } from "utils/driver.utils";
import { VehicleDriverSchedulesDataAccessorHook } from "../../types/vehicle-driver-schedule.types";
import LoadingSkeleton from "../loading/LoadingSkeleton";

type Props = {
  vehicleDriverSchedulesHook: VehicleDriverSchedulesDataAccessorHook
  textClassName?: string
};

function VehicleDriverScheduleDriverList({ vehicleDriverSchedulesHook, textClassName }: Props) {
  const { isLoading: isSchedulesLoading, schedules } = vehicleDriverSchedulesHook;

  const history = useHistory();

  const { vehicleId } = useParams<{ vehicleId: string }>();

  const list = schedules.map((s) => {
    return { name: getDriverFullName(s.user) };
  });

  if (isSchedulesLoading) {
    return <LoadingSkeleton count={1} className={"w-100px"} />;
  }

  if (list.length === 0) {
    return (
      <Row className={"align-items-center"}>
        <BlackBody2Text200 className={"mr-2"}>No driver assigned</BlackBody2Text200>
        <OutlinedButtonWithIcon
          isSmall={true}
          className={"w-max-90px"}
          onClick={() => history.push(`/assign-driver/${vehicleId}`)}
          text={"Assign"}
        />
      </Row>
    );
  }

  return <DynamicListWithTooltip list={list} textClassName={textClassName} />;
}

export default VehicleDriverScheduleDriverList;
;
