// React
// Screens && Components
import {
  AddDriverCompletedPage,
  AddDriverPage,
  AddFleetGroupPage,
  AddVehicleGeotabPage,
  AddVehicleManualPage,
  AddVehiclePage,
  AddVehicleSmartcarPage,
  AddVehicleTeslaPage,
  AssignDriverFormCompletedPage,
  AssignDriverPage,
  BulkUploadDriversPage,
  ChargingSessionDetailsPage,
  ChargingSessionsPage,
  DashboardPage,
  DepotChargingLocationsPage,
  DriverRouter,
  DriverSelfSignupApprovalPage,
  DriverSupportNavigator,
  EditFleetGroupPage,
  EditVehicle,
  Error404Modern,
  FleetGroupsPage,
  InboxPage,
  InvoiceDetailsPage,
  InvoicesPage,
  LogoutPage,
  ReimbursementsPage,
  SettingsPage,
  SmartCarExchangePage,
  TeslaExchangePage,
  TeslaInvoicesPage,
  TeslaRedirectionPage,
  VehicleDetailsPage,
  VehicleFormCompletedPage,
  VehicleLocatorPage,
  VehiclesPage
} from "pages";

//Css
import "react-loading-skeleton/dist/skeleton.css";
import AddVehicleSamsaraPage from "pages/vehicles/AddVehicleSamsaraPage";
import { Suspense, useLayoutEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";

// Utils & Hooks
import { useLocation } from "react-router";
import { Route, Switch } from "react-router-dom";
import { handleLogsByEnv } from "utils/log.utils";
import SamsaraExchangePage from "../pages/vehicles/SamsaraExchangePage";
import SamsaraRedirectionPage from "../pages/vehicles/SamsaraRedirectionPage";


const Routes = () => {
  const { pathname } = useLocation();

  handleLogsByEnv();

  useLayoutEffect(() => {
    window?.scrollTo(0, 0);
  }, [pathname]);


  return (
    <ErrorBoundary fallback={<></>}>
      <Suspense fallback={<></>}>

        <Switch>
          <Route path={`/settings`} component={SettingsPage} />

          <Route path={`/inbox`} component={InboxPage} />

          <Route exact path={`/invoices`} component={InvoicesPage} />
          <Route exact path={`/invoices/:invoiceId`} component={InvoiceDetailsPage} />

          {/** Charging Sessions routes */}
          <Route exact path={`/charging-sessions`} component={ChargingSessionsPage} />
          <Route
            exact
            path={`/charging-sessions/:chargingSessionId`}
            component={ChargingSessionDetailsPage}
          />


          {/** Reimbursement routes */}
          <Route exact path={`/reimbursements`} component={ReimbursementsPage} />


          <Route path={`/driver-support`} component={DriverSupportNavigator} />

          {/** Fleet Groups*/}
          <Route exact path={`/fleet-groups`} component={FleetGroupsPage} />
          <Route exact path={`/add-fleet-group`} component={AddFleetGroupPage} />
          <Route
            exact
            path={`/fleet-groups/:fleetGroupId`}
            component={EditFleetGroupPage}
          />

          {/** Driver routes */}
          <Route exact path={`/assign-driver/:vehicleId`} component={AssignDriverPage} />
          <Route
            exact
            path={`/assign-driver-completed/:operation`}
            component={AssignDriverFormCompletedPage}
          />

          <Route exact path={`/add-driver`} component={AddDriverPage} />
          <Route exact path={`/add-driver/completed`} component={AddDriverCompletedPage} />
          <Route exact path={`/bulk-upload-drivers`} component={BulkUploadDriversPage} />


          <Route path={`/drivers`} component={DriverRouter} />

          <Route exact path={`/approve-driver/:driverId`}
                 component={DriverSelfSignupApprovalPage} />

          {/** Vehicle routes */}
          <Route exact path={`/add-vehicle`} component={AddVehiclePage} />
          <Route exact path={`/add-vehicle/samsara`} component={AddVehicleSamsaraPage} />
          <Route exact path={`/add-vehicle/smartcar`} component={AddVehicleSmartcarPage} />
          <Route exact path={`/add-vehicle/manual`} component={AddVehicleManualPage} />
          <Route exact path={`/add-vehicle/tesla`} component={AddVehicleTeslaPage} />
          <Route exact path={`/add-vehicle/geotab`} component={AddVehicleGeotabPage} />
          <Route exact path={`/add-vehicle/completed`} component={VehicleFormCompletedPage} />
          <Route exact path={`/edit-vehicle/:vehicleId`} component={EditVehicle} />
          <Route exact path={`/vehicles`} component={VehiclesPage} />
          <Route path={`/vehicles/:vehicleId`} component={VehicleDetailsPage} />
          <Route exact path={`/vehicle-locator`} component={VehicleLocatorPage} />


          <Route exact path={`/tesla/auth`} component={TeslaRedirectionPage} />
          <Route exact path={`/tesla/invoices`} component={TeslaInvoicesPage} />
          <Route exact path={`/tesla/exchange`} component={TeslaExchangePage} />

          <Route exact path={`/smartcar/exchange`} component={SmartCarExchangePage} />

          <Route exact path={`/samsara/auth`} component={SamsaraRedirectionPage} />
          <Route exact path={`/samsara/exchange`} component={SamsaraExchangePage} />

          <Route exact path={`/`} component={DashboardPage} />

          <Route exact path={`/depot-chargers`} component={DepotChargingLocationsPage} />

          <Route path={`/logout`} component={LogoutPage} />
          <Route component={Error404Modern} />
        </Switch>

      </Suspense>
    </ErrorBoundary>
  );
};
export default Routes;
