// React
import { useCallback, useEffect, useMemo, useState } from "react";

// Third party
import { CircularProgress } from "@mui/material/node";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";

// Hooks & Utils & Services
import { useIntercomTrigger } from "hooks/intercom.hooks";

// Types
// Components
import CancelButton from "components/button/CancelButton";
import TryAgainButton from "components/button/TryAgainButton";
import UnlinkIcon from "components/icon/icons/UnlinkIcon";
import AlertConfirmDialog from "components/notification/AlertConfirmDialog";
import { BlackBodyText0 } from "components/text/Text";
import Content from "layout/content/Content";
import Head from "layout/head/Head";
import { authorizeSamsaraAuthAsync } from "services/vehicle.services";
import { useAddVehicleCompletedHandlers } from "../../hooks/vehicle.hooks";

type Props = {};

function SamsaraExchangePage(props: Props) {
  // Hooks

  const { t } = useTranslation("common");

  const { triggerIntercom } = useIntercomTrigger();
  const { handleForwardCompletedPage } = useAddVehicleCompletedHandlers();


  const [isLoading, setIsLoading] = useState(true);
  const [isErrorModalVisible, setIsErrorModalVisible] = useState(false);

  const location = useLocation();
  const history = useHistory();

  const { code, state, scope } = useMemo(() => {
    const params = new URLSearchParams(location.search);
    const code = params.get("code");
    const state = params.get("state");
    const scope = params.get("scope");

    return { code, state, scope };
  }, [location.search]);

  // Handlers

  /**
   *
   */
  const handleCancelButtonPressed = useCallback(() => {
    history.push("/vehicles");
  }, [history]);

  /**
   *
   */
  const handleExchangeSamsaraAuth = useCallback(async () => {
    if (!code || !state || !scope) {
      return;
    }

    setIsErrorModalVisible(false);
    setIsLoading(true);
    try {
      const response = await authorizeSamsaraAuthAsync({ state: JSON.parse(decodeURIComponent(state)), code, scope });
      handleForwardCompletedPage(response, "samsara");
    } catch (e) {
      setIsErrorModalVisible(true);
    } finally {
      setIsLoading(false);
    }
  }, [code, handleForwardCompletedPage, scope, state]);

  // Effects
  /**
   *
   */
  useEffect(() => {
    handleExchangeSamsaraAuth();
  }, [code, scope, handleExchangeSamsaraAuth, history, state]);

  return (
    <>
      <Head title={t("vehicle.title.add_vehicles_page")}></Head>
      <Content>
        <AlertConfirmDialog
          overrideButtons={
            <>
              <CancelButton onClick={handleCancelButtonPressed} />
              <TryAgainButton onTryAgainButtonPressed={() => history.replace("/add-vehicle")} />
            </>
          }
          isDialogOpen={isErrorModalVisible}
          toggleIsDialogOpen={handleCancelButtonPressed}
          icon={<UnlinkIcon />}
          title={"Unable to Add Vehicle"}
          descriptionOverride={
            <>
              <BlackBodyText0>
                We're currently experiencing technical difficulties connecting your vehicle.
              </BlackBodyText0>
              <BlackBodyText0>Please attempt to connect again.</BlackBodyText0>
              <BlackBodyText0>
                If the problem persists, please contact{" "}
                <span onClick={triggerIntercom} className="link-text mb-0 pointer-events-all">
                  Customer Support
                </span>
                .
              </BlackBodyText0>
            </>
          }
        />
        <div className="d-flex flex-1 center h-300px">
          {isLoading && <CircularProgress size={75} style={{ color: "#1E44FF" }} />}
        </div>
      </Content>
    </>
  );
}

export default SamsaraExchangePage;
