import { ArcElement, BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Tooltip } from "chart.js";
import { Bar, Doughnut } from "react-chartjs-2";


import ChartDataLabels from "chartjs-plugin-datalabels";

import { UncontrolledTooltip } from "reactstrap";
import { formatNumber } from "utils/charging.utils";


ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement);

export const TotalCostChart = ({ data, tooltipTitlePrefix }) => {
  const yScaleText = {
    id: "yScaleText",
    afterDraw(chart, args, options) {
      try {
        const {
          ctx,
          chartArea: { top }
        } = chart;
        ctx.save();
        ctx.fillStyle = "#A0A8AF";
        ctx.fillText("Amount", 0, top - 20);
        ctx.restore();
      } catch (error) {
        console.log(error);
      }
    }
  };

  //https://github.com/chartjs/Chart.js/issues/8345
  return (
    <Bar
      className="flex flex-1 h-max-100 "
      data={data}
      plugins={[yScaleText]}
      options={{
        maintainAspectRatio: false,
        responsive: true,
        scales: {
          x: {
            beginAtZero: true,
            stacked: true,
            grid: {
              display: true,
              drawTicks: false,

              //  lineWidth: 0.5,
              drawOnChartArea: false,
              borderDash: [5, 10]
            },
            ticks: {
              color: "#A0A8AF",
              autoSkip: true,
              padding: 10,

              font: {
                size: (ctx) => (ctx.chart.width < 480 ? 6 : 10)
              }
            }
          },
          y: {
            title: "$",
            beginAtZero: true,
            grid: {
              drawTicks: false
            },
            border: {
              dash: [5, 10],
              width: 0
            },
            stacked: true,
            ticks: {
              font: {
                size: (ctx) => (ctx.chart.width < 480 ? 8 : 12)
              },
              color: "#A0A8AF",
              align: "center",
              crossAlign: "far",
              textAlign: "left", // Etiketlerin sola yaslanmasını sağlar
              callback: function(value, index, values) {
                return "$" + formatNumber(Number(value));
              }
            }
          }
        },

        layout: {
          padding: {
            bottom: 0
          }
        },
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            intersect: false,

            callbacks: {
              title: function(context) {
                const data = context[0];
                const label = data.label;

                return tooltipTitlePrefix ? `${tooltipTitlePrefix} ${label}` : label;
              },

              label: function(context) {
                const { dataset, dataIndex } = context;

                const data = dataset?.data[dataIndex];
                const formattedValue = formatNumber(Number(data));

                return `$${formattedValue}`;
              }
            }
          }
        }
      }}
    />
  );
};
export const TotalEnergyChart = ({ data, tooltipTitlePrefix }) => {
  return (
    <Bar
      data={data}
      options={{
        maintainAspectRatio: false,
        responsive: true,

        scales: {
          x: {
            beginAtZero: true,

            stacked: true,
            grid: {
              display: true,
              drawTicks: false,

              //  lineWidth: 0.5,
              drawOnChartArea: false,
              borderDash: [5, 10]
            },
            ticks: {
              color: "#A0A8AF",
              autoSkip: true,

              padding: 10,

              font: {
                size: (ctx) => (ctx.chart.width < 480 ? 6 : 10) // Add this line
              }
            }
          },
          y: {
            beginAtZero: true,
            grid: {
              drawTicks: false
            },
            border: {
              dash: [5, 10],
              width: 0
            },
            stacked: true,
            ticks: {
              size: (ctx) => (ctx.chart.width < 480 ? 8 : 12),
              color: "#A0A8AF",
              align: "center",
              crossAlign: "far",
              textAlign: "left", // Etiketlerin sola yaslanmasını sağlar
              callback: function(value, index, values) {
                return formatNumber(Number(value));
              }
            }
          }
        },

        layout: {
          padding: {
            bottom: 0
          }
        },
        plugins: {
          legend: {
            title: "",
            labels: "",
            display: true
          },
          tooltip: {
            intersect: false,

            callbacks: {
              title: function(context) {
                const data = context[0];
                const label = data.label;

                return tooltipTitlePrefix ? `${tooltipTitlePrefix} ${label}` : label;
              },
              label: function(context) {
                const { dataset, dataIndex } = context;

                const data = dataset?.data[dataIndex];
                const formattedValue = formatNumber(Number(data));

                return `${formattedValue} kWh`;
              }
            }
          }
        }
      }}
    />
  );
};


export const DoughnutChart = ({ data, isLegendsNotVisible }) => {
  const total = data.datasets[0]?.data?.reduce((a, b) => a + b, 0);
  return (
    <Doughnut
      height={300}
      width={300}
      className="justify-center align-items-start  "
      plugins={[ChartDataLabels]}
      options={{
        responsive: false,
        maintainAspectRatio: true,

        layout: {
          padding: {
            top: 0,
            left: 60,
            right: 60,
            bottom: 60
          }
        },
        plugins: {
          tooltip: {
            boxPadding: 5,
            rtl: false,
            yAlign: "bottom"
          },
          datalabels: {
            display: function(context) {
              return context.dataset.data[context.dataIndex] !== 0; // or >= 1 or ...
            },
            font: {
              size: 10
            },

            color: "#FFFFFF",
            anchor: "end",
            align: "end",
            offset: 10,
            backgroundColor: function(context) {
              return context.dataset.backgroundColor;
            },
            borderRadius: 4,
            formatter: function(value, context) {
              return ((value / total) * 100)?.toFixed(2) + "%";
            }
          },
          legend: {
            display: !isLegendsNotVisible,
            position: "top",
            align: "center",
            fullSize: true,

            labels: {
              usePointStyle: true,
              font: {
                size: (ctx) => (ctx.chart.width < 480 ? 10 : 20) // Add this line
              }
            }
          }
        }
      }}
      data={data}
    />
  );
};

export function ChargerProviderDoughnutChart({ data, totalChargers }) {
  return (
    <div className="d-flex w-100 h-100 justify-center align-items-start position-relative ">
      <DoughnutChart data={data} isLegendsNotVisible />
      <div id="totalSessionsTooltip" className="doughnut-chart-center-text-without-legends">
        {totalChargers}
      </div>
      <UncontrolledTooltip placement="top" target="totalSessionsTooltip">
        Total Sessions
      </UncontrolledTooltip>
    </div>
  );
}

export function TotalSessionsDoughnutChart({ data, totalSessions }) {
  return (
    <div className="d-flex w-100 h-100 justify-center align-items-start position-relative ">
      <DoughnutChart data={data} />
      <div id="totalSessionsTooltip" className="doughnut-chart-center-text ">
        {totalSessions}
      </div>
      <UncontrolledTooltip placement="top" target="totalSessionsTooltip">
        Total Sessions
      </UncontrolledTooltip>
    </div>
  );
}

const labels = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "May",
  "June",
  "July",
  "May",
  "June",
  "July"
];

export function HorizontalBarChart({ data }) {
  return (
    <Bar
      options={{

        layout: {
          padding: {
            top: 20,
            bottom: 20,
            left: 0
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        indexAxis: "y",
        elements: {
          bar: {
            borderWidth: 0
          }
        },
        scales: {
          y: {
            beginAtZero: false,

            stacked: true,
            border: {
              display: false
            },
            grid: {
              display: false,
              drawTicks: false

              //  lineWidth: 0.5,
            },

            ticks: {
              color: "#A0A8AF",
              autoSkip: false,
              maxRotation: 0,
              minRotation: 0,

              padding: 10,
              font: {
                size: (ctx) => (ctx.chart.width < 480 ? 8 : 12) // Add this line
              },
              callback: function(value, index, values) {
                const labels = data.labels;
                const label = labels[index];
                //burdaki value number donuyor oysaki beinm labellarim string
                if (label.length > 20) return label.substr(0, 20) + "..."; // Eklediğimiz satır
                return labels[index];
              }
            }
          },
          x: {
            grid: {
              drawTicks: false,
              lineWidth: 1.5
            },
            border: {
              dash: [3, 5],
              width: 0
            },
            stacked: true,
            ticks: {
              color: "#A0A8AF",
              align: "left",
              crossAlign: "far",
              textAlign: "left", // Etiketlerin sola yaslanmasını sağlar
              padding: 10
            }
          }
        },

        plugins: {
          legend: {
            display: false
          }
        }
      }}
      data={data}
      height={data.labels.length * 30 + 50}
    />
  );
}