import NotificationBadge from "components/badge/NotificationBadge";
import InboxMenuIconFilled from "components/icon/icons/InboxMenuIconFilled";
import InboxMenuIconOutlined from "components/icon/icons/InboxMenuIconOutlined";
import InvoiceMenuIconFilled from "components/icon/icons/InvoiceMenuIconFilled";
import InvoiceMenuIconOutlined from "components/icon/icons/InvoiceMenuIconOutlined";
import { useEffect, useMemo } from "react";
import { useLocation } from "react-router";
import DriverSupportMenuItemNotificationBadge
  from "../components/driver-support-components/DriverSupportMenuItemNotificationBadge";
import CarMenuIconFilled from "../components/icon/icons/CarMenuIconFilled";
import CarMenuIconOutlined from "../components/icon/icons/CarMenuIconOutlined";
import ChargingSessionMenuIconFilled from "../components/icon/icons/ChargingSessionMenuIconFilled";
import ChargingSessionMenuIconOutlined from "../components/icon/icons/ChargingSessionMenuIconOutlined";
import DashboardMenuIconFilled from "../components/icon/icons/DashboardMenuIconFilled";
import DashboardMenuIconOutlined from "../components/icon/icons/DashboardMenuIconOutlined";
import DriversMenuIconFilled from "../components/icon/icons/DriversMenuIconFilled";
import DriversMenuIconOutlined from "../components/icon/icons/DriversMenuIconOutlined";
import FleetGroupsMenuIconFilled from "../components/icon/icons/FleetGroupsMenuIconFilled";
import FleetGroupsMenuIconOutlined from "../components/icon/icons/FleetGroupsMenuIconOutlined";
import MessageMenuIconFilled from "../components/icon/icons/MessageMenuIconFilled";
import MessageMenuIconOutlined from "../components/icon/icons/MessageMenuIconOutlined";
import SettingsMenuIconFilled from "../components/icon/icons/SettingsMenuIconFilled";
import SettingsMenuIconOutlined from "../components/icon/icons/SettingsMenuIconOutlined";
import { useGetOrganizationInfoCallbackAsync } from "./auth.hooks";
import { useAppSelector } from "./redux.hooks";
import ReimbursementMenuIconOutlined from "../components/icon/icons/ReimbursementMenuIconOutlined";
import ReimbursementMenuIconFilled from "../components/icon/icons/ReimbursementMenuIconFilled";
import NewBadge from "../components/badge/NewBadge";
import DepotLocationsMenuIconFilled from "../components/icon/icons/DepotLocationsMenuIconFilled";
import DepotLocationsMenuIconOutlined from "../components/icon/icons/DepotLocationsMenuIconOutlined";

export function useAppMenu(sidebarData: {
  unreadMessageCount: number | undefined,
  pendingDriversCount: number | undefined
}) {
  const menu = useMemo(() => {
    return [
      {
        isAvailable: true,
        isIconJsx: true,
        IconFilled: (props) => <DashboardMenuIconFilled {...props} />,
        IconOutlined: (props) => <DashboardMenuIconOutlined {...props} />,
        icon: "grid",
        text: "homepage",
        permission: [],
        active: false,
        link: "/"
      },
      {
        isAvailable: true,
        isIconJsx: true,
        IconFilled: (props) => <InboxMenuIconFilled {...props} />,
        IconOutlined: (props) => <InboxMenuIconOutlined {...props} />,
        icon: "grid",
        text: "inbox",
        permission: [],
        active: false,
        link: "/inbox",
        NotificationBadge: (props) => <NotificationBadge {...props} count={sidebarData.unreadMessageCount} />
      },
      {
        isAvailable: true,
        isIconJsx: true,
        IconFilled: (props) => <ChargingSessionMenuIconFilled {...props} />,
        IconOutlined: (props) => <ChargingSessionMenuIconOutlined {...props} />,
        icon: "spark",
        text: "chargingSessions",
        active: false,
        link: "/charging-sessions"
      },
      {
        isAvailable: true,
        isIconJsx: true,
        IconFilled: (props) => <CarMenuIconFilled {...props} />,
        IconOutlined: (props) => <CarMenuIconOutlined {...props} />,
        icon: "vehicle",
        text: "vehicles",
        active: false,
        link: "/vehicles"
      },
      {
        isAvailable: true,
        isIconJsx: true,
        IconFilled: (props) => <DriversMenuIconFilled {...props} />,
        IconOutlined: (props) => <DriversMenuIconOutlined {...props} />,
        icon: "navigate-up",
        text: "drivers",
        active: false,
        link: "/drivers",
        NotificationBadge: (props) => <NotificationBadge {...props} count={sidebarData.pendingDriversCount} />
      },
      {
        isAvailable: true,
        isIconJsx: true,
        IconFilled: (props) => <DepotLocationsMenuIconFilled {...props} />,
        IconOutlined: (props) => <DepotLocationsMenuIconOutlined {...props} />,
        icon: "navigate-up",
        text: "depot_chargers",
        active: false,
        link: "/depot-chargers",
        NotificationBadge: () => <NewBadge />
      },
      {
        isAvailable: true,
        isIconJsx: true,
        IconFilled: (props) => <ReimbursementMenuIconFilled {...props} />,
        IconOutlined: (props) => <ReimbursementMenuIconOutlined {...props} />,
        icon: "spark",
        text: "reimbursement",
        active: false,
        link: "/reimbursements",
      },
      {
        isAvailable: true,
        isIconJsx: true,
        IconFilled: (props) => <FleetGroupsMenuIconFilled {...props} />,
        IconOutlined: (props) => <FleetGroupsMenuIconOutlined {...props} />,
        icon: "fleet-groups",
        text: "fleetGroups",
        active: false,
        link: "/fleet-groups"
      },
      {
        isAvailable: true,
        isIconJsx: true,
        IconFilled: (props) => <MessageMenuIconFilled {...props} />,
        IconOutlined: (props) => <MessageMenuIconOutlined {...props} />,
        icon: "account-setting",
        text: "messages",
        active: false,
        link: "/driver-support",
        NotificationBadge: (props) => <DriverSupportMenuItemNotificationBadge {...props} />
      },
      {
        isAvailable: true,
        isIconJsx: true,
        IconFilled: (props) => <InvoiceMenuIconFilled {...props} />,
        IconOutlined: (props) => <InvoiceMenuIconOutlined {...props} />,
        icon: "invoice",
        text: "invoice",
        active: false,
        link: "/invoices"
      },

      {
        isAvailable: true,
        isIconJsx: true,
        IconFilled: (props) => <SettingsMenuIconFilled {...props} />,
        IconOutlined: (props) => <SettingsMenuIconOutlined {...props} />,
        icon: "setting",
        text: "setting",
        active: false,
        link: "/settings"
      }
    ];
  }, [sidebarData.pendingDriversCount, sidebarData.unreadMessageCount]);

  return { menu };
}

export const useSyncSidebarOrganizationDataWithInterval = () => {
  const THIRTY_MINUTES = 60 * 1000 * 3000;

  const { getOrganizationInfoAsyncCallback } = useGetOrganizationInfoCallbackAsync();
  const user = useAppSelector((state) => state.user.currentUser);
  const { pathname } = useLocation();

  /**
   * Sync Sidebar organization data on below conditions:
   * First render of the app.
   * Every 30 min.
   * In Inbox & Invoices pages.
   */

  const isInvoicesPage = pathname === "/invoices";
  const isInboxMessagesPage = pathname === "/inbox";

  useEffect(() => {
    if (isInvoicesPage || isInboxMessagesPage) {
      getOrganizationInfoAsyncCallback();
    }
  }, [getOrganizationInfoAsyncCallback, isInboxMessagesPage, isInvoicesPage]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      getOrganizationInfoAsyncCallback();
    }, THIRTY_MINUTES);

    return () => clearInterval(intervalId);
  }, [THIRTY_MINUTES, getOrganizationInfoAsyncCallback]);

  return {
    unpaidInvoiceCount: user?.unpaidInvoiceCount,
    unreadMessageCount: user?.unreadMessageCount,
    pendingDriversCount: user?.pendingSignUpApplicationCount
  };
};
