import Badge from "components/badge/Badge";
import ConnectionStatusBadge from "components/badge/ConnectionStatusBadge";
import { ConnectionStatus } from "types/charging-sessions.types";
import { VehicleConnectionState } from "types/vehicle.types";

type Props = {
  readonly connectionStatus: ConnectionStatus | VehicleConnectionState;
};

function VehicleConnectionStatusBadge({ connectionStatus }: Props) {
  if (connectionStatus === VehicleConnectionState.CONNECTED) {
    const detailPageSpecificConnectedBadgeProps = {
      text: "Connected",
      textStyle: `text-positive-200`,
      containerStyle: `bg-positive-900 px-2 rounded-xxxl center`
    };

    return <Badge {...detailPageSpecificConnectedBadgeProps} />;
  }

  return <ConnectionStatusBadge connectionStatus={connectionStatus} />;
}

export default VehicleConnectionStatusBadge;
