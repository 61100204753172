import { GoogleMap, InfoWindow, MarkerF } from "@react-google-maps/api";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router";
import { useVehicleLocationsAsync } from "../../../hooks/data/vehicle-data-accessor.hooks";
import { useGoogleApiLoader } from "../../../hooks/google-maps.hooks";
import Row from "../../custom/Row";
import {
  BlackBody2Text100,
  BlackBodyText100,
  BlackBodyText200,
  BlackBodyText300,
  BlackH5Text100
} from "../../text/Text";

import { CircularProgress } from "@mui/material";
import { RSelect } from "components/Component";
import InfoIcon from "components/icon/icons/InfoIcon";
import SearchIcon from "components/icon/icons/SearchIcon";
import VehiclePlaceholderIconSmall from "components/icon/icons/VehiclePlaceholderIconSmall";
import { mapSmartcarVehiclesForSelector } from "utils/vehicle.utils";
import defaultMarkerIconUrl from "../../../assets/gifs/vehicle/vehicle.gif";
import selectedMarkerIconUrl from "../../../components/icon/icons/VehicleMarkerIcon.svg";
import { VehicleLocation } from "../../../types/vehicle.types";
import VehicleLocatorForwardingLinkButton from "../../button/VehicleLocatorForwardingLinkButton";
import LiveVehicleLocatorInfoWindowContent from "./LiveVehicleLocatorInfoWindowContent";
import LiveVehicleMapPlaceholder from "./LiveVehicleMapPlaceholder";

type Props = {
  readonly isPage?: boolean;
};

function LiveVehicleLocator({ isPage }: Props) {
  const { vehicleLocations, isLoading } = useVehicleLocationsAsync();
  const vehicleOptions = mapSmartcarVehiclesForSelector(vehicleLocations ?? []);

  const filterOption = useCallback((option, inputValue: string) => {
    const { value } = option;
    const lowerCaseName = value?.name?.toLocaleLowerCase();
    const lowerCaseVin = value?.vin?.toLocaleLowerCase();
    const query = inputValue.toLocaleLowerCase();

    return lowerCaseName.includes(query) || lowerCaseVin.includes(query);
  }, []);

  const [selectedVehicle, setSelectedVehicle] = useState<{ value: VehicleLocation; label: string } | undefined>();

  const { isLoaded } = useGoogleApiLoader();

  const [selectedMarker, setSelectedMarker] = useState<VehicleLocation | undefined>();

  const [mapDimensions, setMapDimensions] = useState({ width: "500px", height: "260px" });

  const [map, setMap] = React.useState<google.maps.Map | null>(null);
  const location = useLocation();

  const resizeMap = useCallback(() => {
    const parentDiv = document.getElementById("map-container");

    if (!parentDiv) {
      return;
    }

    const mapHeight = isPage ? parentDiv.clientHeight + "px" : "250px";

    setMapDimensions({
      width: parentDiv.clientWidth + "px",
      height: mapHeight
    });

    if (map) {
      window.google.maps.event.trigger(map, "resize");
    }
  }, [isPage, map]);

  useEffect(() => {
    window.addEventListener("resize", resizeMap);
    return () => {
      window.removeEventListener("resize", resizeMap);
    };
  }, [resizeMap]);

  useEffect(() => {
    resizeMap();
  }, [location.pathname, resizeMap]);

  useEffect(() => {
    if (isLoaded && map && !selectedVehicle) {
      setSelectedMarker(undefined);
      const bounds = new google.maps.LatLngBounds();
      vehicleLocations?.forEach((marker) => {
        bounds.extend(new google.maps.LatLng(marker.location?.data.latitude, marker.location?.data.longitude));
      });
      map.fitBounds(bounds);
    }
  }, [isLoaded, map, vehicleLocations, selectedVehicle]);

  useEffect(() => {
    if (selectedVehicle && map) {
      const selectedLocation = vehicleLocations?.find((vsl) => vsl.id === selectedVehicle.value.id);
      if (selectedLocation) {
        setSelectedMarker(selectedLocation);
        map.setZoom(17);
        map.panTo({ lat: selectedLocation.location?.data.latitude, lng: selectedLocation.location?.data.longitude });
      }
    }
  }, [map, selectedVehicle, vehicleLocations]);

  const handleSelectMarkerPressed = useCallback(
    (sm) => {
      if (isPage) {
        setSelectedMarker(sm);
        return;
      }

      window.open(
        "/vehicle-locator",
        "_blank" // <- This is what makes it open in a new window.
      );
    },
    [isPage]
  );

  const CustomOption = useCallback((props) => {
    const { data, label, setValue } = props;
    console.log("data");
    return (
      <Row
        onClick={() => setValue({ value: data.value, label })}
        key={data.value.id}
        className="vehicle-locator-option flex-nowrap"
      >
        <div className="d-flex w-min-20px">
          <VehiclePlaceholderIconSmall />
        </div>

        <BlackBody2Text100 className="ml-2">{label}</BlackBody2Text100>
      </Row>
    );
  }, []);

  if (!vehicleLocations || !isLoaded) {
    if (isPage) {
      return (
        <div className="h-80vh center ">
          <CircularProgress size={75} style={{ color: "#1E44FF" }} />
        </div>
      );
    }

    return <CircularProgress size={75} style={{ color: "#1E44FF" }} />;
  }

  const containerClass = isPage ? "h-80vh h-max-80vh" : "h-100 w-100";
  return (
    <div className={containerClass}>
      <div className={`${isPage ? "px-4 pb-2 mx-2 mt-2" : ""}`}>
        {isPage ? (
          <BlackH5Text100 className="mb-1">Live Vehicle Locator</BlackH5Text100>
        ) : (
          <VehicleLocatorForwardingLinkButton />
        )}

        {isPage ? (
          <>
            <BlackBodyText100>View the locations of your connected vehicles on the map below.</BlackBodyText100>
            <Row className="align-items-center mb-2">
              <InfoIcon fill="#6F7981" width={16} height={16} />
              <BlackBodyText200 className="ml-1">
                Vehicle locations on this map are updated every 30 minutes for accurate tracking.
              </BlackBodyText200>
            </Row>
          </>
        ) : (
          <BlackBodyText300 className="mb-3">
            View the locations of your connected vehicles on the map below
          </BlackBodyText300>
        )}

        {isPage && (
          <div className="w-550px w-max-100 mb-3 filter-selector vehicle-locator-filter">
            <SearchIcon className="filter-selector-search-icon" />
            <RSelect
              placeholder={isLoading ? "Loading..." : "Search vehicle"}
              value={selectedVehicle}
              onChange={setSelectedVehicle}
              options={vehicleOptions}
              filterOption={filterOption}
              isClearable
              components={{ Option: CustomOption }}
            />
          </div>
        )}
      </div>
      <div id="map-container" className={`w-100 ${isPage ? "h-100" : ""}`}>
        {vehicleLocations.length > 0 ? (
          <GoogleMap
            onClick={() => setSelectedMarker(undefined)}
            mapContainerStyle={mapDimensions}
            onLoad={setMap}
            onUnmount={() => setMap(null)}
          >
            <>
              {vehicleLocations?.map((marker) => (
                <MarkerComponent
                  marker={marker}
                  key={marker.id}
                  id={marker.id}
                  selectedMarker={selectedMarker}
                  onSelectMarker={handleSelectMarkerPressed}
                />
              ))}
              {selectedMarker && (
                <InfoWindow
                  onCloseClick={() => {
                    setSelectedMarker(undefined);
                  }}
                  position={{
                    lat: selectedMarker.location.data.latitude,
                    lng: selectedMarker.location.data.longitude
                  }}
                >
                  <LiveVehicleLocatorInfoWindowContent selectedMarker={selectedMarker} />
                </InfoWindow>
              )}
            </>
          </GoogleMap>
        ) : (
          <div className={`d-flex flex-1 justify-center align-items-center ${isPage ? "h-100" : "mt-4 pt-4"}`}>
            <LiveVehicleMapPlaceholder />
          </div>
        )}
      </div>
    </div>
  );
}

const MarkerComponent = ({
                           marker,
                           id,
                           selectedMarker,
                           onSelectMarker
                         }: {
  marker: VehicleLocation;
  id: string;
  selectedMarker: VehicleLocation | undefined;
  onSelectMarker: (sm: any) => void;
}) => {
  const iconUrls = useMemo(
    () => ({
      default: defaultMarkerIconUrl,
      selected: selectedMarkerIconUrl
    }),
    []
  );

  const getMarkerUrl = (id) => {
    return selectedMarker?.id === id ? iconUrls.selected : iconUrls.default;
  };

  return (
    <MarkerF
      // Add a dynamic key to the MarkerF component to force a re-render when the selected marker changes
      key={`${id}-${selectedMarker?.id === id}`}
      onClick={() => onSelectMarker(marker)}
      position={{
        lat: marker.location?.data.latitude,
        lng: marker.location?.data.longitude
      }}
      icon={{
        url: getMarkerUrl(id),
        scale: 5,
        scaledSize: new google.maps.Size(50, 50),
        anchor: new google.maps.Point(25, 25),
      }}
    />
  );
};

export default React.memo(LiveVehicleLocator);