import PrimaryButton from "components/button/PrimaryButton";
import Row from "components/custom/Row";
import SettingsLogoutIcon from "components/icon/icons/SettingsLogoutIcon";
import SettingsPasswordIcon from "components/icon/icons/SettingsPasswordIcon";
import SettingsSecurityIcon from "components/icon/icons/SettingsSecurityIcon";
import { NeutralBodyMediumText100 } from "components/text/Text";
import { useAuthServicesAsync } from "hooks/auth.hooks";
import { openLinkInSideTab } from "utils/link.utils";
import SettingsPageAccordionBox from "./SettingsPageAccordionBox";
import SettingsPageChangePasswordEditor from "./form/SettingsPageChangePasswordEditor";
import SettingsLegalIconFilled from "../icon/icons/SettingsLegalIconFilled";
import HoverUnderlinedTextButton from "../button/HoverUnderlinedTextButton";

type Props = {};

function SettingsPageSecurityContent({}: Props) {
  const { logoutAsync, isLoading } = useAuthServicesAsync();
  return (
    <div className="w-100">
      <SettingsPageAccordionBox
        title="Change password"
        body={
          <Row className="align-items-center justify-between">
            <NeutralBodyMediumText100>Update your password and secure your account.</NeutralBodyMediumText100>
            <SettingsPageChangePasswordEditor />
          </Row>
        }
        icon={<SettingsPasswordIcon />}
      />
      <SettingsPageAccordionBox
        className="mt-4"
        title="Security"
        body={
          <>
            <NeutralBodyMediumText100>
              Robust Data Encryption:
              <span className="text-black-200 ml-1">
                Ensuring your vehicle and charging data is protected with industry-leading encryption methods.
              </span>
            </NeutralBodyMediumText100>
            <NeutralBodyMediumText100 className="mt-3">
              Strict Privacy Protocols:
              <span className="text-black-200 ml-1">
                Your driver information is safeguarded under stringent privacy standards, maintaining confidentiality at
                all times.
              </span>
            </NeutralBodyMediumText100>

            <NeutralBodyMediumText100 className="mt-3">
              Continuous Security Monitoring:
              <span className="text-black-200 ml-1">
                Our systems are constantly monitored to detect and prevent unauthorized access, keeping your fleet data
                secure 24/7.
              </span>
            </NeutralBodyMediumText100>
          </>
        }
        icon={<SettingsSecurityIcon />}
      />

      <SettingsPageAccordionBox
        className="mt-4"
        title="Legal"
        body={
          <>

            <HoverUnderlinedTextButton text={"Terms of Service"} onClick={() => openLinkInSideTab("/terms")} />

            <Row className="mt-3">
            <HoverUnderlinedTextButton
                                       text={"Privacy Policy"}
                                       onClick={() => openLinkInSideTab("/privacy")} />
            </Row>
          </>
        }
        icon={<SettingsLegalIconFilled />}
      />

      <SettingsPageAccordionBox
        className="mt-4"
        title="Log out"
        body={
          <Row className="align-items-center justify-between flex-nowrap ">
            <NeutralBodyMediumText100>
              Logging out will end your current session. To access your account again, you will need to re-login.
            </NeutralBodyMediumText100>
            <PrimaryButton text="Log out" onClick={logoutAsync} isLoading={isLoading} isDisabled={isLoading} />
          </Row>
        }
        icon={<SettingsLogoutIcon />}
      />
    </div>
  );
}

export default SettingsPageSecurityContent;
