import React from "react";
import { Link } from "react-router-dom";


export enum TextButtonMode {
  PRIMARY,
  BLUE,
  NEGATIVE
}

export type TextButtonProps = {
  readonly icon?: React.JSX.Element;
  readonly text?: string;
  readonly textOverride?: React.JSX.Element;
  readonly onClick?: () => void;
  readonly className?: string;
  readonly textClassName?: string;
  readonly mode?: TextButtonMode;
  readonly id?: string
  readonly href?: string
};

export default function TextButton({
                                     icon,
                                     id,
                                     text,
                                     onClick,
                                     className,
                                     textClassName,
                                     textOverride,
                                     href
                                   }: TextButtonProps) {
  const classNameValue = `d-inline-flex pointer-events-auto  align-center  rounded-xxl cursor-pointer  ${className}`;

  const child = <> {icon && <div className="mr-1">{icon}</div>}
    {textOverride ?? <span className={`font-weight-normal text-truncate ${textClassName ?? ""}`}>{text}</span>}
  </>;


  if (href) {
    return <Link to={href} className={classNameValue}>
      {child}
    </Link>;
  }


  return (
    <span
      id={id}
      style={{ pointerEvents: "auto" }}
      className={`d-inline-flex justify-center  align-center justify-center rounded-xxl cursor-pointer ${className}`}
      onClick={onClick}
    >
      {child}
    </span>
  );
}
