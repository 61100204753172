import { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import { getInvoiceAsync, getInvoicesAsync } from "services/invoice.services";
import { PaginationMeta } from "types/api.types";
import { Invoice } from "types/invoice.types";
import { getQueryWithDefaultPagination } from "utils/query.utils";

export const useInvoicesAsync = (page: number = 0, size: number = 20) => {
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [meta, setMeta] = useState<PaginationMeta | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const { search } = useLocation();
  const handleGetInvoicesAsyncCallback = useCallback(
    async () => {
      try {
        setIsLoading(true);

        const queryString = getQueryWithDefaultPagination(search);
        const response = await getInvoicesAsync(queryString);

        setMeta(response.meta);
        setInvoices(response.data ?? []);

      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    },
    [search]
  );

  useEffect(() => {
    handleGetInvoicesAsyncCallback();
  }, [handleGetInvoicesAsyncCallback]);
  return { isLoading, invoices, meta };
};


export const useInvoiceAsync = () => {
  const { invoiceId } = useParams<{ invoiceId: string }>();
  const [invoice, setInvoice] = useState<Invoice | undefined>();

  const [isLoading, setIsLoading] = useState(false);

  const handleGetInvoiceAsyncCallback = useCallback(
    async () => {
      try {
        setIsLoading(true);
        setInvoice((await getInvoiceAsync(invoiceId)).data);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    },
    [invoiceId]
  );


  useEffect(() => {
    handleGetInvoiceAsyncCallback();
  }, [handleGetInvoiceAsyncCallback]);


  return { isLoading, invoice };
};