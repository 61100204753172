import { getDriverFullName } from "./driver.utils";
import { getFixedRate, getFormattedNumberForUsdByCent } from "./format.utils";
import { getFormattedNumberForEnergy } from "./charging.utils";
import { getLocalFormattedDateAsDateShort } from "./date.utils";
import { Reimbursement, ReimbursementPaymentState } from "../types/reimbursement.types";
import { AppColors } from "./colors.utils";
import { DateTime } from "luxon";

export const getChargingReimbursementRowForCsv = (reimbursement: Reimbursement) => {
  // We will add vehicle details as separated columns.

  return {
    driver: getDriverFullName(reimbursement.user),
    status: reimbursement.paymentState,
    reimbursement: getFormattedNumberForUsdByCent(reimbursement?.netAmount),
    month: reimbursement?.period,
    fixedRate: getFixedRate(reimbursement?.payoutRate)?.label,
    totalKWh: `${getFormattedNumberForEnergy(reimbursement?.energyDelivered)} kWh`,
    date: getLocalFormattedDateAsDateShort(reimbursement?.createdAt)
  };
};

export const getSessionStatisticDataForGraphAsWeekOfLastQuarter = (
  statisticsByPeriod: { period: string, totalEnergy: number, totalCost: number }[]
) => {
  const data = statisticsByPeriod.map(({ totalCost }) => totalCost / 100);
  return [{
    data,
    label: "Total Cost",
    backgroundColor: AppColors.blue500,
    borderColor: AppColors.blue500,
    borderWidth: 1
  }];
};


export const getMonthLabelForStatisticGraphByStatisticData = (
  statisticsByPeriod: { period: string, totalEnergy: number, totalCost: number }[]
) => {
  const uniquePeriods = getUniquePeriodsFromStatistics(statisticsByPeriod);
  const monthPeriods = uniquePeriods.map((p) => getMonthNameFromYearMonthFormat(p));
  const uniqueMonthsSet = new Set<string>();
  monthPeriods.forEach((m) => {
    uniqueMonthsSet.add(m);
  });
  return { monthlyLabels: Array.from(uniqueMonthsSet), uniquePeriods };
};

export const getMonthNameFromYearMonthFormat = (yearMonth: string) => {
  const [year, month] = yearMonth.split("-").map(Number);
  const date = DateTime.fromObject({ year, month });
  const monthName = date.monthLong;
  return monthName ?? "-";
};

// Helper function to get unique periods
export const getUniquePeriodsFromStatistics = (statisticsByPeriod: { period: string }[]) => {
  const periodsSet = new Set(statisticsByPeriod.map(({ period }) => period));
  return Array.from(periodsSet);
};


export const getReimbursementPaymentStateOptions = () => {
  return [
    { label: "Paid", value: ReimbursementPaymentState.PAID, textClass: "success-text" },
    { label: "Unpaid", value: ReimbursementPaymentState.UNPAID, textClass: "text-cherry-100" },
    { label: "Canceled", value: ReimbursementPaymentState.VOID, textClass: "text-canceled" }
  ];
};