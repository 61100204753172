import React from "react";
import TertiaryButton from "../../components/button/TertiaryButton";

type Props = {
  text: string;
  onClick: () => void;
};

function HeaderLink({ onClick, text }: Props) {
  return (
    <TertiaryButton text={text} onClick={onClick} textFontSize="fs-14px" isSmall={true} />
  );
}

export default HeaderLink;
