import React from "react";
import { CircularProgress } from "@mui/material";

import Button from "./Button";

export default function BaseButton({
                                     isDisabled,
                                     isLoading,
                                     className,
                                     textOverride,
                                     text,
                                     textFontSize = "16px",
                                     onClick,
                                     height,
                                     isSmall = false,
                                     theme,
                                     ...otherProps
                                   }) {
  const baseHeight = isSmall ? 36 : 42;
  const themeValue = theme ?? "primary";
  const buttonHeight = height ?? baseHeight;
  const buttonHeightClassName = `h-${buttonHeight}px`;
  return (
    <Button
      disabled={isDisabled || isLoading}
      color={themeValue}
      className={` rounded-xxl center ${buttonHeightClassName} ${className ?? ""}`}
      onClick={onClick}
      {...otherProps}
    >
      {isLoading ? (
        <CircularProgress size={12} color="inherit" />
      ) : (
        textOverride ?? <span className={`font-weight-normal  ${textFontSize}`}>{text}</span>
      )}
    </Button>
  );
}
