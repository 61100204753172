import React from "react";
import { SvgProps } from "types/svg.types";

type Props = {};

function InboxUnassignedVehicleIcon({ height = 24, width = 24 }: SvgProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="12" fill="#FFEFE6" />
      <g clipPath="url(#clip0_35851_63082)">
        <path
          d="M18.6248 11.4295C18.5966 11.3542 18.5543 11.286 18.5025 11.2319L17.2301 9.86768L16.1011 7.26158C16.0046 7.08047 15.8682 6.92994 15.7059 6.82645C15.546 6.7206 15.3649 6.6665 15.179 6.6665H8.82138C8.63557 6.6665 8.45446 6.7206 8.29216 6.82409C8.12987 6.92758 7.9958 7.07812 7.89702 7.25923L6.76802 9.86533L5.4979 11.2319C5.44615 11.2883 5.40381 11.3542 5.37559 11.4295C5.34736 11.5047 5.33325 11.5847 5.33325 11.667V16.7404C5.33325 16.898 5.38735 17.0486 5.48378 17.1591C5.58022 17.2697 5.70958 17.3332 5.846 17.3332H8.15339C8.35802 17.3332 8.66614 17.0956 8.66614 16.8604V16.0019H15.3343V16.7428C15.3343 16.9804 15.5389 17.3355 15.7435 17.3355H18.1521C18.2885 17.3355 18.4179 17.272 18.5143 17.1615C18.6107 17.0509 18.6648 16.9004 18.6648 16.7428V11.6694C18.6648 11.587 18.6507 11.5071 18.6225 11.4318L18.6248 11.4295ZM8.13693 10.3357L9.04248 8.14831C9.08011 8.05893 9.16714 8.00013 9.26357 8.00013H14.7439C14.8403 8.00013 14.9297 8.05893 14.965 8.14831L15.8635 10.3357C15.9294 10.4933 15.8118 10.6674 15.6424 10.6674H8.35802C8.18632 10.6674 8.07107 10.4933 8.13693 10.3357ZM9.33413 13.5628C9.33413 13.8051 9.13891 14.0003 8.89665 14.0003H7.10437C6.8621 14.0003 6.66688 13.8051 6.66688 13.5628V12.4385C6.66688 12.1539 6.93502 11.9446 7.21021 12.0151L9.00249 12.462C9.19771 12.5114 9.33413 12.6855 9.33413 12.8854V13.5628ZM17.3359 13.5628C17.3359 13.8051 17.1407 14.0003 16.8984 14.0003H15.1061C14.8639 14.0003 14.6686 13.8051 14.6686 13.5628V12.8854C14.6686 12.6855 14.8051 12.5091 15.0003 12.462L16.7926 12.0151C17.0678 11.9469 17.3359 12.1539 17.3359 12.4385V13.5628Z"
          fill="#FE5D02" />
      </g>
      <defs>
        <clipPath id="clip0_35851_63082">
          <rect width="13.3339" height="10.6667" fill="white" transform="translate(5.33325 6.6665)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default InboxUnassignedVehicleIcon;
