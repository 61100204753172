import React from "react";
import { Link } from "react-router-dom";
import { Row } from "reactstrap";
import MuiTooltip from "../tooltip/MuiTooltip";

export default function DynamicVehicleList({ vehicles }) {
  if (!vehicles || vehicles.length === 0) {
    return <></>;
  }

  const firstVehicle = vehicles[0];
  const firstVehicleName = firstVehicle.name;
  const firstVehicleLink = (
    <Link
      to={`/vehicles/${firstVehicle?.id}`}
      className={"text-neutral-100 text-decoration-underline"}
    >
      <p title={firstVehicleName} className={"mr-1 mb-0 w-max-150px"}>{firstVehicleName}</p>
    </Link>
  );

  if (vehicles.length === 1) {
    return firstVehicleLink;
  }

  const otherVehicles = vehicles.slice(1);
  const otherVehiclesLength = otherVehicles.length;

  let uniqueKey = "";
  otherVehicles.forEach((v) => {
    uniqueKey += v.name[0] + `${v.name[v.name.length - 1]}` + `-${otherVehiclesLength}`;
  });

  return (
    <Row className="flex flex-nowrap align-center m-0 priority-link">
      {firstVehicleLink}

      <MuiTooltip
        title={otherVehicles.map((vehicle, index) => (
          <div style={{ padding: 2 }} key={index}>{`${vehicle.name}`}</div>
        ))}
        placement="top"
      >
        <div id={uniqueKey} className="help-fill rounded-circle center bg-dark w-25px h-25px text-white">
          +{otherVehiclesLength}
        </div>
      </MuiTooltip>
    </Row>
  );
}