import AuthFormInput from "components/auth-components/form-components/AuthFormInput";
import CancelButton from "components/button/CancelButton";
import CustomerSupportLinkButton from "components/button/CustomerSupportLinkButton";
import PrimaryButton from "components/button/PrimaryButton";
import WhatIsNextCard from "components/card/WhatIsNextCard";
import Row from "components/custom/Row";
import ControllerValidationError from "components/form/ControllerValidationError";
import PasswordEyeHideIcon from "components/icon/icons/PasswordEyeHideIcon";
import PasswordEyeIcon from "components/icon/icons/PasswordEyeIcon";
import PinIconFilled from "components/icon/icons/PinIconFilled";
import PinIconFilledWithXIcon from "components/icon/icons/PinIconFilledWithXIcon";
import TickCircleIcon from "components/icon/icons/TickCircleIcon";
import { BlackBodyText200, BlackH5Text100 } from "components/text/Text";
import { useAlertManager } from "hooks/alert.hooks";
import { useTeslaCommandHandlersAsync } from "hooks/data/vehicle-data-accessor.hooks";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import { Form } from "reactstrap";
import { updateVehicleTeslaAsync } from "services/vehicle.services";
import { TeslaCommand } from "types/vehicle.types";
import { handleApiErrorResponse } from "utils";

type Props = {
  isDisableMode?: boolean;
  onFormReset: () => void;
};

type PinToDriverFormState = {
  PIN: string;
};

function VehicleDetailsPinToDriveEditorForm({ isDisableMode, onFormReset }: Props) {
  const { revalidateTeslaDrivePin } = useTeslaCommandHandlersAsync();

  const { handleOpenErrorAlert } = useAlertManager();
  const { vehicleId } = useParams<{ vehicleId: string }>();
  const [successState, setSuccessState] = useState({
    isOpen: false,
    isPinDisabled: false
  });

  const [isPinShown, setIsPinShown] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const { handleSubmit, register, watch, reset, errors } = useForm<PinToDriverFormState>({
    mode: "all"
  });

  const pinValue = watch("PIN");

  const handleSubmitDisablePin = useCallback(async () => {
    try {
      setIsSubmitting(true);
      await updateVehicleTeslaAsync(vehicleId, { command: TeslaCommand.DISABLE_DRIVE_PIN });
      setSuccessState({ isOpen: true, isPinDisabled: true });
      await revalidateTeslaDrivePin();
    } catch (error) {
      handleOpenErrorAlert(handleApiErrorResponse(error));
    } finally {
      setIsSubmitting(false);
    }
  }, [handleOpenErrorAlert, revalidateTeslaDrivePin, vehicleId]);

  const handleSubmitEnablePinForm = useCallback(
    async (formData: PinToDriverFormState) => {
      try {
        const { PIN } = formData;

        setIsSubmitting(true);
        await updateVehicleTeslaAsync(vehicleId, { command: TeslaCommand.ENABLE_DRIVE_PIN, pin: PIN.toString() });
        setSuccessState({ isOpen: true, isPinDisabled: false });
        await revalidateTeslaDrivePin();
      } catch (error) {
        handleOpenErrorAlert(handleApiErrorResponse(error));
      } finally {
        setIsSubmitting(false);
      }
    },
    [handleOpenErrorAlert, revalidateTeslaDrivePin, vehicleId]
  );

  const handleDoneButtonClicked = useCallback(() => {
    onFormReset();
    reset();
  }, [onFormReset, reset]);

  if (successState.isOpen) {
    return (
      <div className="p-4 center flex-column">
        <TickCircleIcon fill={"#23D206"} width={100} height={100} />
        <BlackH5Text100 className="mt-2">
          {successState.isPinDisabled ? "PIN to drive disabled successfully" : "4-digit PIN created successfully"}
        </BlackH5Text100>

        <WhatIsNextCard
          description={
            successState.isPinDisabled
              ? "The assigned driver will no longer need to enter a PIN."
              : "The assigned driver will need to enter the 4-digit PIN before starting the vehicle."
          }
        />

        <PrimaryButton type="button" text={"Done"} onClick={handleDoneButtonClicked} />
      </div>
    );
  }

  return (
    <div className="p-4">
      <div className="mb-3">{isDisableMode ? <PinIconFilledWithXIcon /> : <PinIconFilled />}</div>

      <BlackH5Text100>{isDisableMode ? "Disable PIN to drive" : "Enable PIN to drive"}</BlackH5Text100>
      <BlackBodyText200 className="mt-1">
        {isDisableMode
          ? "Are you sure to disable the 'PIN to Drive' feature."
          : "For an added layer of security, prevent your Tesla from being driven until a 4-digit PIN is entered. You can disable it at any time you want on the specific vehicle's page."}
      </BlackBodyText200>
      {isDisableMode ? (
        <Row className="justify-content-end mt-4">
          <div className="mr-2">
            <CancelButton />
          </div>

          <PrimaryButton onClick={handleSubmitDisablePin} type="button" text={"Disable"} isLoading={isSubmitting} />
        </Row>
      ) : (
        <Form className="w-100" onSubmit={handleSubmit(handleSubmitEnablePinForm)} onReset={onFormReset}>
          <div className="position-relative">
            <AuthFormInput
              register={register}
              data-testid="pin"
              placeholder="Enter 4-digit PIN"
              className={`w-max-100 w-100 mt-4 ${!isPinShown ? "input-with-hidden-value" : ""}`}
              label={undefined}
              name={"PIN"}
              type="number"
              maxLength={4}
              inputMode="numeric"
            />
            {isPinShown ? (
              <PasswordEyeHideIcon className="password-eye-icon clickable" onClick={() => setIsPinShown(false)} />
            ) : (
              <PasswordEyeIcon className="password-eye-icon clickable" onClick={() => setIsPinShown(true)} />
            )}
          </div>
          <ControllerValidationError error={errors.PIN?.message} />

          {!isDisableMode && (
            <BlackBodyText200 className="mt-4">
              If you encounter any issues or have questions, please don’t hesitate to reach out to{" "}
              <CustomerSupportLinkButton /> for assistance.
            </BlackBodyText200>
          )}
          <Row className="justify-content-end mt-4">
            <div className="mr-2">
              <CancelButton />
            </div>

            <PrimaryButton type="submit" text={"Enable"} isLoading={isSubmitting} isDisabled={pinValue?.length !== 4} />
          </Row>
        </Form>
      )}
    </div>
  );
}

export default VehicleDetailsPinToDriveEditorForm;
