import InfoBadge from "components/badge/InfoBadge";
import Card from "components/card/Card";
import LoadingSkeleton from "components/loading/LoadingSkeleton";
import { NeutralTitleSmallTextBlack } from "components/text/Text";
import TeslaPinToDriveEditor from "components/vehicle-components/tesla/controls/TeslaPinToDriveEditor";
import TeslaSystemStatusEditor from "components/vehicle-components/tesla/controls/TeslaSystemStatusEditor";
import VehicleDetailsAntiTheftModeEditor
  from "components/vehicle-components/vehicle-details/VehicleDetailsAntiTheftModeEditor";
import { useTeslaControlsStatusReadonly, useTeslaDrivePinAsync } from "hooks/data/vehicle-data-accessor.hooks";
import React from "react";

type Props = {};

function TeslaDrivePinEditors({}: Props) {
  const teslaControlsStatusHook = useTeslaControlsStatusReadonly();

  const teslaDrivePinHook = useTeslaDrivePinAsync(true);

  if (teslaDrivePinHook.isLoading || teslaControlsStatusHook.isLoading) {
    return <div>
      <Card><LoadingSkeleton count={2} height={50} /></Card>
      <Card className={"mt-4"}><LoadingSkeleton count={1} height={50} /></Card>
    </div>;
  }


  return (
    <>
      <Card>
        {teslaControlsStatusHook.isPinToDriverNotSupported ?
          (
            <>
              <NeutralTitleSmallTextBlack>PIN to drive</NeutralTitleSmallTextBlack>
              <InfoBadge
                text={"PIN to Drive requires virtual key support, which is not available on pre-2021 Model X and Model S vehicles. Learn more on Tesla's website."} />
            </>
          ) :
          (
            <>
              <TeslaSystemStatusEditor
                teslaDrivePinHook={teslaDrivePinHook}
              />
              {
                <TeslaPinToDriveEditor
                  teslaDrivePinHook={teslaDrivePinHook}
                />
              }
            </>
          )
        }
      </Card>

      <Card
        className={"mt-4"}>
        <VehicleDetailsAntiTheftModeEditor
          teslaDrivePinHook={teslaDrivePinHook}
        />
      </Card>
    </>

  );
}

export default TeslaDrivePinEditors;
