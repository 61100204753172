import * as React from "react";
import Box from "@mui/material/Box";
import { InboxMessageTabType } from "types/inbox.types";
import { StyledTab, StyledTabs } from "../tab/StyledTab";



type InboxMessagesTabProps = {
  onTabChange: (messageType: InboxMessageTabType) => void;
  activeTab: InboxMessageTabType;
};

export default function InboxMessagesTab({ onTabChange, activeTab }: InboxMessagesTabProps) {
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    onTabChange(newValue as InboxMessageTabType);
  };

  return (
    <Box>
      <StyledTabs value={activeTab} onChange={handleChange}>
        <StyledTab label="Messages" />
        <StyledTab label="Archived messages" />
        <></>
      </StyledTabs>
    </Box>
  );
}
