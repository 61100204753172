import Row from "components/custom/Row";
import CopyIcon from "components/icon/icons/CopyIcon";
import { ParagraphProps } from "components/text/Text";
import { useAlertManager } from "hooks/alert.hooks";
import { CopyToClipboard } from "react-copy-to-clipboard";

type Props = {
  TextComponent: React.FC<ParagraphProps>;
  textComponentClassName?: string;
  text?: string;
};

function ClipboardWithCopyIcon({ text, TextComponent, textComponentClassName }: Props) {
  const { handleOpenSuccessAlert } = useAlertManager();
  return (
    <CopyToClipboard className={'pointer-events-all'} text={text} onCopy={() => handleOpenSuccessAlert("Copied to clipboard!")}>
      <Row className="clickable align-items-center pointer-events-all">
        <TextComponent className={`mr-1 word-wrap-normal w-max-100 text-truncate ${textComponentClassName ?? ""}`}>
          {text}
        </TextComponent>
        <CopyIcon />
      </Row>
    </CopyToClipboard>
  );
}

export default ClipboardWithCopyIcon;
