import Box from "@mui/material/Box";
import { WhiteBody2Text0 } from "components/text/Text";
import * as React from "react";
import { DriversTableType } from "types/driver.types";
import { StyledTab, StyledTabs } from "../tab/StyledTab";
import { useHistory, useLocation } from "react-router";


type Props = {
  pendingSignUpApplicationCount?: number | null;
};

export default function DriversPageTab({ pendingSignUpApplicationCount }: Props) {
  const { pathname } = useLocation();
  const history = useHistory();
  const isActiveTabPending = pathname.includes("pending");

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (newValue === DriversTableType.ALL) {
      history.push("/drivers");
      return;
    }
    history.push("/drivers/pending");
  };

  return (
    <Box>
      <StyledTabs value={isActiveTabPending ? DriversTableType.PENDING : DriversTableType.ALL} onChange={handleChange}>
        <StyledTab label="All drivers" />
        <StyledTab label="Pending approval"
                   rightElement={pendingSignUpApplicationCount && pendingSignUpApplicationCount > 0 && (
                     <div className="center">
                       <div
                         className="w-min-20px w-min-20px h-20px rounded-circle bg-cherry-500 center drivers-tab-notification-badge">
                         <WhiteBody2Text0 className="mx-1">{pendingSignUpApplicationCount}</WhiteBody2Text0>
                       </div>
                     </div>
                   )} />

      </StyledTabs>
    </Box>
  );
}
