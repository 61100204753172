// React
import React, { useCallback, useEffect, useMemo, useState } from "react";

// 3rd party
// Hooks & Utils
// Types
// Components
import FilterSearchInput from "components/filter/FilterSearchInput";
import PaginatedTable from "components/table/PaginatedTable";
import Row from "../custom/Row";
import NoDataPlaceholder from "../table/placeholder/NoDataPlaceholder";
import { TableColumn } from "react-data-table-component";
import PrimaryButton from "../button/PrimaryButton";
import { Icon } from "../Component";
import DepotChargingLocationMapEditor from "./DepotChargingLocationMapEditor";
import { useDepotChargingLocationListAsync } from "../../hooks/data/depot-charging-locations-data-accessor.hooks";
import { OrganizationDepotChargingLocation } from "../../types/depot-charging-location.types";
import { getLocalFormattedDateForTables } from "../../utils/date.utils";
import DepotChargingLocationAddressColumnAsync from "./DepotChargingLocationAddressColumnAsync";
import PrimaryNegativeButton from "../button/PrimaryNegativeButton";
import PencilIcon from "../icon/icons/PencilIcon";
import AlertConfirmDialog from "../notification/AlertConfirmDialog";
import CancelButton from "../button/CancelButton";
import { deleteDepotChargingLocationAsync } from "../../services/depot-charging-location.service";
import { handleApiErrorResponse } from "../../utils";
import { useAlertManager } from "../../hooks/alert.hooks";
import TrashIcon from "../icon/icons/TrashIcon";
import OutlinedButtonWithIcon from "../button/OutlinedButtonWithIcon";

export type DepotChargingLocationsTableColumn = TableColumn<OrganizationDepotChargingLocation>[];

type Props = {}

const ReimbursementsTable = ({}: Props) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  /**
   * Hooks
   */
  const { handleOpenSuccessAlert, handleOpenErrorAlert } = useAlertManager();

  const [locationToUpdate, setLocationToUpdate] = useState<OrganizationDepotChargingLocation | null>(null);
  const [locationIdToDelete, setLocationIdToDelete] = useState<string | null>(null);

  const [isEditorModalOpen, setIsEditorModalOpen] = useState(false);

  useEffect(() => {
    if (locationToUpdate) {
      setIsEditorModalOpen(true);
    }
  }, [locationToUpdate]);


  useEffect(() => {
    if (!isEditorModalOpen) {
      setLocationToUpdate(null);
    }
  }, [isEditorModalOpen]);

  const { list, isLoading, meta, refetch } = useDepotChargingLocationListAsync();

  const columns = useMemo(
    () => [
      {
        grow: 1,
        name: "Charger name",
        selector: (row: OrganizationDepotChargingLocation) => row.name
      },
      {
        grow: 1.5,
        name: "Charger address",
        selector: (row: OrganizationDepotChargingLocation) =>
          <DepotChargingLocationAddressColumnAsync
            polygon={row.polygon}
          />
      },
      {
        grow: 1,
        name: "Added",
        selector: (row: OrganizationDepotChargingLocation) => <span
          title={getLocalFormattedDateForTables(row?.createdAt)}>{getLocalFormattedDateForTables(row?.createdAt)} </span>
      },
      {
        width: "150px",
        name: "Actions",
        selector: (row: OrganizationDepotChargingLocation) => <Row>
          <OutlinedButtonWithIcon
            isSmall={true}
            className={"mr-2"}
            leftIcon={<PencilIcon />}
            text={""}
            onClick={() => setLocationToUpdate(row)}
          />
          <PrimaryNegativeButton
            isSmall={true}
            leftIcon={<TrashIcon />}
            text={""}
            onClick={() => setLocationIdToDelete(row.id)} />
        </Row>
      }
    ],
    []
  ) as DepotChargingLocationsTableColumn;


  /**
   * Handlers
   */
  const handleSubmitDeleteAsync = useCallback(async () => {
    if (!locationIdToDelete) {
      return;
    }

    try {
      setIsSubmitting(true);
      await deleteDepotChargingLocationAsync(locationIdToDelete);
      refetch();
      handleOpenSuccessAlert("Depot location deleted successfully.");
    } catch (error) {
      handleOpenErrorAlert(handleApiErrorResponse(error));
    } finally {
      setIsSubmitting(false);
      setLocationIdToDelete(null);
    }
  }, [handleOpenErrorAlert, handleOpenSuccessAlert, locationIdToDelete, refetch]);

  /**
   * Effects
   */

  /**
   * Render
   */

  return (
    <React.Fragment>
      <AlertConfirmDialog
        title={`Delete Depot Charger`}
        description={"Are you sure you want to delete your depot charger? This action cannot be undone."}
        isDialogOpen={!!locationIdToDelete}
        overrideButtons={
          <>
            <div className="mr-2">
              <CancelButton onClick={() => setLocationIdToDelete(null)} />
            </div>
            <PrimaryButton text={"Delete"} isLoading={isSubmitting} onClick={handleSubmitDeleteAsync} />
          </>
        }
      />


      {isEditorModalOpen && <DepotChargingLocationMapEditor
        isOpen={isEditorModalOpen}
        setIsOpen={setIsEditorModalOpen}
        isLoading={isLoading}
        depotChargingLocationToUpdate={locationToUpdate}
        setDepotChargingLocationToUpdate={setLocationToUpdate}
        refetch={refetch}
      />}


      <Row className="justify-between my-2 mt-3 align-center">
        <FilterSearchInput placeholder="Search name" />

        <PrimaryButton onClick={() => setIsEditorModalOpen(true)} leftIcon={<Icon name="plus" />}
                       text={"Add Depot Charger"} />
      </Row>

      <PaginatedTable
        noDataPlaceholder={
          <NoDataPlaceholder description="No depot locations yet." />
        }
        paginationTotalRows={meta?.pagination.totalElements}
        columns={columns}
        data={list}
        isLoading={isLoading}
      />
    </React.Fragment>
  )
    ;
};

export default ReimbursementsTable;
