import Row from "components/custom/Row";
import InfoIcon from "components/icon/icons/InfoIcon";
import XIcon from "components/icon/icons/XIcon";
import { NeutralBodySmallText100 } from "components/text/Text";
import { Card } from "reactstrap";

type Props = { text?: string; textOverride?: React.JSX.Element; onClose?: () => void, className?: string };

function InfoBadge({ text, textOverride, onClose, className }: Props) {
  return (
    <Card className={`p-2 rounded-xxl bg-bd-blue-100 ${className ?? "mt-2"}`}>
      <Row className="justify-between  flex-nowrap">
        <Row className="flex-nowrap">
          <div className="w-min-20px d-flex align-items-start h-20px mr-1">
            <InfoIcon />
          </div>
          {textOverride ?? <NeutralBodySmallText100 className={"fs-13px"}>{text}</NeutralBodySmallText100>}
        </Row>
        {onClose && <XIcon onClick={onClose} className="clickable ml-2" width={12} height={12} />}

      </Row>
    </Card>
  );
}

export default InfoBadge;
