import { useJsApiLoader } from "@react-google-maps/api";
import { useCallback, useEffect, useMemo, useState } from "react";


type Location = {
  latitude: number;
  longitude: number;
};

type CachedLocation = {
  id: string;
  location: string
  lastFetchedAt: number,
}


export const useGoogleReverseGeocoding = (geolocation?: Location) => {
  const { isLoaded } = useGoogleApiLoader();
  const cacheDuration = 60000;

  // Change to use Map for caching locations
  const [cachedData, setCachedData] = useState<Map<string, CachedLocation>>(new Map());
  const [isLocationLoading, setIsLocationLoading] = useState(false);
  const [location, setLocation] = useState("");

  const latlng = useMemo(() => {
    if (!geolocation) return undefined;
    return { lat: geolocation.latitude, lng: geolocation.longitude };
  }, [geolocation]);

  const handleGetLocationAsync = useCallback(async () => {
    if (!latlng) return;
    const key = `${latlng.lat}-${latlng.lng}`;
    const currentTime = new Date().getTime();
    const cachedLocationData = cachedData.get(key);

    if (cachedLocationData && currentTime - cachedLocationData.lastFetchedAt < cacheDuration) {
      setLocation(cachedLocationData.location);
      return;
    }

    try {
      setIsLocationLoading(true);
      const geocoder = new google.maps.Geocoder();
      const response = await geocoder.geocode({ location: latlng });

      const address = response?.results[1];
      if (address) {
        const formattedAddress = address.formatted_address;
        setLocation(formattedAddress);

        setCachedData(new Map(cachedData).set(key, {
          id: key,
          location: formattedAddress,
          lastFetchedAt: currentTime
        }));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLocationLoading(false);
    }
  }, [cachedData, latlng]);

  useEffect(() => {
    if (isLoaded) {
      handleGetLocationAsync();
    }

    // Do not set handleGetLocationAsync as a dependency since it triggers this effect when the cache is changed which causes an infinite loop.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded, latlng]);

  const link = `https://www.google.com/maps/search/?api=1&query=${latlng?.lat},${latlng?.lng}`;
  return { location, link, isLocationLoading };
};

export const useGoogleApiLoader = () => {
  const [libraries] = useState<["maps", "places"]>(["maps", "places"]);
  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? "";
  return useJsApiLoader({
    id: "google-map-script",
    libraries,
    googleMapsApiKey
  });
};


export const useResizeMapFullWidthHeight = (map: google.maps.Map | null, mapId: string) => {
  const [mapDimensions, setMapDimensions] = useState({ width: "500px", height: "400px" });

  const resizeMap = useCallback(() => {
    const parentDiv = document.getElementById(mapId);


    if (!parentDiv) {
      return;
    }

    const mapHeight = "400px";

    setMapDimensions({
      width: parentDiv.clientWidth + "px",
      height: mapHeight
    });

    if (map) {
      window.google.maps.event.trigger(map, "resize");
    }
  }, [map, mapId]);


  useEffect(() => {
    window.addEventListener("resize", resizeMap);

    return () => {
      window.removeEventListener("resize", resizeMap);
    };
  }, [resizeMap]);


  useEffect(() => {
    resizeMap();
  }, [resizeMap]);


  return { mapDimensions };
};