import restApiClient from "./client";
import { generateApiEndpoint } from "utils/api.utils";

/**
 * POST: '/api/organization'
 */
export async function generateAccessToken() {
  const endpoint = generateApiEndpoint("chat/token");
  const response = await restApiClient.post(endpoint);

  return response.data;
}
