import Card from "components/card/Card";

import Col from "components/custom/Col";
import Row from "components/custom/Row";
import CircularLoadingSpinner from "components/loading/CircularLoadingSpinner";
import { BlackSubtitleText100, NeutralBodySmallText300, NeutralTitleSmallTextBlack } from "components/text/Text";
import TeslaControlsEditors from "components/vehicle-components/tesla/controls/TeslaControlsEditors";
import TeslaDrivePinEditors from "components/vehicle-components/tesla/controls/TeslaDrivePinEditors";
import TeslaVehicleStateCard from "components/vehicle-components/tesla/controls/TeslaVehicleStateCard";
import TeslaVirtualKeyEditor from "components/vehicle-components/tesla/controls/TeslaVirtualKeyEditor";
import TeslaWakeUpEditorModal from "components/vehicle-components/tesla/controls/TeslaWakeUpEditorModal";
import { useTeslaInfoAsync, useVehicleTeslaControlsStatusAsync } from "hooks/data/vehicle-data-accessor.hooks";
import { useEffect } from "react";
import { useHistory } from "react-router";
import { TeslaDataAccessorHook, VehicleDataAccessorHook } from "types/vehicle.types";
import { getDateAsFromNowFormatWithFetchedPrefix } from "utils/date.utils";
import VehicleDetailsControlsContentVirtualKeyAlert from "../VehicleDetailsControlsContentVirtualKeyAlert";
import VehicleDetailsLocationInfo from "../VehicleDetailsLocationInfo";
import VehicleDetailsLocationMap from "../VehicleDetailsLocationMap";
import VehicleDetailsOverviewCard from "../VehicleDetailsOverviewCard";

type Props = {
  readonly vehicleHook: VehicleDataAccessorHook;
}

function VehicleDetailsTeslaControlsContent({ vehicleHook }: Props): JSX.Element {
  const history = useHistory();
  const isTeslaConnected = vehicleHook.isTeslaConnected;


  useEffect(() => {
    if (!isTeslaConnected && !vehicleHook.isLoading) {
      history.replace("/vehicle-details");
    }
  }, [history, isTeslaConnected, vehicleHook.isLoading]);


  const teslaHook = useTeslaInfoAsync(isTeslaConnected)

  const controlsStatusHooks = useVehicleTeslaControlsStatusAsync(isTeslaConnected);

  if (vehicleHook.isLoading) {
    return <CircularLoadingSpinner />;
  }

  return (
    <>
      <Row className={"align-items-center mb-2"}>
        <BlackSubtitleText100 className={"mr-2"}>Controls</BlackSubtitleText100>
        {controlsStatusHooks.isKeyRequired && <VehicleDetailsControlsContentVirtualKeyAlert />}
      </Row>

      <TeslaWakeUpEditorModal teslaHook={teslaHook} />
      <Row>
        <Col lg={6} className="pr-lg-3">
          {controlsStatusHooks.isKeyRequired && <TeslaVirtualKeyEditor />}


          <TeslaDrivePinEditors />

          <VehicleDetailsOverviewCard className={"mt-4"}
                                      fullLink={"/vehicle-locator"}
                                      titleOverwrite={
                                        <Row className="align-items-center justify-between w-100">
                                          <NeutralTitleSmallTextBlack
                                            className={"mr-2"}>Location</NeutralTitleSmallTextBlack>
                                          <NeutralBodySmallText300>{getDateAsFromNowFormatWithFetchedPrefix(vehicleHook.vehicle?.telemetry?.location?.meta.fetchedAt)}</NeutralBodySmallText300>
                                        </Row>
                                      }
                                      linkRoute={""}
                                      linkText={"See details>"}
                                      description={<VehicleDetailsLocationInfo
                                        telemetry={vehicleHook.vehicle?.telemetry}
                                        isLoading={vehicleHook.isLoading} />}
                                      content={<VehicleDetailsLocationMap vehicleHook={vehicleHook} />} />


        </Col>
        <Col lg={6} className="pl-lg-3">
          <Card
            className={"mb-4"}>
            <TeslaVehicleStateCard teslaHook={teslaHook} />
          </Card>

          <TeslaControlsEditors
            teslaHook={teslaHook}
          />
        </Col>
      </Row>
    </>

  );

}

export default VehicleDetailsTeslaControlsContent;