import classNames from "classnames";
import { Row as ReactstrapRow, RowProps } from "reactstrap";

export default function Row(props: RowProps) {
  return (
    <ReactstrapRow {...props} className={classNames("m-0", props.className)}>
      {props?.children}
    </ReactstrapRow>
  );
}
