import PrimaryButton from "components/button/PrimaryButton";
import Row from "components/custom/Row";
import LoadingSkeleton from "components/loading/LoadingSkeleton";
import ProcessingCircularBar from "components/loading/ProcessingCircularBar";
import {
  BlackBody2Text0,
  BlackCaptionText200,
  NeutralOverlineText100,
  NeutralTitleSmallTextBlack
} from "components/text/Text";
import { TeslaDataAccessorHook } from "types/vehicle.types";
import { getTeslaVehicleStatusContent } from "utils/vehicle.utils";

type Props = {
  teslaHook: TeslaDataAccessorHook;
};

function TeslaVehicleStateCard({ teslaHook }: Props) {
  const isWakeUpSequenceRunning = teslaHook.isWakeUpSequenceRunning;

  if (teslaHook.isLoading && !teslaHook.isWakeUpSequenceRunning) {
    return <div className={"py-2"}><LoadingSkeleton count={1} height={40} /></div>;
  }


  const { icon, label, textClass } = getTeslaVehicleStatusContent(teslaHook.teslaVehicleStatus);

  return (
    <div>
      <Row className="justify-between">
        <BlackCaptionText200>Vehicle state</BlackCaptionText200>
        {!isWakeUpSequenceRunning && <Row>
          {icon}
          <BlackBody2Text0 className={`ml-1 ${textClass ?? ""}`}>{label}</BlackBody2Text0>
        </Row>}

      </Row>

      {isWakeUpSequenceRunning && <div className={"position-relative h-92px mt-3"}>
        <div className={"animated-linear-border-card bg-secondary h-100"}>
          <ProcessingCircularBar />
        </div>
        <div className={"animated-linear-background"} />
      </div>
      }


      {teslaHook.isSleeping && !isWakeUpSequenceRunning && (
        <div className="bg-orange-1000 mt-2 rounded-xxl p-2">
          <Row className="flex-nowrap align-items-center">
            <div className={"orange-dot mr-1"} />
            <NeutralTitleSmallTextBlack>Sleep mode</NeutralTitleSmallTextBlack>
          </Row>
          <NeutralOverlineText100>
            Your vehicle is in sleep mode and controls are currently inactive. Wake up your vehicle to access vehicle
            controls and settings.
          </NeutralOverlineText100>
          <Row className={"w-100 justify-content-end mt-1"}>
            <PrimaryButton isSmall={true} className={"ml-1"} onClick={teslaHook.triggerTeslaWakeUpSequence}
                           text={"Wake Up"} />
          </Row>
        </div>
      )}
    </div>
  );
}

export default TeslaVehicleStateCard;
