import Col from "../custom/Col";
import { NeutralH3Text0, BlackSubtitleText200 } from "../text/Text";

type props = {
  title: string;
  description: string | JSX.Element;
};

export default function CenteredHeader({ title, description }: props) {
  return (
    <Col className="w-100 my-2 justify-center align-center flex-column">
      <NeutralH3Text0>{title}</NeutralH3Text0>
      <BlackSubtitleText200 className="mt-3">{description}</BlackSubtitleText200>
    </Col>
  );
}
