import { OutlinedButtonWithIconProps } from "components/button/OutlinedButtonWithIcon";
import TeslaControlledTooltipButton from "components/vehicle-components/tesla/controls/TeslaControlledTooltipButton";
import { useTeslaControlsStatusReadonly } from "hooks/data/vehicle-data-accessor.hooks";
import React from "react";

type Props = OutlinedButtonWithIconProps & {
  isAdditionallySupported?: boolean;
  tooltipMessageOverride?: string
}
const TeslaBasicControlsButton = ({ ...props }: Props) => {
  const { isBasicControlsSupported, isLoading } = useTeslaControlsStatusReadonly();

  const isSupported = props.isAdditionallySupported !== false && isBasicControlsSupported;

  return (
    <TeslaControlledTooltipButton
      isSupported={isSupported}
      isLoading={isLoading}
      {...props}
    />
  );
};

export default TeslaBasicControlsButton;