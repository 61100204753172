import Card from "components/card/Card";
import PageHeader from "components/header/PageHeader";
import { useAuthStatus } from "hooks/auth.hooks";
import { getFormattedPhoneNumberNational } from "utils/format.utils";
import SettingsPageCompanyNameEditor from "./form/SettingsPageCompanyNameEditor";
import RowBetweenBlock from "../block/RowBetweenBlock";

type Props = {};

function SettingsPageAccountContent({}: Props) {
  const { user } = useAuthStatus();
  return (
    <Card padding="p-4 w-100">
      <PageHeader title="Account" description="Your personal details at a glance." />
      <RowBetweenBlock className={'mt-3'} isBorderBottomVisible leftText={"Full name"} rightText={user?.name ?? "-"} />
      <RowBetweenBlock isBorderBottomVisible leftText="Email" rightText={user?.email ?? "-"} />
      <RowBetweenBlock isBorderBottomVisible leftText="Phone number"
                       rightText={getFormattedPhoneNumberNational(user?.phoneNumber)} />
      <SettingsPageCompanyNameEditor />
    </Card>
  );
}

export default SettingsPageAccountContent;
