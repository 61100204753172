import ContinueButton from "components/button/ContinueButton";
import SignInTextLink from "components/button/SignInTextLink";
import { Dispatch, SetStateAction, SyntheticEvent, useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AuthSignUpFormFlowSteps, AuthSignUpFormState, DialCode } from "types/auth.types";
import UnderlinedTextButton from "../../button/UnderlinedTextButton";
import Row from "../../custom/Row";
import { BlackBodyText200, BlackCaptionText300, BlackH3Text100 } from "../../text/Text";
import AuthFormInput from "./AuthFormInput";
import AuthPhoneNumberInput from "./AuthPhoneNumberInput";

type Props = {
  readonly setFormFlowStep: Dispatch<SetStateAction<AuthSignUpFormFlowSteps>>;
  readonly onFormStateChanged: Dispatch<SetStateAction<AuthSignUpFormState>>;
  readonly registerFormState: AuthSignUpFormState;
};

type FormValues = {
  phoneNumber: string;
  name: string;
};

const PRIVACY_URL = "https://docs.google.com/document/d/1sb0p8rFpp6wXmx3Haggeon-J4gvoBnYmnl7nQuDYlrM/edit";
const TERMS_URL = "https://docs.google.com/document/d/1AVIv6LcCBR8RmCRfrGFZlq6ZB7RWkcYCNAt-TjD3prM/edit";

function SignUpFormFlowStartStep({ onFormStateChanged, setFormFlowStep, registerFormState }: Props) {
  const { register, handleSubmit, trigger, errors } = useForm<FormValues>({
    mode: "all"
  });

  const { t } = useTranslation("common");

  const [dialCode, setDialCode] = useState<DialCode>(registerFormState.dialCode ?? { label: "US +1", value: "+1" });

  /**
   *
   */
  const handleStepCompleted = useCallback(
    (values: FormValues) => {
      onFormStateChanged((oldState) => {
        return { ...oldState, dialCode: dialCode, phoneNumber: values.phoneNumber, name: values.name };
      });
      setFormFlowStep(AuthSignUpFormFlowSteps.COMPANY);
    },
    [dialCode, onFormStateChanged, setFormFlowStep]
  );

  /**
   *
   */
  const handleFormSubmit = useCallback(
    async (e: SyntheticEvent<HTMLFormElement, SubmitEvent>) => {
      e.preventDefault();

      await trigger();

      handleSubmit(handleStepCompleted)(e);
    },
    [handleStepCompleted, handleSubmit, trigger]
  );

  const handleOpenLinkClicked = useCallback((url: string) => {
    window.open(url, "_blank");
  }, []);

  return (
    <form onSubmit={handleFormSubmit}>
      <BlackH3Text100 className="mb-2">{t("auth.sign_up.flow_steps.start.title")}</BlackH3Text100>
      <BlackBodyText200 className="mb-4">{t("auth.sign_up.flow_steps.start.description")}</BlackBodyText200>
      <AuthFormInput
        aria-label="name"
        defaultValue={registerFormState?.name}
        register={register}
        type="text"
        placeholder="Full name"
        name="name"
        required
        className="w-max-100 w-100"
      />

      <AuthPhoneNumberInput
        defaultSelectedDialCode={registerFormState.dialCode}
        defaultPhoneNumberValue={registerFormState?.phoneNumber}
        errors={errors}
        register={register}
        onDialCodeChanged={setDialCode}
        dialCode={dialCode}
      />

      <ContinueButton type="submit" className={"mt-4 w-100"} />

      <Row className="center mt-4">
        <BlackBodyText200 className="mr-1">Already have an account?</BlackBodyText200>
        <SignInTextLink />
      </Row>
      <BlackCaptionText300 className="mt-4 py-2">
        By creating an account, you accept the Bluedot for Fleets{" "}
        <UnderlinedTextButton
          text={"Terms of Service"}
          className="clickable d-inline"
          onClick={() => handleOpenLinkClicked("/terms")} />
        {" "} and acknowledge our{" "}
        <UnderlinedTextButton text={"Privacy Policy."}
                              className="clickable d-inline"
                              onClick={() => handleOpenLinkClicked("/privacy")} />

      </BlackCaptionText300>
    </form>
  );
}

export default SignUpFormFlowStartStep;
