import { useChargingSessionCommonTableColumns } from "hooks/charging-session-hooks";
import { useVehicleChargingListAsync } from "hooks/data/vehicle-data-accessor.hooks";
import { useStripeInvoiceUrlAccessor } from "hooks/invoice.hooks";
import React, { useMemo } from "react";
import DataTable from "react-data-table-component";
import { Charging } from "types/charging-sessions.types";
import LoadingSkeleton from "../../loading/LoadingSkeleton";
import NoDataPlaceholder from "../../table/placeholder/NoDataPlaceholder";

type Props = {};

export type VehicleChargingSessionsTableColumn = {
  name: string;
  selector?: (row?: Charging) => JSX.Element | string | any;
  width?: string;
  cell?: (row?: any) => JSX.Element | string;
};

function VehicleDetailsOverviewChargingTable({}: Props) {

  const { isLoading, vehicleChargingList } = useVehicleChargingListAsync(true);


  const commonTableColumns = useChargingSessionCommonTableColumns();

  const { handleForwardToStripeAsync, isForwarding } = useStripeInvoiceUrlAccessor();

  const columns = useMemo(
    () => [
      commonTableColumns.driver,
      commonTableColumns.state,
      commonTableColumns.source,
      commonTableColumns.chargerName,
      commonTableColumns.type,
      commonTableColumns.energy,
      commonTableColumns.cost,
      commonTableColumns.payStat,
      commonTableColumns.stripeInvoiceId(handleForwardToStripeAsync, isForwarding),
      commonTableColumns.whoPays,
      commonTableColumns.duration,
      commonTableColumns.date,
      commonTableColumns.action
    ],
    [commonTableColumns, handleForwardToStripeAsync, isForwarding]
  ) as VehicleChargingSessionsTableColumn[];


  return (
    <>
      <DataTable
        progressComponent={<LoadingSkeleton count={3} height={50} className={"px-2"} />}
        noDataComponent={
          <NoDataPlaceholder height={150} />
        }
        style={{ borderRadius: 10 }}
        className={`rounded-xxl  ${isLoading && "mt-2"}`}
        highlightOnHover
        responsive
        progressPending={isLoading}
        columns={columns}
        data={vehicleChargingList}
      />
    </>
  );
}

export default VehicleDetailsOverviewChargingTable;
