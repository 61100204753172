import FilterEditorCard from "components/filter/FilterEditorCard";
import FilterMenuCheckbox from "components/filter/FilterMenuCheckbox";
import { useFilterUrlQuery } from "hooks/filter.hooks";
import { useCallback, useEffect, useState } from "react";
import { VehicleConnectionState, VehiclesTableFilters } from "types/vehicle.types";
import { getVehicleConnectionOption, getVehicleConnections } from "utils/vehicle.utils";

type Props = {};

function FilterVehicleStatusSelector(props: Props) {
  const { filterQuery, setFilterQuery } = useFilterUrlQuery<VehiclesTableFilters>();
  const [selectedConnections, setSelectedConnections] = useState<VehicleConnectionState[]>(
    filterQuery?.connection?.$in ?? []
  );
  const [variationCount, setVariationCount] = useState(0);

  useEffect(() => {
    setSelectedConnections(filterQuery?.connection?.$in ?? []);
    setVariationCount(filterQuery?.connection?.$in?.length ?? 0);
  }, [filterQuery]);

  const isSelectedConnection = useCallback(
    (connection: VehicleConnectionState) => {
      return selectedConnections.includes(connection);
    },
    [selectedConnections]
  );

  const handleNetworkToggled = useCallback(
    (connection: VehicleConnectionState) => {
      if (isSelectedConnection(connection)) {
        setSelectedConnections(selectedConnections.filter((n) => n !== connection));
        return;
      }
      setSelectedConnections([...selectedConnections, connection]);
    },
    [isSelectedConnection, selectedConnections]
  );

  const handleSetNetworksOnQuery = useCallback(
    (connections: VehicleConnectionState[]) => {
      setFilterQuery((q) => {
        return {
          connection: {
            $in: connections
          }
        };
      });
    },
    [setFilterQuery]
  );

  const handleClearButtonPressed = useCallback(() => {
    setSelectedConnections([]);
    handleSetNetworksOnQuery([]);
    setVariationCount(0);
  }, [handleSetNetworksOnQuery]);

  const handleApplyButtonPressed = useCallback(() => {
    handleSetNetworksOnQuery(selectedConnections);
    setVariationCount(selectedConnections.length);
  }, [handleSetNetworksOnQuery, selectedConnections]);

  return (
    <FilterEditorCard
      className="my-1"
      title="Status"
      label="Status"
      description="Current status of your EV"
      variationCount={variationCount}
      onApplyPressed={handleApplyButtonPressed}
      onClearPressed={handleClearButtonPressed}
    >
      {getVehicleConnections().map((connection, ix) => (
        <FilterMenuCheckbox
          id={ix}
          key={ix}
          onToggle={() => handleNetworkToggled(connection)}
          isChecked={isSelectedConnection(connection)}
          label={getVehicleConnectionOption(connection).label}
          description={getVehicleConnectionOption(connection).description}
          textClassName={getVehicleConnectionOption(connection).textClass}
        />
      ))}
    </FilterEditorCard>
  );
}

export default FilterVehicleStatusSelector;
