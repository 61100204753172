import InboxDangerIcon from "components/icon/icons/InboxDangerIcon";
import InboxWarningIcon from "components/icon/icons/InboxWarningIcon";
import InfoIcon from "components/icon/icons/InfoIcon";
import React, { useMemo } from "react";
import { MessageLevel } from "types/inbox.types";

type Props = {
  level?: MessageLevel;
  isLarge?: boolean;
};

function InboxMessageLevelIcon({ level, isLarge }: Props) {

  return useMemo(() => {
    const height = isLarge ? 48 : undefined;
    const widht = isLarge ? 48 : undefined;
    switch (level) {
      case MessageLevel.ALERT:
        return <InboxDangerIcon width={widht} height={height} />;
      case MessageLevel.WARNING:
        return <InboxWarningIcon width={widht} height={height} />;

      default:
        return <InfoIcon fill="#0C0C0C" width={widht} height={height} />;
    }
  }, [isLarge, level]);
}

export default InboxMessageLevelIcon;
