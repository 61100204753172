import React from "react";
import { SvgProps } from "types/svg.types";

type Props = {};

function ChevronBottomIcon(props: SvgProps) {
  return (

    <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.999999 6.5L6 1.5L11 6.5" stroke="#6F7981" strokeWidth="1.5" strokeLinecap="round"
            strokeLinejoin="round" />
    </svg>

  );
}

export default ChevronBottomIcon;
