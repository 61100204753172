import { DateTime } from "luxon";
import {
  BillingCycle,
  ChargingInvoiceLineItem,
  DriverData,
  Invoice,
  InvoiceGeneratedAs,
  InvoicePaymentState,
  InvoiceType,
  PlatformInvoiceLineItem,
  VehicleData
} from "types/invoice.types";
import { getFormattedNumberForEnergy } from "./charging.utils";
import { getLocalDate, getLocalFormattedDateAsMonthYear } from "./date.utils";
import { getFormattedNumberForUsdByCent, getFullNameByFirstNameAndLastName } from "./format.utils";

export const AddManuelInvoiceAlertDialogStates = {
  failed: {
    isError: true,
    isOpen: true,
    message: "Invoice couldn't be created!"
  },
  success: {
    isError: false,
    isOpen: true,
    message: "Invoice created successfully!"
  }
};


export const getInvoiceTypeLabel = (invoiceType: InvoiceType) => {
  if (invoiceType === InvoiceType.CHARGING) {
    return "Charging fee";
  }

  if (invoiceType === InvoiceType.PLATFORM) {
    return "Platform fee";
  }


  return "Debit invoice";

};


export const getInvoiceDescription = (invoice: Invoice) => {
  if (invoice.billingCycle === BillingCycle.ONE_OFF) {
    return "One Off";
  }

  if (invoice.generatedAs === InvoiceGeneratedAs.SETTLEMENT) {
    return "Settlement";
  }

  return "-";
};


export const getInvoicePaymentStatusLabelAndColor = (paymentState: InvoicePaymentState) => {
  if (paymentState === InvoicePaymentState.PAID) {
    return { color: "#2BA318", label: "Paid" };
  }

  if (paymentState === InvoicePaymentState.UNPAID) {
    return { color: "#C4003E", label: "Unpaid" };
  }

  return { color: "#7611D9", label: "Canceled" };
};


export const getPlatformInvoiceTableRowData = (lineItem: PlatformInvoiceLineItem) => {

  const isDriver = lineItem.objectData.__typename === "DRIVER";

  if (isDriver) {
    const lineItemDriverData = lineItem.objectData as DriverData;

    return {
      name: getFullNameByFirstNameAndLastName(lineItemDriverData.firstName, lineItemDriverData.lastName),
      type: "Driver",
      price: getFormattedNumberForUsdByCent(lineItem.netAmount)
    };
  }


  const lineItemVehicleData = lineItem.objectData as VehicleData;
  return {
    name: lineItemVehicleData.name,
    type: "Vehicle",
    price: getFormattedNumberForUsdByCent(lineItem.netAmount)
  };
};


export const getInvoiceDetailsExportDataByType = (lineItems: ChargingInvoiceLineItem[] | PlatformInvoiceLineItem[], isChargingInvoice: boolean) => {

  if (isChargingInvoice) {
    const chargingInvoiceLineItems = lineItems as ChargingInvoiceLineItem[];
    return chargingInvoiceLineItems?.map((i) => {
      return {
        name: i.objectData.__typename,
        vehicle: i.objectData.vehicleName,
        vin: i.objectData.vehicleVin,
        driver: i.objectData.driverName,
        chargerName: i.objectData.chargerName,
        discountRate: i.discountRate,
        date: i.objectData.startedAt,
        discountAmount: getFormattedNumberForUsdByCent(i.discountAmount),
        grossAmount: getFormattedNumberForUsdByCent(i.grossAmount),
        kwh: getFormattedNumberForEnergy(i.objectData.energy),
        netAmount: getFormattedNumberForUsdByCent(i.netAmount),
        quantity: i.quantity,
        updatedAt: i.updatedAt
      };

    });

  }

  const platformInvoiceLineItems = lineItems as PlatformInvoiceLineItem[];
  return platformInvoiceLineItems?.map((i) => getPlatformInvoiceTableRowData(i));
};


export const getFormattedDateForInvoicePeriod = (invoice: Invoice) => {
  if (invoice.billingCycle === BillingCycle.WEEKLY) {
    const endedAt = getLocalDate(invoice.periodEndedAt) as DateTime;
    const startedAt = getLocalDate(invoice.periodStartedAt) as DateTime;


    return `${startedAt.toFormat("LLL dd")} - ${endedAt.toFormat("LLL dd")}`;
  }

  if (invoice.billingCycle === BillingCycle.MONTHLY) {
    return getLocalFormattedDateAsMonthYear(invoice.periodStartedAt);
  }

  if (invoice.billingCycle === BillingCycle.ONE_OFF) {
    const startedAt = getLocalDate(invoice.periodStartedAt) as DateTime;

    return startedAt.toFormat("LLL dd");

  }


  return "-";

};