import React from "react";
import { SvgProps } from "types/svg.types";

type Props = {};

function InboxUnpaidInvoiceIcon({ height = 24, width = 24 }: SvgProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="12" fill="#FFE5EE" />
      <path
        d="M17.375 11H13C12.6022 11 12.2206 10.842 11.9393 10.5607C11.658 10.2794 11.5 9.89782 11.5 9.5V5.125C11.5 5.09185 11.4868 5.06005 11.4634 5.03661C11.4399 5.01317 11.4082 5 11.375 5H8.5C7.96957 5 7.46086 5.21071 7.08579 5.58579C6.71071 5.96086 6.5 6.46957 6.5 7V17C6.5 17.5304 6.71071 18.0391 7.08579 18.4142C7.46086 18.7893 7.96957 19 8.5 19H15.5C16.0304 19 16.5391 18.7893 16.9142 18.4142C17.2893 18.0391 17.5 17.5304 17.5 17V11.125C17.5 11.0918 17.4868 11.0601 17.4634 11.0366C17.4399 11.0132 17.4082 11 17.375 11ZM14.5 16H9.5C9.36739 16 9.24021 15.9473 9.14645 15.8536C9.05268 15.7598 9 15.6326 9 15.5C9 15.3674 9.05268 15.2402 9.14645 15.1464C9.24021 15.0527 9.36739 15 9.5 15H14.5C14.6326 15 14.7598 15.0527 14.8536 15.1464C14.9473 15.2402 15 15.3674 15 15.5C15 15.6326 14.9473 15.7598 14.8536 15.8536C14.7598 15.9473 14.6326 16 14.5 16ZM14.5 13.5H9.5C9.36739 13.5 9.24021 13.4473 9.14645 13.3536C9.05268 13.2598 9 13.1326 9 13C9 12.8674 9.05268 12.7402 9.14645 12.6464C9.24021 12.5527 9.36739 12.5 9.5 12.5H14.5C14.6326 12.5 14.7598 12.5527 14.8536 12.6464C14.9473 12.7402 15 12.8674 15 13C15 13.1326 14.9473 13.2598 14.8536 13.3536C14.7598 13.4473 14.6326 13.5 14.5 13.5Z"
        fill="#C4003E" />
      <path
        d="M17.1006 9.89407L12.6066 5.40001C12.5978 5.39132 12.5867 5.38541 12.5746 5.38302C12.5625 5.38063 12.55 5.38187 12.5386 5.38658C12.5272 5.39129 12.5175 5.39926 12.5106 5.40949C12.5037 5.41972 12.5001 5.43175 12.5 5.44407V9.50064C12.5 9.63324 12.5527 9.76042 12.6464 9.85419C12.7402 9.94796 12.8674 10.0006 13 10.0006H17.0566C17.0689 10.0006 17.0809 9.99689 17.0911 9.99002C17.1014 9.98315 17.1093 9.97341 17.1141 9.96202C17.1188 9.95063 17.12 9.9381 17.1176 9.92602C17.1152 9.91393 17.1093 9.90281 17.1006 9.89407Z"
        fill="#C4003E" />
    </svg>
  );
}

export default InboxUnpaidInvoiceIcon;
