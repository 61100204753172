import Col from "components/custom/Col";
import Row from "components/custom/Row";
import PasswordEyeHideIcon from "components/icon/icons/PasswordEyeHideIcon";
import PasswordEyeIcon from "components/icon/icons/PasswordEyeIcon";
import PinIcon from "components/icon/icons/PinIcon";
import BaseModal from "components/modal/BaseModal";
import { BlackOverlineText200, NeutralTitleSmallTextBlack } from "components/text/Text";
import TeslaControlledTooltipToggle from "components/vehicle-components/tesla/controls/TeslaControlledTooltipToggle";
import VehicleDetailsPinToDriveEditorForm
  from "components/vehicle-components/vehicle-details/VehicleDetailsPinToDriveEditorForm";
import { useTeslaControlsStatusReadonly } from "hooks/data/vehicle-data-accessor.hooks";

import { useState } from "react";
import { TeslaDrivePinHook, TeslaManagementMode } from "types/vehicle.types";

type Props = {
  teslaDrivePinHook: TeslaDrivePinHook
};

function TeslaPinToDriveEditor({ teslaDrivePinHook }: Props) {
  const { drivePin } = teslaDrivePinHook;

  const {
    isPinToDriveSupported
  } = useTeslaControlsStatusReadonly();


  const [isPinShown, setIsPinShown] = useState(false);
  const [isEditorModalOpen, setIsEditorModalOpen] = useState(false);

  const pin = drivePin?.drive.pin;
  const isEnabled = !!pin;
  const isSystemMode = drivePin?.drive.managementMode === TeslaManagementMode.SYSTEM;

  const isPinToDriveToggleSupported = !isSystemMode && isPinToDriveSupported;

  return (
    <>
      <BaseModal
        className="wide-xs"
        isCloseIconVisible
        isModalOpen={isEditorModalOpen}
        setIsModalOpen={setIsEditorModalOpen}
      >
        {isEditorModalOpen && (
          <VehicleDetailsPinToDriveEditorForm
            isDisableMode={isEnabled}
            onFormReset={() => setIsEditorModalOpen(false)}
          />
        )}
      </BaseModal>

      <Row className="justify-between align-items-center mt-2">
        <Col>
          <Row>
            <NeutralTitleSmallTextBlack>PIN to drive</NeutralTitleSmallTextBlack>
            <div className="ml-1">
              <PinIcon />
            </div>
          </Row>
          <Row className="align-items-center">
            {pin ? (
              <>
                {isPinShown ? pin : <>****</>}
                <div className="vertical-border-line-gray h-20px mx-1" />
                {isPinShown ? (
                  <PasswordEyeHideIcon className=" clickable" onClick={() => setIsPinShown(false)} />
                ) : (
                  <PasswordEyeIcon className=" clickable" onClick={() => setIsPinShown(true)} />
                )}
              </>
            ) : (
              "-"
            )}
          </Row>
        </Col>
        <div className="position-relative">
          <TeslaControlledTooltipToggle onToggle={() => setIsEditorModalOpen(true)}
                                        tooltipMessageOverride={isSystemMode ? "System mode is active." : undefined}
                                        isSupported={isPinToDriveToggleSupported}
                                        isChecked={isEnabled}
          />

        </div>
      </Row>

      {isSystemMode && (
        <BlackOverlineText200 className="mt-1">
          When system managed is enabled, pin to drive cannot be changed.
        </BlackOverlineText200>
      )}
    </>
  );
}

export default TeslaPinToDriveEditor;
