import { useDashboardInsightsAsync } from "hooks/data/dashboard-data-accessor.hooks";
import Col from "../custom/Col";
import Row from "../custom/Row";
import LiveVehicleLocator from "../vehicle-components/locator/LiveVehicleLocator";

import Card from "components/card/Card";
import { ErrorBoundary } from "react-error-boundary";
import DashboardInsightCard from "./DashboardInsightCard";
import DashboardChargerProviderGraph from "./dashboard-graphs/DashboardChargerProviderGraph";
import DashboardChargerTypeGraph from "./dashboard-graphs/DashboardChargerTypeGraph";
import DashboardCostGraph from "./dashboard-graphs/DashboardCostGraph";
import DashboardEnergyGraph from "./dashboard-graphs/DashboardEnergyGraph";
import DashboardLocationsGraph from "./dashboard-graphs/DashboardLocationsGraph";
import DashboardMostCostlyGroupsTable from "./dashboard-tables/DashboardMostCostlyGroupsTable";
import DashboardMostCostlyVehiclesTable from "./dashboard-tables/DashboardMostCostlyVehiclesTable";

export default function DashboardInsightCards() {
  // Observe insights
  useDashboardInsightsAsync();

  return (
    <Row className="g-4">
      <Col md="6">
        <DashboardInsightCard title={"Total Energy [ kWh ]"} graph={<DashboardEnergyGraph />} />
      </Col>
      <Col md="6">
        <DashboardInsightCard title={"Total Cost [ $ ]"} graph={<DashboardCostGraph />} />
      </Col>

      <Col md="6">
        <DashboardInsightCard
          isPieChart
          title={"Fast / Regular Charging"}
          description={"Proportional breakdown of AC and DC charging usage"}
          graph={<DashboardChargerTypeGraph />}
        />
      </Col>
      <Col md="6">
        <DashboardInsightCard
          isPieChart
          title={"Charger Network "}
          description={"Visual overview of charger network distribution"}
          graph={<DashboardChargerProviderGraph />}
        />
      </Col>

      <Col md="6">
        <DashboardInsightCard
          height={"h-300px"}
          title={"Most Costly Groups"}
          description={"Detailed overview of top expense groups"}
          graph={<DashboardMostCostlyGroupsTable />}
          isSkeletonHorizontalLoading
          tooltipText="Percentages consider all drivers' usage, but only fleet groups are shown."
        />
      </Col>

      <Col md="6">
        <DashboardInsightCard
          height={"h-300px"}
          title={"Most Costly Vehicles"}
          description={"Detailed insights into your top energy-consuming EVs"}
          graph={<DashboardMostCostlyVehiclesTable />}
          isSkeletonHorizontalLoading
        />
      </Col>

      <Col md="6">
        <DashboardInsightCard
          description={"Overview of top charging sites, ranked by the total number of charging sessions "}
          title={"Locations"}
          graph={<DashboardLocationsGraph />}
        />
      </Col>
      <Col md="6">
        {/**  <DashboardInsightCard graph={<DashboardSustainabilityScore />} /> */}
        <ErrorBoundary fallback={<></>}>
          <Card className={`h-350px d-flex flex-1 flex-grow  justify-center align-items-center`} padding="px-3">
            <LiveVehicleLocator />
          </Card>
        </ErrorBoundary>
      </Col>
    </Row>
  );
}
