import VehicleDetailsAboutContent
  from "components/vehicle-components/vehicle-details/contents/VehicleDetailsAboutContent";
import VehicleDetailsAssignmentsContent
  from "components/vehicle-components/vehicle-details/contents/VehicleDetailsAssignmentsContent";
import VehicleDetailsChargingContent
  from "components/vehicle-components/vehicle-details/contents/VehicleDetailsChargingContent";
import VehicleDetailsOverviewContent
  from "components/vehicle-components/vehicle-details/contents/VehicleDetailsOverviewContent";
import VehicleDetailsControlContent
  from "components/vehicle-components/vehicle-details/contents/VehicleDetailsTeslaControlsContent";
import VehicleDetailsHeader from "components/vehicle-components/vehicle-details/VehicleDetailsHeader";
import VehicleDetailsTab from "components/vehicle-components/vehicle-details/VehicleDetailsTab";
import { useTeslaInfoAsync, useVehicleAsync } from "hooks/data/vehicle-data-accessor.hooks";
import { useVehicleDriverSchedulesAsync } from "hooks/data/vehicle-driver-schedule-data-accessor.hooks";
import Content from "layout/content/Content";
import Head from "layout/head/Head";
import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Route, Switch, useRouteMatch } from "react-router";
import { getVehicleConnectionStatus } from "utils/vehicle.utils";
import NotFoundContent from "../../components/not-found/NotFoundContent";
import VehicleDetailsAlertsContent
  from "../../components/vehicle-components/vehicle-details/contents/VehicleDetailsAlertsContent";

type Props = {};

function VehicleDetailsPage(props: Props) {
  const { path } = useRouteMatch();

  const vehicleHook = useVehicleAsync();

  const vehicleConnectionStatus = getVehicleConnectionStatus(vehicleHook.vehicle);

  const vehicleDriverSchedulesHook = useVehicleDriverSchedulesAsync();


  if (!vehicleHook.isLoading && !vehicleHook.vehicle) {
    return <>
      <Head title={"Vehicle Details"}></Head>
      <NotFoundContent />
    </>;
  }


  return (
    <ErrorBoundary fallback={<></>}>
      <Head title={"Vehicle Details"}></Head>
      <Content>
        <VehicleDetailsHeader vehicleDriverSchedulesHook={vehicleDriverSchedulesHook}
                              vehicleHook={vehicleHook}
        />
        <ErrorBoundary fallback={<></>}>
          <VehicleDetailsTab
            isLoading={vehicleHook.isLoading}
            isTeslaConnected={vehicleConnectionStatus.isTeslaConnected} />
          <Switch>
            <Route exact path={`${path}/about`}
                   render={() => <VehicleDetailsAboutContent
                     vehicleHook={vehicleHook}
                   />
                   }
            />
            <Route exact path={`${path}/charging`} component={VehicleDetailsChargingContent} />
            <Route exact path={`${path}/assignments`}
                   render={() =>
                     <VehicleDetailsAssignmentsContent
                       vehicleDriverSchedulesHook={vehicleDriverSchedulesHook}
                     />
                   }
            />
            <Route exact path={`${path}/controls`}
                   render={() =>
                     <VehicleDetailsControlContent
                       vehicleHook={vehicleHook}
                     />
                   }
            />

            <Route path={`${path}/alerts`}
                   render={() =>
                     <VehicleDetailsAlertsContent
                       isTeslaConnected={vehicleConnectionStatus.isTeslaConnected}
                     />
                   }
            />

            <Route path={`${path}`}
                   render={() =>
                     <VehicleDetailsOverviewContent
                       vehicleDriverSchedulesHook={vehicleDriverSchedulesHook}
                       vehicleHook={vehicleHook}
                     />}
            />


          </Switch>

        </ErrorBoundary>


      </Content>
    </ErrorBoundary>
  );
}

export default VehicleDetailsPage;


/*
*  <VehicleDetailsChargingInsights />
        <VehicleChargingSessionsTable />*/