import React from "react";
import Col from "../../../custom/Col";
import Row from "../../../custom/Row";
import VehicleDetailsAlertList from "../VehicleDetailsAlertList";
import VehicleDetailsOverviewCard from "../VehicleDetailsOverviewCard";


type Props = {
  isTeslaConnected: boolean
}

function VehicleDetailsAboutContent({ isTeslaConnected }: Props) {
  if (!isTeslaConnected) {
    return <></>;
  }

  return (
    <Row>
      <Col lg={7} className="pr-lg-3">
        <VehicleDetailsOverviewCard title={"Active alerts"}
                                    content={<VehicleDetailsAlertList />} />

      </Col>
    </Row>
  );
}

export default VehicleDetailsAboutContent;