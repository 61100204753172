import React, { useCallback } from "react";
import { useHistory } from "react-router";
import { TextButtonMode } from "./TextButton";
import HoverUnderlinedTextButton from "./HoverUnderlinedTextButton";

type Props = {
  readonly vehicleId: string;
};

function AssignDriverLinkButton({ vehicleId }: Props) {
  /**
   * Hooks
   */
  const history = useHistory();

  return (
    <HoverUnderlinedTextButton
      href={`/assign-driver/${vehicleId}`}
      mode={TextButtonMode.BLUE}
      text={"Assign Driver"}
    />
  );
}

export default AssignDriverLinkButton;
