import IosSwitch, { IosSwitchProps } from "components/input/switch/IosSwitch";
import MuiTooltip from "components/tooltip/MuiTooltip";
import { useTeslaInfoReadonly } from "hooks/data/vehicle-data-accessor.hooks";
import React from "react";

type Props = IosSwitchProps & {
  isSupported: boolean;
  tooltipMessageOverride?: string
}
const TeslaControlledTooltipToggle = ({ isSupported, tooltipMessageOverride, ...props }: Props) => {
  const { isSleeping } = useTeslaInfoReadonly();

  const getTooltipMessage = () => {
    if (!isSupported) return tooltipMessageOverride ?? "Virtual key must be added.";
    if (isSleeping) return tooltipMessageOverride ?? "Vehicle needs to wake up.";
    return null;
  };

  const tooltipMessage = getTooltipMessage();


  if (!tooltipMessage) {
    return <IosSwitch
      {...props}
    />;
  }

  return (
    <MuiTooltip title={tooltipMessage} placement="top">
      <div>
        <IosSwitch
          isDisabled={true}
          {...props}
        />
      </div>
    </MuiTooltip>
  );
};

export default TeslaControlledTooltipToggle;