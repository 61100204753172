import CancelButton from "components/button/CancelButton";
import PrimaryButton from "components/button/PrimaryButton";
import AlertConfirmDialog from "components/notification/AlertConfirmDialog";
import TeslaBasicControlsButton from "components/vehicle-components/tesla/controls/TeslaBasicControlsButton";
import VehicleDetailsPageCardBlock from "components/vehicle-components/vehicle-details/VehicleDetailsPageCardBlock";
import { useTeslaCommandHandlersAsync } from "hooks/data/vehicle-data-accessor.hooks";
import React from "react";
import { TeslaCommand, TeslaDataAccessorHook } from "types/vehicle.types";

type Props = {
  teslaHook: TeslaDataAccessorHook;
};

function TeslaRemoteStartEditor({ teslaHook }: Props) {
  const {
    handleSubmitBasicCommandAsync,

    isConfirmDialogOpen,
    setIsConfirmDialogOpen,
    isSubmittingByCommand
  } = useTeslaCommandHandlersAsync();



  return (
    <>
      <AlertConfirmDialog
        isDialogOpen={isConfirmDialogOpen}
        overrideButtons={
          <>
            <div className="mr-2">
              <CancelButton onClick={() => setIsConfirmDialogOpen(false)} />
            </div>

            <PrimaryButton
              type="button"
              text={"Start Vehicle"}
              isLoading={isSubmittingByCommand(TeslaCommand.REMOTE_START)}
              onClick={() => handleSubmitBasicCommandAsync(TeslaCommand.REMOTE_START)}
            />
          </>
        }
        toggleIsDialogOpen={() => setIsConfirmDialogOpen(false)}
        title={`Are you sure you want to start this vehicle?`}
      />

      <VehicleDetailsPageCardBlock
        isBorderBottomVisible={false}
        paddingClass={"pt-3 pb-1"}
        isLoading={teslaHook.isLoading}
        leftText={"Start vehicle"}
        rightTextOverwrite={
          <TeslaBasicControlsButton
            isAdditionallySupported={teslaHook.isRemoteStartSupported}
            tooltipMessageOverride={teslaHook.isRemoteStartSupported === false ? "Remote start control is not supported!" : undefined}
            text={"Start vehicle"}
            isLoading={isSubmittingByCommand(TeslaCommand.REMOTE_START)}
            onClick={() => setIsConfirmDialogOpen(true)}
          />
        }
      />
    </>
  );
}

export default TeslaRemoteStartEditor;
