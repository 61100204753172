export const defaultErrorMessage = "Something went wrong";

const handleErrors = (errors) => {
  let errorMessage = "";

  for (const error of errors) {
    if (error?.keyword === "required") {
      errorMessage = error.message;
      break;
    }
  }
  return errorMessage;
};


export const handleApiErrorResponse = (err) => {
  let errorMessage = "";

  if (err.errors && Array.isArray(err.errors)) {
    errorMessage = handleErrors(err.errors);
  } else {
    errorMessage = err.response?.data?.message ?? defaultErrorMessage;
  }

  return errorMessage;
};


export const handleAwsErrorResponse = (err) => {
  let errorMessage = "";

  if (err.errors && Array.isArray(err.errors)) {
    errorMessage = handleErrors(err.errors);
  } else {
    errorMessage = err.response?.data?.message ?? err.response?.message ?? defaultErrorMessage;
  }

  return errorMessage;
};