import { useSessionStatisticsByPeriodAsync } from "hooks/data/charging-data-accessor.hooks";
import { useDashboardOverviewsAsync } from "hooks/data/dashboard-data-accessor.hooks";
import { ErrorBoundary } from "react-error-boundary";
import {
  getMonthLabelForStatisticGraphByStatisticData,
  getSessionStatisticDataForGraphAsWeekOfLastQuarter
} from "../../utils/charging.utils";
import Col from "../custom/Col";
import Row from "../custom/Row";
import ChargingProviderGraph from "./ChargingProviderGraph";
import TotalChargeCard from "./TotalChargeCard";
import TotalCostCard from "./TotalCostCard";

export default function ChargingSessionsStats() {
  useDashboardOverviewsAsync();

  const { sessionStatistics, isLoading: isSessionStatisticsLoading } = useSessionStatisticsByPeriodAsync();

  const chargingSessionStatisticsForGraph = getSessionStatisticDataForGraphAsWeekOfLastQuarter(sessionStatistics);
  const { monthlyLabels, uniquePeriods } = getMonthLabelForStatisticGraphByStatisticData(sessionStatistics);

  return (
    <Row className="mb-4 justify-between">
      <Col md="4" className="justify-between flex-column " spacing={3}>
        <Col className="pb-2">
          <TotalCostCard />
        </Col>

        <Col className="pt-2 mb-4 mb-lg-0">
          <TotalChargeCard />
        </Col>
      </Col>
      <ErrorBoundary fallback={<>An error ocurred</>}>
        <Col md="8" className="flex-1 pl-md-4 mt-xs-2  mt-lg-0">
          <ChargingProviderGraph
            labels={monthlyLabels}
            uniquePeriods={uniquePeriods}
            data={chargingSessionStatisticsForGraph}
            isLoading={isSessionStatisticsLoading}
          />
        </Col>
      </ErrorBoundary>
    </Row>
  );
}
