import React from "react";
import { SvgProps } from "types/svg.types";

type Props = {};

function InboxNewSignupIcon({ height = 24, width = 24 }: SvgProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="12" fill="#FFEFE6" />
      <path
        d="M7.66668 12.6666C8.10883 12.6666 8.53217 12.4902 8.84498 12.1774C9.15778 11.8646 9.33417 11.4413 9.33417 10.9991C9.33417 10.557 9.15778 10.1336 8.84498 9.82083C8.53217 9.50803 8.10883 9.33398 7.66668 9.33398C7.22452 9.33398 6.80117 9.51038 6.48837 9.82083C6.17557 10.1336 5.99917 10.557 5.99917 10.9991C5.99917 11.4413 6.17557 11.8646 6.48837 12.1774C6.80117 12.4902 7.22452 12.6666 7.66668 12.6666ZM18.0009 10.9991C18.0009 11.4413 17.8245 11.8646 17.5117 12.1774C17.1989 12.4902 16.7756 12.6666 16.3334 12.6666C15.8913 12.6666 15.4679 12.4902 15.1551 12.1774C14.8423 11.8646 14.6659 11.4413 14.6659 10.9991C14.6659 10.557 14.8423 10.1336 15.1551 9.82083C15.4679 9.50803 15.8913 9.33398 16.3334 9.33398C16.7756 9.33398 17.1989 9.51038 17.5117 9.82083C17.8245 10.1336 18.0009 10.557 18.0009 10.9991ZM7.33506 14.6657C7.33506 14.2048 7.40091 13.7579 7.52791 13.3369L7.41502 13.3463C6.84351 13.4098 6.31433 13.6803 5.93097 14.1083C5.54761 14.5364 5.33594 15.0914 5.33594 15.6653V18.6663H7.33506V14.6657ZM18.6689 18.6663V15.6653C18.6689 15.0703 18.4431 14.4988 18.0339 14.066C17.627 13.6333 17.0696 13.3722 16.4769 13.3369C16.6016 13.7579 16.6698 14.2048 16.6698 14.6657V18.6663H18.6689Z"
        fill="#FE5D02" />
      <path
        d="M11.9988 6.27426C12.4598 6.27426 12.8926 6.453 13.2195 6.77992C13.5417 7.10213 13.7251 7.54664 13.7251 8.00055C13.7251 8.45447 13.5417 8.89898 13.2195 9.22119C12.8949 9.54811 12.4598 9.72685 11.9988 9.72685C11.5379 9.72685 11.1051 9.54811 10.7782 9.22119C10.456 8.89898 10.2725 8.45447 10.2725 8.00055C10.2725 7.54664 10.456 7.10213 10.7782 6.77992C11.1028 6.453 11.5379 6.27426 11.9988 6.27426ZM11.9988 5.3335C11.2909 5.3335 10.6136 5.61337 10.1126 6.11433C9.61167 6.61528 9.33179 7.29263 9.33179 8.00055C9.33179 8.70848 9.61167 9.38583 10.1126 9.88678C10.6136 10.3877 11.2909 10.6676 11.9988 10.6676C12.7068 10.6676 13.3841 10.3877 13.8851 9.88678C14.386 9.38583 14.6659 8.70848 14.6659 8.00055C14.6659 7.29263 14.386 6.61528 13.8851 6.11433C13.3841 5.61337 12.7068 5.3335 11.9988 5.3335Z"
        fill="#FE5D02" />
      <path
        d="M11.9988 12.2738C12.6385 12.2738 13.2383 12.5231 13.6898 12.9746C14.1343 13.4191 14.3907 14.0377 14.3907 14.6657V17.7255H9.60458V14.6657C9.60458 14.0353 9.86094 13.4191 10.3054 12.9746C10.757 12.5231 11.3591 12.2738 11.9965 12.2738M11.9965 11.333C11.1121 11.333 10.2655 11.6834 9.63986 12.309C9.01425 12.9347 8.66382 13.7837 8.66382 14.6657V18.6662H15.3315V14.6657C15.3315 13.7813 14.981 12.9347 14.3554 12.309C13.7298 11.6834 12.8831 11.333 11.9988 11.333H11.9965Z"
        fill="#FE5D02" />
    </svg>
  );
}

export default InboxNewSignupIcon;
