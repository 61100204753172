import restApiClient from "./client";
import { generateApiEndpoint } from "utils/api.utils";
import { DashboardInsightFilter, DashboardInsights, DashboardOverviews } from "types/dashboard.types";
import { ApiResponse } from "types/api.types";
import { TotalEnergyAndCostByPeriodAndProvider } from "types/charging-sessions.types";

///admin/organization/dashboard/insights

const serviceName = "organization/dashboard";

/**
 * GET: '/api/admin/organization/dashboard/insights'
 */
export async function getDashboardInsightsByFilterAsync(filter: DashboardInsightFilter): Promise<ApiResponse<DashboardInsights>> {
  const endpoint = generateApiEndpoint(`${serviceName}/insights?filter=${filter}`);
  const response = await restApiClient.get(endpoint);
  return response.data;
}


/**
 * GET: '/api/admin/organization/dashboard/overviews'
 */
export async function getDashboardOverviewsAsync(): Promise<ApiResponse<DashboardOverviews>> {
  const endpoint = generateApiEndpoint("organization/dashboard/overview");
  const response = await restApiClient.get(endpoint);

  return response.data;
}


/**
 * GET: '/api/admin/organization/dashboard/weekly-stats'
 */
export async function getWeeklySessionStatisticsAsync(): Promise<ApiResponse<TotalEnergyAndCostByPeriodAndProvider[]>> {
  const endpoint = generateApiEndpoint("organization/dashboard/weekly-stats");
  const response = await restApiClient.get(endpoint);

  return response.data;
}







