import React from "react";
import { SvgProps } from "types/svg.types";

type Props = {};

function InboxSuspiciousChargingIcon({ height = 24, width = 24 }: SvgProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="12" fill="#FFE5EE" />
      <path
        d="M14.408 6.12443C14.4805 6.16645 14.5366 6.23182 14.5672 6.30985C14.5977 6.38788 14.6009 6.47396 14.5762 6.55403L13.2422 10.8886H15.7035C15.7758 10.8886 15.8466 10.9097 15.907 10.9494C15.9675 10.9892 16.015 11.0457 16.0437 11.1121C16.0723 11.1785 16.0809 11.2519 16.0684 11.3231C16.0559 11.3944 16.0227 11.4604 15.9731 11.513L10.0475 17.8089C9.9902 17.8699 9.9139 17.9097 9.83108 17.9217C9.74826 17.9338 9.6638 17.9174 9.59146 17.8753C9.51913 17.8332 9.46318 17.7679 9.43275 17.6899C9.40231 17.6119 9.39918 17.526 9.42386 17.446L10.7579 13.1107H8.29652C8.22419 13.1107 8.15343 13.0896 8.09298 13.0498C8.03253 13.0101 7.98504 12.9536 7.95637 12.8872C7.92769 12.8207 7.91909 12.7474 7.93163 12.6762C7.94417 12.6049 7.97729 12.5389 8.02691 12.4863L13.9525 6.19035C14.0098 6.12946 14.0859 6.08974 14.1686 6.07763C14.2513 6.06553 14.3357 6.08176 14.408 6.12368V6.12443Z"
        fill="#C4003E" />
    </svg>

  );
}

export default InboxSuspiciousChargingIcon;
