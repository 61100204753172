import { DriverLocationPayload } from "../types/driver.types";
import { LatLng, LocationRectangleBounds } from "../types/location.types";

export const getPolygonLocationByRectangleBoundsAndName = (rectangleBounds: LocationRectangleBounds, name: string): DriverLocationPayload => {
  return {
    "name": name,
    "polygon": {
      "type": "Polygon",
      "coordinates": [
        [
          [rectangleBounds.west, rectangleBounds.north], // Top-left
          [rectangleBounds.east, rectangleBounds.north], // Top-right
          [rectangleBounds.east, rectangleBounds.south], // Bottom-right
          [rectangleBounds.west, rectangleBounds.south], // Bottom-left
          [rectangleBounds.west, rectangleBounds.north]  // Close the loop back to top-left
        ]
      ]
    }
  };
};


export const getRectangleBoundsAndCenter = (coordinates?: number[][][]): {
  bounds: LocationRectangleBounds;
  center: LatLng
} | undefined => {

  if (!coordinates) {
    return;
  }

  if (coordinates && (coordinates?.length === 0 || coordinates[0]?.length === 0)) {
    return;
  }

  // Flatten the coordinates array
  const flatCoordinates = coordinates[0];

  const latitudes = flatCoordinates.map(coord => coord[1]);
  const longitudes = flatCoordinates.map(coord => coord[0]);

  const north = Math.max(...latitudes);
  const south = Math.min(...latitudes);
  const east = Math.max(...longitudes);
  const west = Math.min(...longitudes);

  const bounds: LocationRectangleBounds = { north, south, east, west };

  const center: LatLng = {
    lat: (north + south) / 2,
    lng: (east + west) / 2
  };

  return { bounds, center };
};


export const calculatePolygonCenter = (path: LatLng[]): LatLng => {
  const latSum = path.reduce((sum, point) => sum + point.lat, 0);
  const lngSum = path.reduce((sum, point) => sum + point.lng, 0);
  return {
    lat: latSum / path.length,
    lng: lngSum / path.length
  };
};


export const calculateRectangleArea = (bounds: LocationRectangleBounds): number => {
  const earthRadius = 6378137; // Earth's radius in meters

  const latDistance = (bounds.north - bounds.south) * (Math.PI / 180) * earthRadius;
  const lngDistance = (bounds.east - bounds.west) * (Math.PI / 180) * earthRadius * Math.cos(((bounds.north + bounds.south) / 2) * (Math.PI / 180));

  return Math.abs(latDistance * lngDistance);
};

export const getZoomLevel = (area: number): number => {
  if (area <= 300) return 20;
  if (area <= 3000) return 19;
  if (area <= 30000) return 18;
  if (area <= 300000) return 17;
  if (area <= 3000000) return 16;
  if (area <= 30000000) return 15;
  if (area <= 300000000) return 14;
  if (area <= 3000000000) return 13;
  if (area <= 30000000000) return 12;
  return 11; // Adjust this as needed for larger areas
};

export const calculateZoomForRectangle = (bounds: LocationRectangleBounds): number => {
  const area = calculateRectangleArea(bounds);
  return getZoomLevel(area);
};