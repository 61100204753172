import { TeslaControlCapability, TeslaControlsStatus } from "types/tesla.types";
import { TeslaCommand, TeslaVehicleInfo } from "../types/vehicle.types";

export const getTeslaCommandAlertSuccessMessage = (command: TeslaCommand) => {
  switch (command) {
    case TeslaCommand.HONK_HORN:
      return "Honk horn command is successfully sent";
    case TeslaCommand.FLASH_LIGHTS:
      return "Flash light command is successfully sent";
    case TeslaCommand.ACTUATE_REAR_TRUNK:
      return "Trunk command is successfully sent";
    case TeslaCommand.ACTUATE_FRONT_TRUNK:
      return "Frunk command is successfully sent";
    case TeslaCommand.UNLOCK:
      return "Vehicle unlocked successfully.";
    case TeslaCommand.LOCK:
      return "Vehicle locked successfully.";
    case TeslaCommand.CLOSE_CHARGE_PORT:
      return "Charge port closed successfully";
    case TeslaCommand.OPEN_CHARGE_PORT:
      return "Charge port opened successfully";
    case TeslaCommand.REMOTE_START:
      return "Vehicle started successfully.";
    case TeslaCommand.ENABLE_SYSTEM_MANAGED_DRIVE_PIN:
      return "System managed drive pin is enabled.";
    case TeslaCommand.DISABLE_SYSTEM_MANAGED_DRIVE_PIN:
      return "System managed drive pin is disabled.";
    case TeslaCommand.ENABLE_ANTI_THEFT :
      return "Anti-Theft Mode has been successfully enabled.";
    case TeslaCommand.DISABLE_ANTI_THEFT :
      return "Anti-Theft Mode has been successfully disabled.";
    default:
      return "Command is successfully updated";
  }
};

export const getTeslaControlsStatusExtras = (teslaInfo?: TeslaVehicleInfo | null) => {
  const remoteStart = teslaInfo?.remoteStart;
  const isRemoteStartSupported = remoteStart?.supported && remoteStart?.enabled;
  const areTrunkControlsSupported = teslaInfo?.trunks.canActuate;
  return { isRemoteStartSupported, areTrunkControlsSupported };
};

export const getTeslaControlsStatusKeyDetails = (controlsStatus: TeslaControlsStatus | null) => {
  const isKeySupported = controlsStatus?.key.supported;
  const isKeyPaired = controlsStatus?.key.paired;

  const isKeyRequired = isKeySupported && !isKeyPaired;

  const pinToDriverControls = controlsStatus?.controls.pinToDrive;
  const isPinToDriveSupported = pinToDriverControls === TeslaControlCapability.SUPPORTED;
  const isPinToDriverKeyRequired = pinToDriverControls === TeslaControlCapability.KEY_REQUIRED;
  const isPinToDriverNotSupported = pinToDriverControls === TeslaControlCapability.NOT_SUPPORTED;

  const basicControls = controlsStatus?.controls.basic;
  const isBasicControlsSupported = basicControls === TeslaControlCapability.SUPPORTED;
  const isBasicControlsKeyRequired = basicControls === TeslaControlCapability.KEY_REQUIRED;


  const antiTheftControls = controlsStatus?.controls.antiTheft;
  const isAntiTheftControlsSupported = antiTheftControls === TeslaControlCapability.SUPPORTED;
  const isAntiTheftControlsKeyRequired = antiTheftControls === TeslaControlCapability.KEY_REQUIRED;


  return {
    isKeySupported,
    isKeyPaired,
    isKeyRequired,
    isPinToDriveSupported,
    isPinToDriverKeyRequired,
    isPinToDriverNotSupported,
    isBasicControlsSupported,
    isBasicControlsKeyRequired,
    isAntiTheftControlsSupported,
    isAntiTheftControlsKeyRequired,
    firmwareVersion: controlsStatus?.key.firmwareVersion
  };
};
