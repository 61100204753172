// React
import { SyntheticEvent, useCallback, useState } from "react";
import { useHistory } from "react-router";

// Hooks & Utils
import { useTranslation } from "react-i18next";
import { handleApiErrorResponse } from "utils/error.utils";

// Types
import { AWSSignInErrorTypes } from "types/auth.types";

// Third party
import { Auth } from "aws-amplify";
import { useForm } from "react-hook-form";

// Components
import { useAlertManager } from "hooks/alert.hooks";
import ForgotYourPasswordTextLink from "../button/ForgotYourPasswordTextLink";
import SignInButton from "../button/SignInButton";
import SignUpTextLink from "../button/SignUpTextLink";
import Row from "../custom/Row";
import { BlackBodyText200, BlackH3Text100 } from "../text/Text";
import AuthBluedotFleetWordMark from "./form-components/AuthBluedotFleetWordMark";
import AuthFormInput from "./form-components/AuthFormInput";
import AuthPasswordInput from "./form-components/AuthPasswordInput";

// Images & Icons

type Props = Record<string, unknown>;

type LoginFormValues = {
  email: string;
  password: string;
};

function LoginForm(props: Props) {
  const { t } = useTranslation("common");
  const { handleOpenErrorAlert } = useAlertManager();

  const { register, handleSubmit, trigger } = useForm<LoginFormValues>({
    mode: "all",
  });

  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);

  /**
   *
   */
  const handleUserNotConfirmedException = useCallback(
    (email: string) => {
      const urlToRedirect = `${process.env.PUBLIC_URL}/auth/unconfirmed-user`;
      history.push(urlToRedirect, { email });
    },
    [history]
  );

  /**
   *
   */
  const loginAsync = useCallback(
    async (values: LoginFormValues) => {
      try {
        setIsLoading(true);
        await Auth.signIn({ ...values, username: values.email });
      } catch (error: any) {
        if (error?.code === AWSSignInErrorTypes.UserNotConfirmedException) {
          handleUserNotConfirmedException(values.email);
        }

        handleOpenErrorAlert(handleApiErrorResponse(error));
      } finally {
        setIsLoading(false);
      }
    },
    [handleOpenErrorAlert, handleUserNotConfirmedException]
  );

  /**
   *
   */
  const handleFormSubmit = useCallback(
    async (e: SyntheticEvent<HTMLFormElement, SubmitEvent>) => {
      e.preventDefault();

      await trigger();

      handleSubmit(loginAsync)(e);
    },
    [handleSubmit, loginAsync, trigger]
  );

  return (
    <form onSubmit={handleFormSubmit} className="auth-form-container">
      <AuthBluedotFleetWordMark />

      <BlackH3Text100 className="py-3">{t("auth.sign_in.flow_header")}</BlackH3Text100>
      <BlackBodyText200 className="mb-4">{t("auth.sign_in.flow_description")}</BlackBodyText200>
      <AuthFormInput
        register={register}
        inputMode="email"
        placeholder="Enter your email"
        label="Email"
        required
        name="email"
        type="email"
        className="w-max-100 w-100"
      />

      <AuthPasswordInput register={register} className="w-max-100 w-100" />
      <ForgotYourPasswordTextLink />

      <SignInButton isLoading={isLoading} isDisabled={isLoading} type="submit" className="w-100 mt-4" />

      <Row className="center mt-4">
        <BlackBodyText200 className="mr-1">Don’t have an account?</BlackBodyText200>
        <SignUpTextLink />
      </Row>
    </form>
  );
}

export default LoginForm;
