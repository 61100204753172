import Col from "components/custom/Col";
import InputControllerWithValidationError from "components/form/InputControllerWithValidationError";
import EditorModal from "components/modal/EditorModal";
import AlertGotItDialog from "components/notification/AlertGotItDialog";
import { useAuthStatus } from "hooks/auth.hooks";
import { useAppDispatch } from "hooks/redux.hooks";
import React, { SyntheticEvent, useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { updateOrganizationBusinessName } from "redux/slices/userSlice";
import { updateOrganizationInfoAsync } from "services/organization.services";
import { OrganizationProfileCommand } from "../../../types/organization.types";
import Row from "../../custom/Row";
import OutlinedButtonWithIcon from "../../button/OutlinedButtonWithIcon";
import PencilIcon from "../../icon/icons/PencilIcon";
import RowBetweenBlock from "../../block/RowBetweenBlock";
import { BlackBodyText100 } from "../../text/Text";

type Props = {};

type CompanyNameEditorFromValues = {
  businessName: string;
};

function SettingsPageCompanyNameEditor({}: Props) {
  const [isFormDialogOpen, setIsFormDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);

  const { user } = useAuthStatus();

  const dispatch = useAppDispatch();

  const { register, handleSubmit, errors, trigger, control, reset } = useForm<CompanyNameEditorFromValues>({
    mode: "all",
    defaultValues: {
      businessName: user?.businessName
    }
  });

  /**
   *
   */
  const updateOrganizationAsyncCallback = useCallback(
    async (values: CompanyNameEditorFromValues) => {
      if (!user) {
        return;
      }
      try {
        setIsLoading(true);

        const payload = {
          businessName: values.businessName
        };

        await updateOrganizationInfoAsync({
          command: OrganizationProfileCommand.UPDATE_BUSINESS_NAME,
          businessName: values.businessName
        });

        dispatch(updateOrganizationBusinessName(payload.businessName));
        reset(payload);
        setIsFormDialogOpen(false);
        setIsSuccessDialogOpen(true);
      } catch (error: any) {
        // TODO: New Alert notification
        // setErrorMessage(handleApiErrorResponse(error));
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch, reset, user]
  );

  /**
   *
   */
  const handleFormSubmit = useCallback(
    async (e: SyntheticEvent<HTMLFormElement, SubmitEvent>) => {
      e.preventDefault();

      await trigger();

      handleSubmit(updateOrganizationAsyncCallback)(e);
    },
    [handleSubmit, updateOrganizationAsyncCallback, trigger]
  );

  return (
    <>
      <AlertGotItDialog
        isDialogOpen={isSuccessDialogOpen}
        title="Success!"
        description="Company name has been updated successfully."
        onGotItButtonPressed={() => {
          setIsSuccessDialogOpen(false);
        }}
      />

      <EditorModal
        setIsModalOpen={setIsFormDialogOpen}
        isLoading={isLoading}
        isModalOpen={isFormDialogOpen}
        onSubmit={handleFormSubmit}
        isCloseIconVisible={false}
        title={"Company name"}
      >
        <Col className="w-max-100 w-100 mt-4">
          <InputControllerWithValidationError
            defaultValue={user?.businessName}
            register={register}
            name="businessName"
            type="text"
            placeholder={"Enter the company name"}
            errors={errors}
            control={control}
            registerRef={register({
              required: "Company name is required"
            })}
          />
        </Col>
      </EditorModal>


      <RowBetweenBlock isBorderBottomVisible={false} leftText="Company name"
                       rightOverwrite={
                         <Row className={'align-items-center'}>
                           <BlackBodyText100>{user?.businessName ?? "-"}</BlackBodyText100>
                           <OutlinedButtonWithIcon className="ml-2 clickable"
                                                   leftIcon={<PencilIcon />}
                                                   onClick={() => setIsFormDialogOpen(true)} />

                         </Row>

                       }

      />


    </>
  );
}

export default SettingsPageCompanyNameEditor;
