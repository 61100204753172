

export const geocodeAddressAsync = async (address: string) => {
  const geocoder = new google.maps.Geocoder();
  return new Promise<google.maps.GeocoderResult>((resolve, reject) => {
    geocoder.geocode({ address }, (results, status) => {
      if (status === "OK" && results && results[0]) {
        resolve(results[0]);
      } else {
        console.error(`Geocode Address Error: ${status}`);
        reject(new Error(`Geocode failed with status: ${status}`));
      }
    });
  });
};

export const geocodeLocationAsync = async (lat: number, lng: number) => {
  const geocoder = new google.maps.Geocoder();
  const location = new google.maps.LatLng(lat, lng);

  return new Promise<google.maps.GeocoderResult>((resolve, reject) => {
    geocoder.geocode({ location }, (results, status) => {
      if (status === "OK" && results && results[0]) {
        resolve(results[0]);
      } else {
        console.error(`Geocode Location Error: ${status}`);
        reject(new Error(`Geocode failed with status: ${status}`));
      }
    });
  });
};