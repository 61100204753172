import { useMemo } from "react";
import { VehicleConnectionState } from "types/vehicle.types";
import { ConnectionStatus } from "../../types/charging-sessions.types";
import Badge from "./Badge";

type Props = {
  readonly connectionStatus: ConnectionStatus | VehicleConnectionState;
};

function ConnectionStatusBadge({ connectionStatus }: Props) {

  const badgeProps = useMemo(() => {
    const commonContainerStyle = "px-2 rounded-xxxl center";

    const connectedBadgeProps = {
      text: "Connected",
      textStyle: `text-positive-200`,
      containerStyle: `bg-positive-950 ${commonContainerStyle}`,
    };

    switch (connectionStatus) {
      case ConnectionStatus.CONNECTED:
        return connectedBadgeProps;

      case ConnectionStatus.DISCONNECTED:
        return {
          text: "Disconnected",
          textStyle: `text-cherry-200 `,
          containerStyle: `bg-cherry-1000 ${commonContainerStyle}`
        };

      case ConnectionStatus.NOT_CONNECTED:
        return {
          text: "Not connected",
          textStyle: "text-neutral-200",
          containerStyle: `bg-neutral-900 ${commonContainerStyle}`
        };
      default:
        return  {
          text: "Unknown status",
          textStyle: "text-neutral-200",
          containerStyle: `bg-neutral-900 ${commonContainerStyle}`
        };
    }
  }, [connectionStatus]);

  return <Badge {...badgeProps} />;
}

export default ConnectionStatusBadge;
