import React from "react";

type Props = {};

function DepotLocationsMenuIconFilled({}: Props) {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_36958_21866)">
        <path
          d="M16.6517 11.1112C18.9513 12.9566 20.2302 14.5878 19.7888 15.3515C19.0915 16.5566 14.3677 15.1374 9.23799 12.1816C4.1083 9.2257 0.51455 5.85265 1.21143 4.64796C1.64736 3.89484 3.65596 4.16671 6.35361 5.19835"
          stroke="#7A8590" strokeWidth="1.5" strokeMiterlimit="10" />
        <path
          d="M10.5 16.25C13.9518 16.25 16.75 13.4518 16.75 10C16.75 6.54822 13.9518 3.75 10.5 3.75C7.04822 3.75 4.25 6.54822 4.25 10C4.25 13.4518 7.04822 16.25 10.5 16.25Z"
          stroke="#7A8590" strokeWidth="1.5" strokeMiterlimit="10" />
      </g>
      <defs>
        <clipPath id="clip0_36958_21866">
          <rect width="20" height="20" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default DepotLocationsMenuIconFilled;
