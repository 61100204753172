import CircularLoadingSpinner from "components/loading/CircularLoadingSpinner";
import { useSamsaraConnection } from "hooks/vehicle.hooks";
import { useEffect } from "react";

type Props = {};

function SamsaraRedirectionPage({}: Props) {
  const { handleForwardCredentialsToOriginTab } = useSamsaraConnection();
  useEffect(() => {
    handleForwardCredentialsToOriginTab();
  }, [handleForwardCredentialsToOriginTab]);

  return <CircularLoadingSpinner />;
}

export default SamsaraRedirectionPage;
