import React from 'react';
import Select from "react-select";

const RSelect = ({ ...props }) => {
  return (
    <div className="">
      <Select
        className={`react-select-container ${props.className ? props.className : ""}`}
        classNamePrefix="react-select"
        styles={{
          control: (baseStyles, state) => {
            return {
              ...baseStyles,
              borderColor: state.isFocused ? "grey" : "red",
              borderRadius: 10,
              minHeight: 42,
            };
          },

          option: (baseStyles, state) => {
            return {
              ...baseStyles,
              borderRadius: 10,
            };
          },
          multiValue: (styles, { data }) => {
            return {
              ...styles,
              minHeight: 36,
              display: "flex",
              alignItems: "center",
              borderRadius: 10,
              backgroundColor: "red",
            };
          },
        }}
      
        {...props}
      />
    </div>
  );
};

export default RSelect;
