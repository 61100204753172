import React from "react";
import Row from "../custom/Row";
import BaseButton from "./BaseButton";

export default function ButtonWithIcon(props) {
  const { leftIcon, rightIcon, text, textFontSize } = props;

  return (
    <BaseButton
      {...props}
      textOverride={
        <Row className="center">
          {leftIcon && leftIcon}
          {text && <span className={`font-weight-normal ${textFontSize ?? "fs-16px"} mx-1`}>{text}</span>}
          {rightIcon && rightIcon}
        </Row>
      }
    />
  );
}
