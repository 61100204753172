import { InboxNotificationSettings } from "./settings.types";

export interface User {
  id: string
  email: string
  phoneNumber: string
  name: string
  businessName: string
  status: string
  active: boolean
  complete: boolean
  createdAt: string
  updatedAt: string,
  invalidTeslaTokens: { email: string }[],
  features: Feature[]
  vehicleCount?: number,
  driverCount?: number,
  groupCount?: number
  notificationSettings: {
    inboxSettings: InboxNotificationSettings;
  }
  pendingSignUpApplicationCount: number
  unpaidInvoiceCount: number
  unreadMessageCount: number
  signUpCode: {
    id: string,
    code: string,
  },
  intercom: {
    web: {
      hash: string
    }
  }
}


export enum FeatureName {
  TESLA_INVOICE_LISTING_ENABLED = "tesla_invoice_listing_enabled",
  CHARGING_ASSIGN_DRIVER_ENABLED = "charging_assign_driver_enabled"
}

export interface Feature {
  id: FeatureName;
  name: string;
  isEnabled: boolean;
}

export type UserState = {
  currentUser: User | null;
};


export type OnboardingState = {
  isFirstLogin: boolean,
  isAddVehiclesStepCompleted: boolean,
  isAddDriversStepCompleted: boolean,
  isAddGroupsStepCompleted: boolean
}