import CancelButton from "components/button/CancelButton";
import PrimaryButton from "components/button/PrimaryButton";
import BaseModal from "components/modal/BaseModal";
import { NeutralBodyMediumText0, NeutralTitleLargeTextBlack } from "components/text/Text";
import { useAppDispatch, useAppSelector } from "hooks/redux.hooks";
import React from "react";
import { Row } from "reactstrap";
import { setIsTeslaWakeUpModalOpen } from "redux/slices/teslaSlice";
import { TeslaDataAccessorHook } from "types/vehicle.types";

type Props = {
  teslaHook: TeslaDataAccessorHook
}

const TeslaWakeUpEditorModal = ({ teslaHook }: Props) => {
  const isModalOpen = useAppSelector(state => state.tesla.helperSlice.isWakeUpModalOpen);
  const dispatch = useAppDispatch();

  const { triggerTeslaWakeUpSequence } = teslaHook;

  const handleCloseModal = () => {
    dispatch(setIsTeslaWakeUpModalOpen(false));
  };

  return (
    <BaseModal
      className="wide-xs"
      isModalOpen={isModalOpen}
      setIsModalOpen={handleCloseModal}
    >
      <NeutralTitleLargeTextBlack>Vehicle in Sleep Mode</NeutralTitleLargeTextBlack>
      <NeutralBodyMediumText0>
        Your Tesla vehicle is currently in sleep mode and needs to be awakened to perform the requested action. Waking
        up the vehicle will:
        {"\n"}
        &#x2022; Enable remote vehicle controls and settings
        {"\n"}
        &#x2022; Allow door lock/unlock functions
        {"\n"}
        &#x2022; Provide real-time vehicle status
        {"\n"}
        {"\n"}
        Would you like to wake up your vehicle to proceed with the requested action?</NeutralBodyMediumText0>

      <Row className="align-items-center justify-content-end">
        <CancelButton type="button" onClick={handleCloseModal} />
        <PrimaryButton text={"Wake up"} onClick={triggerTeslaWakeUpSequence} />
      </Row>
    </BaseModal>
  );
};

export default TeslaWakeUpEditorModal;