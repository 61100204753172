import React from "react";
import VehicleDetailsAllLocationInsight from "../VehicleDetailsAllLocationInsight";

import Col from "components/custom/Col";
import Row from "components/custom/Row";
import VehicleDetailsOverviewCard from "../VehicleDetailsOverviewCard";
import { useVehicleChargingSummaryAsync } from "hooks/data/vehicle-data-accessor.hooks";
import { TeslaDataAccessorHook, VehicleDataAccessorHook } from "types/vehicle.types";
import VehicleDetailsOverviewChargingTable from "../VehicleDetailsOverviewChargingTable";
import VehicleDetailsLocationMap from "../VehicleDetailsLocationMap";
import VehicleDetailsLocationInfo from "../VehicleDetailsLocationInfo";
import VehicleDetailsDriverAssignment from "../VehicleDetailsDriverAssignment";
import VehicleDetailsAttributes from "../VehicleDetailsAttributes";
import { VehicleDriverSchedulesDataAccessorHook } from "../../../../types/vehicle-driver-schedule.types";
import { BlackBody2Text200, BlackSubtitleText100 } from "../../../text/Text";
import { getDateAsFromNowFormatWithFetchedPrefix } from "../../../../utils/date.utils";
import { getVehicleConnectionStatus } from "../../../../utils/vehicle.utils";

type Props = {
  readonly vehicleHook: VehicleDataAccessorHook;
  readonly vehicleDriverSchedulesHook: VehicleDriverSchedulesDataAccessorHook
}

function VehicleDetailsOverviewContent({ vehicleHook,  vehicleDriverSchedulesHook }: Props): JSX.Element {
  const { isLoading, vehicleChargingSummary } = useVehicleChargingSummaryAsync();
  const { isManualAddedCar } = getVehicleConnectionStatus(vehicleHook.vehicle);


  return (
    <Row>
      <Col lg={4} className="pr-lg-3">
        <VehicleDetailsOverviewCard title={"Total Charging Cost"}
                                    linkText={"View details"}
                                    linkRoute={"charging"}
                                    content={<VehicleDetailsAllLocationInsight isLoading={isLoading}
                                                                               vehicleChargingSummary={vehicleChargingSummary} />} />


        <VehicleDetailsOverviewCard className={"mt-4"}
                                    title={"Assignments"}
                                    linkText={"View all"}
                                    linkRoute={"assignments"}
                                    content={<VehicleDetailsDriverAssignment
                                      vehicleDriverSchedulesHook={vehicleDriverSchedulesHook}
                                    />
                                    }
        />


        <VehicleDetailsOverviewCard className={"mt-4"}
                                    title={"Attributes"}
                                    linkText={"View all"}
                                    linkRoute={"about"}
                                    content={<VehicleDetailsAttributes isOverview vehicleHook={vehicleHook} />}
        />


      </Col>
      <Col lg={8} className="pl-lg-3">
        <VehicleDetailsOverviewCard title={"Recent sessions"}
                                    linkText={"View all"}
                                    linkRoute={"charging"}
                                    content={<VehicleDetailsOverviewChargingTable />} />

        {!isManualAddedCar &&
          <VehicleDetailsOverviewCard className={"mt-4"}
                                      titleOverwrite={
                                        <Row className="align-items-center">
                                          <BlackSubtitleText100
                                            className={"mr-2"}>Location</BlackSubtitleText100>
                                          <BlackBody2Text200>{getDateAsFromNowFormatWithFetchedPrefix(vehicleHook.vehicle?.telemetry?.location?.meta.fetchedAt)}</BlackBody2Text200>
                                        </Row>
                                      }
                                      description={<VehicleDetailsLocationInfo
                                        telemetry={vehicleHook.vehicle?.telemetry}
                                        isLoading={vehicleHook.isLoading} />}
                                      content={<VehicleDetailsLocationMap vehicleHook={vehicleHook}
                                                                          isOverview />} />
        }
      </Col>


    </Row>
  );
}

export default VehicleDetailsOverviewContent;