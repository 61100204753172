import React from "react";
import { SvgProps } from "types/svg.types";

function SustainabilityScoreIcon({ fill = "#6F7981" }: SvgProps) {
  return (
    <svg width="24" height="21" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.9199 8.47461C10.8711 10.041 9.22637 14.8734 7.5973 18.9225L9.07756 19.4395L9.8216 17.6381C10.1975 17.7712 10.5891 17.873 10.8711 17.873C19.4863 17.873 21.8359 4.55859 21.8359 4.55859C21.0527 6.125 15.5703 6.3208 11.6543 7.104C7.73828 7.88721 6.17188 11.2158 6.17188 12.7822C6.17188 14.3486 7.54248 15.7192 7.54248 15.7192C10.0879 8.47461 17.9199 8.47461 17.9199 8.47461Z"
        fill={fill}
      />
    </svg>
  );
}

export default SustainabilityScoreIcon;
