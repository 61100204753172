import Row from "components/custom/Row";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { Driver } from "types/driver.types";
import { getDriverFullName } from "utils/driver.utils";
import { getVehicleSummaryName } from "utils/vehicle.utils";

type Props = {
  drivers: Driver[];
  isForwardActive?: boolean;
};

function DriverList({ drivers }: Props) {
  return (
    <Row className="w-100 h-auto align-items-center py-2">
      {drivers?.map((d, ix) => (
        <Link key={ix} to={`/drivers/${d.id}`} className="list-badge">
          {getDriverFullName(d)}
        </Link>
      ))}
    </Row>
  );
}

export default DriverList;
