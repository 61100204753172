import FilterCalendarIcon from "components/icon/icons/FilterCalendarIcon";
import { BlackBodyText100, BlackCaptionText200, BlackH5Text100 } from "components/text/Text";
import { useFilterUrlQuery } from "hooks/filter.hooks";
import { useAppSelector } from "hooks/redux.hooks";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { setDashboardInsightsFilter } from "redux/slices/dashboardSlice";
import { DashboardInsightFilter } from "types/dashboard.types";
import { Period } from "types/filter.types";
import { extendFilterOptions } from "utils/dashboard.utils";

import { RSelect } from "../Component";
import Col from "../custom/Col";
import Row from "../custom/Row";

export default function DashboardPageInsightsHeader() {
  //const { t } = useTranslation("common");
  // const title = t("dashboard.page_header");
  // const description = t("dashboard.page_description");

  const { setFilterQuery } = useFilterUrlQuery();
  const { filter } = useAppSelector((state) => state.dashboard.insightsState);
  const dispatch = useDispatch();

  const handleSetDatePeriodOnQuery = useCallback(
    (period: Period) => {
      setFilterQuery((q) => {
        return {
          createdAt: {
            $within: period
          }
        };
      });
    },
    [setFilterQuery]
  );

  const filterValueOptions = [
    { label: "Last Week", value: DashboardInsightFilter.LastWeek },
    { label: "This Week", value: DashboardInsightFilter.ThisWeek },
    { label: "Last Month", value: DashboardInsightFilter.LastMonth },
    { label: "This Month", value: DashboardInsightFilter.ThisMonth },
    { label: "Last Quarter", value: DashboardInsightFilter.LastQuarter },
    { label: "This Quarter", value: DashboardInsightFilter.ThisQuarter },
    { label: "This Year", value: DashboardInsightFilter.ThisYear }
  ];

  const isFilterValueAllTime = DashboardInsightFilter.ThisYear === filter;
  const currentFilterValueLabel = filterValueOptions.find((o) => o.value === filter)?.label;
  const { start, end } = extendFilterOptions(filter);

  const handleFilterValueChanged = useCallback(
    (e) => {
      handleSetDatePeriodOnQuery(e.value);
      dispatch(setDashboardInsightsFilter(e.value));
    },
    [dispatch, handleSetDatePeriodOnQuery]
  );

  return (
    <Row className="justify-between align-items-center my-2">
      <Col md="6" className="my-2">
        <BlackH5Text100>
          Charging Insights
        </BlackH5Text100>

        <BlackBodyText100 className="page_description mt-1">
          Optimize your EV fleet charging with real-time insights
        </BlackBodyText100>
      </Col>

      <Col className="justify-end align-items-center" md="6">
        <Row className="align-items-center">
          <div className="mr-3 mb-2 mb-md-0">
            {!isFilterValueAllTime && (
              <BlackCaptionText200>
                {start} - {end}
              </BlackCaptionText200>
            )}
          </div>
          <div className="filter-selector calendar-filter">
            <FilterCalendarIcon className="filter-selector-calendar-icon" />
            <RSelect
              // inputRef={register({ required: "required" })}
              //isOptionDisabled={() => true}
              className="pl-2"
              isSearchable={false}
              options={filterValueOptions}
              onChange={handleFilterValueChanged}
              value={filter}
              placeholder={currentFilterValueLabel}
            />
          </div>
        </Row>
      </Col>
    </Row>
  );
}
