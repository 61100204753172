import user from "layout/header/dropdown/user/User";
import { InboxNotificationSettings, NotificationSetting } from "../types/settings.types";
import { User } from "../types/user.types";


const MockNotificationSetting: NotificationSetting = {
  email: true,
  sms: true,
  push: false
};

export const MockInboxSettings: InboxNotificationSettings = {
  chargingSuspicious: MockNotificationSetting,
  driverInvoiceUnpaid: MockNotificationSetting,
  vehicleNotAssigned: MockNotificationSetting,
  vehicleAssignmentEndsSoon: MockNotificationSetting,
  vehicleDriverPinSet: MockNotificationSetting,
  driverSignupCreated: MockNotificationSetting,
  driverPaymentMethodMissing: MockNotificationSetting
};

export const UserMockDataForTesting: User = {
  invalidTeslaTokens: [],
  active: true,
  businessName: "test business name",
  complete: true,
  createdAt: new Date().toDateString(),
  email: "test@gmail.com",
  features: [],
  id: "test_id_1111",
  name: "test name",
  phoneNumber: "+1111",
  status: "active",
  updatedAt: new Date().toDateString(),
  unpaidInvoiceCount: 0,
  unreadMessageCount: 0,
  notificationSettings: {
    inboxSettings: MockInboxSettings
  },
  pendingSignUpApplicationCount: 0,
  signUpCode: {
    code: "test code",
    id: "id"
  },
  intercom: {
    web: {
      hash: "test web"
    }
  }
};