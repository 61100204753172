// React
import { useCallback, useEffect, useMemo, useState } from "react";

// Third party
import { CircularProgress } from "@mui/material/node";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";

// Hooks & Utils & Services
import { useIntercomTrigger } from "../../hooks/intercom.hooks";

// Types
import { authorizeSmartCarStateAsync } from "services/vehicle.services";

// Components
import CancelButton from "../../components/button/CancelButton";
import TryAgainButton from "../../components/button/TryAgainButton";
import UnlinkIcon from "../../components/icon/icons/UnlinkIcon";
import AlertConfirmDialog from "../../components/notification/AlertConfirmDialog";
import { BlackBodyText0 } from "../../components/text/Text";
import { useAddVehicleCompletedHandlers, useSmartCar } from "../../hooks/vehicle.hooks";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { useAppDispatch } from "../../hooks/redux.hooks";

type Props = {};

function SmartCarExchangePage(props: Props) {
  // Hooks
  const { t } = useTranslation("common");

  const { triggerIntercom } = useIntercomTrigger();
  const { handleSmartCarOpenDialog } = useSmartCar();
  const { handleForwardCompletedPage } = useAddVehicleCompletedHandlers();

  const [isLoading, setIsLoading] = useState(true);
  const [isErrorModalVisible, setIsErrorModalVisible] = useState(false);

  const dispatch = useAppDispatch();
  const location = useLocation();
  const history = useHistory();

  const { code, state } = useMemo(() => {
    const params = new URLSearchParams(location.search);
    const code = params.get("code");
    const state = params.get("state");

    return { code, state };
  }, [location.search]);

  /**
   *
   */
  const callAuthorizeSmartCarStateAsync = useCallback(async (originalStateJsonStringify: string, code: string) => {
    return await authorizeSmartCarStateAsync({ state: originalStateJsonStringify, code });
  }, []);

  // Handlers

  /**
   *
   */
  const handleCancelButtonPressed = useCallback(() => {
    history.push("/vehicles");
  }, [history]);

  /**
   *
   */
  const handleExchangeSmartCarState = useCallback(async () => {
    if (!code || !state) {
      return;
    }

    setIsErrorModalVisible(false);
    setIsLoading(true);

    //https://github.com/smartcar/javascript-sdk/blob/master/src/sdk.js
    // bail if `state` is invalid
    try {
      const response = await callAuthorizeSmartCarStateAsync(JSON.parse(decodeURIComponent(state)), code);
      handleForwardCompletedPage(response, "smartcar");
    } catch (e) {
      setIsLoading(false);
      setIsErrorModalVisible(true);
    }
  }, [callAuthorizeSmartCarStateAsync, code, handleForwardCompletedPage, state]);

  // Effects
  /**
   *
   */
  useEffect(() => {
    handleExchangeSmartCarState();
  }, [callAuthorizeSmartCarStateAsync, code, handleExchangeSmartCarState, history, state]);

  return (
    <>
      <Head title={t("vehicle.title.add_vehicles_page")}></Head>
      <Content>
        <AlertConfirmDialog
          overrideButtons={
            <>
              <CancelButton onClick={handleCancelButtonPressed} />
              <TryAgainButton onTryAgainButtonPressed={handleSmartCarOpenDialog} />
            </>
          }
          isDialogOpen={isErrorModalVisible}
          toggleIsDialogOpen={handleCancelButtonPressed}
          icon={<UnlinkIcon />}
          title={"Unable to Add Vehicle"}
          descriptionOverride={
            <>
              <BlackBodyText0>
                We're currently experiencing technical difficulties connecting your vehicle.
              </BlackBodyText0>
              <BlackBodyText0>Please attempt to connect again.</BlackBodyText0>
              <BlackBodyText0>
                If the problem persists, please contact{" "}
                <span onClick={triggerIntercom} className="link-text mb-0 pointer-events-all">
                  Customer Support
                </span>
                .
              </BlackBodyText0>
            </>
          }
        />
        <div className="d-flex flex-1 center h-300px">
          {isLoading && <CircularProgress size={75} style={{ color: "#1E44FF" }} />}
        </div>
      </Content>
    </>
  );
}

export default SmartCarExchangePage;
