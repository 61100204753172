import Card from "components/card/Card";
import FlashLightsIcon from "components/icon/icons/FlashLightsIcon";
import FrunkOpenIcon from "components/icon/icons/FrunkOpenIcon";
import HonkHornIcon from "components/icon/icons/HonkHornIcon";
import TrunkOpenIcon from "components/icon/icons/TrunkOpenIcon";
import TeslaBasicControlsButton from "components/vehicle-components/tesla/controls/TeslaBasicControlsButton";
import TeslaLockStatusEditor from "components/vehicle-components/tesla/controls/TeslaLockStatusEditor";
import TeslaPortStatusEditor from "components/vehicle-components/tesla/controls/TeslaPortStatusEditor";
import TeslaRemoteStartEditor from "components/vehicle-components/tesla/controls/TeslaRemoteStartEditor";
import VehicleDetailsPageCardBlock from "components/vehicle-components/vehicle-details/VehicleDetailsPageCardBlock";
import { useTeslaCommandHandlersAsync } from "hooks/data/vehicle-data-accessor.hooks";
import React from "react";
import { TeslaCommand, TeslaDataAccessorHook } from "types/vehicle.types";

type Props = {
  teslaHook: TeslaDataAccessorHook;
}

function TeslaControlsEditors({ teslaHook }: Props) {
  const { handleSubmitBasicCommandAsync, isSubmittingByCommand } = useTeslaCommandHandlersAsync();

  const isTeslaLoading = teslaHook.isLoading;

  const trunksInfo = teslaHook.info?.trunks
  const isFrunkOpen = trunksInfo?.front.open
  const isTrunkOpen = trunksInfo?.rear.open

  return (
    <>
      <Card className={"mt-4"}>
        <TeslaLockStatusEditor teslaHook={teslaHook} />
        <TeslaPortStatusEditor teslaHook={teslaHook} />
        <TeslaRemoteStartEditor teslaHook={teslaHook} />
      </Card>


      <Card className={"mt-4"}>
        <VehicleDetailsPageCardBlock
          paddingClass={"pt-0 pb-2"}
          columnClass="h-36px"
          isLoading={isTeslaLoading}
          leftLabel="Frunk status"
          leftText={teslaHook.isSleeping ? "Unavailable in Sleep Mode" : (isFrunkOpen ? "Opened" : "Closed")}
          rightTextOverwrite={
            <TeslaBasicControlsButton
              tooltipMessageOverride={teslaHook.areTrunkControlsSupported === false ? "Frunk control is not supported." : undefined}
              isAdditionallySupported={teslaHook.areTrunkControlsSupported}
              onClick={() => handleSubmitBasicCommandAsync(TeslaCommand.ACTUATE_FRONT_TRUNK)}
              isLoading={isSubmittingByCommand(TeslaCommand.ACTUATE_FRONT_TRUNK)}
              leftIcon={<FrunkOpenIcon />}
            />
          }
        />

        <VehicleDetailsPageCardBlock
          columnClass="h-36px"
          paddingClass={"py-2"}
          isLoading={isTeslaLoading}
          leftLabel="Trunk status"
          leftText={teslaHook.isSleeping ? "Unavailable in Sleep Mode" : (isTrunkOpen ? "Opened" : "Closed")}
          rightTextOverwrite={
            <TeslaBasicControlsButton
              tooltipMessageOverride={teslaHook.areTrunkControlsSupported === false ? "Frunk control is not supported." : undefined}
              isAdditionallySupported={teslaHook.areTrunkControlsSupported}
              onClick={() => handleSubmitBasicCommandAsync(TeslaCommand.ACTUATE_REAR_TRUNK)}
              isLoading={isSubmittingByCommand(TeslaCommand.ACTUATE_REAR_TRUNK)}
              leftIcon={<TrunkOpenIcon />} />
          }
        />


        <VehicleDetailsPageCardBlock
          columnClass="h-36px"
          paddingClass={"py-2"}
          isLoading={isTeslaLoading}
          leftText={"Flash Lights"}
          rightTextOverwrite={
            <TeslaBasicControlsButton
              onClick={() => handleSubmitBasicCommandAsync(TeslaCommand.FLASH_LIGHTS)}
              isLoading={isSubmittingByCommand(TeslaCommand.FLASH_LIGHTS)}
              leftIcon={<FlashLightsIcon />}
            />
          }
        />


        <VehicleDetailsPageCardBlock
          isBorderBottomVisible={false}
          columnClass="h-36px"
          paddingClass={"pb-0 pt-2"}
          isLoading={isTeslaLoading}
          leftText={"Honk Horn"}
          rightTextOverwrite={
            <TeslaBasicControlsButton
              onClick={() => handleSubmitBasicCommandAsync(TeslaCommand.HONK_HORN)}
              isLoading={isSubmittingByCommand(TeslaCommand.HONK_HORN)}
              leftIcon={<HonkHornIcon />}
            />
          }
        />
      </Card>
    </>
  );

}

export default TeslaControlsEditors;