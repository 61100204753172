import qs from "qs";
import { PaginationUrlQuery } from "types/api.types";
import { Query } from "types/filter.types";

export function getQueryWithDefaultPagination(query?: string): string {
  const defaultPagination: PaginationUrlQuery = { page: "0", size: "20" };

  const queryObject = query ? qs.parse(query, { ignoreQueryPrefix: true }) as Query<any> : {};

  const mergedFilters = {
    ...queryObject,
    pagination: {
      ...defaultPagination,
      ...(queryObject?.pagination || {})
    }
  };

  return qs.stringify(mergedFilters, {
    skipNulls: true,
    addQueryPrefix: true
  });
}