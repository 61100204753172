import PrimaryButton from "components/button/PrimaryButton";
import Card from "components/card/Card";
import Row from "components/custom/Row";
import { useAlertManager } from "hooks/alert.hooks";
import {
  useDriverFormVehicleSetupStepStates,
  useDriverSelfSignupFormReimbursementStepStates
} from "hooks/driver.hooks";
import React, { Dispatch, SetStateAction, useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Prompt, useHistory, useParams } from "react-router";
import { Form } from "reactstrap";
import {
  resetDriverFormState,
  setDriverFormSuccessMessageHelper,
  setDriverNonFormStateAsCompleted
} from "redux/slices/driverSlice";
import {
  DriverFormState,
  DriverSelfSignupStep,
  DriverSelfSignupUpdateCommand,
  DriverSelfSignupUpdatePayload
} from "types/driver.types";
import { PayerType } from "types/payment.types";
import { handleApiErrorResponse } from "utils";
import { getDriverSelfSignupFormStepStatus, getFormattedCreditValueForAddDriverService } from "utils/driver.utils";
import DriverFormChargingStep from "./DriverFormChargingStep";
import DriverFormVehicleSetupStep from "./DriverFormVehicleSetupStep";
import OutlinedBackButton from "../../button/OutlinedBackButton";
import DriverFormReimbursementSetupStep from "./DriverFormReimbursementSetupStep";
import { updateDriverSignupAsync } from "../../../services/driver.services";
import { useToggleBackButton } from "../../../hooks/dom.hooks";
import { NeutralBodyLargeText100 } from "../../text/Text";
import CancelButton from "../../button/CancelButton";
import AlertConfirmDialog from "../../notification/AlertConfirmDialog";
import InfoBadge from "../../badge/InfoBadge";

type Props = {
  driverFullName: string;
  step: DriverSelfSignupStep
  isNavigationAllowing: boolean
  setIsNavigationAllowing: Dispatch<SetStateAction<boolean>>
  setForwardingLocationAfterConfirm: Dispatch<SetStateAction<string>>
  setIsExitFormWarningModalOpen: Dispatch<SetStateAction<boolean>>
  setStep: Dispatch<SetStateAction<DriverSelfSignupStep>>
};

function DriverSelfSignupApprovalForm({
                                        driverFullName,
                                        step,
                                        setStep,
                                        setIsNavigationAllowing,
                                        isNavigationAllowing,
                                        setForwardingLocationAfterConfirm,
                                        setIsExitFormWarningModalOpen
                                      }: Props) {
  const { handleSubmit, errors, trigger, reset, formState, register, control } = useForm<DriverFormState>({
    mode: "all",
    defaultValues: {
      payer: PayerType.ORGANIZATION
    }
  });

  const { driverId } = useParams<{ driverId: string }>();

  const [validatedDriverSubmitFormData, setValidatedDriverSubmitFormData] = useState<DriverFormState | undefined>();

  const history = useHistory();
  const dispatch = useDispatch();
  const { handleOpenErrorAlert } = useAlertManager();

  const [isChargingStepConfirmDialogOpen, setIsChargingStepConfirmDialogOpen] = useState(false);


  /**
   * Reimbursement Setup
   */
  const driverFormReimbursementStepStates = useDriverSelfSignupFormReimbursementStepStates();
  const { isReimbursementActive } = driverFormReimbursementStepStates;

  /**
   * Vehicle Setup
   */
  const driverFormVehicleSetupStates = useDriverFormVehicleSetupStepStates();
  const {
    isVehicleSelected,
    handleSubmitAddScheduleAsync
  } = driverFormVehicleSetupStates;


  const { isChargingSetupStep, isReimbursementStep, isVehicleSetupStep } = getDriverSelfSignupFormStepStatus(step);
  const isBackButtonVisible = isChargingSetupStep;
  useToggleBackButton(isBackButtonVisible);


  const commonFormStepProps = useMemo(() => {
    return { register, control, errors };
  }, [control, errors, register]);


  const [isSubmitting, setIsSubmitting] = useState(false);


  /*
*  Form Handlers
* */

  const handleCompleteFlow = useCallback(() => {
    dispatch(resetDriverFormState());
    dispatch(setDriverNonFormStateAsCompleted());
    history.replace("/add-driver/completed");
  }, [dispatch, history]);


  /**
   *
   */
  const handleFormApproveSubmit = useCallback(
    async () => {
      try {
        if (!validatedDriverSubmitFormData) {
          return;
        }

        const { credit, payer } = validatedDriverSubmitFormData;

        const payload: DriverSelfSignupUpdatePayload = {
          credit: getFormattedCreditValueForAddDriverService(credit),
          payer,
          vehicleDriverSchedule: null
        };

        /*
        *
        * */
        setIsSubmitting(true);
        setIsNavigationAllowing(true);
        await updateDriverSignupAsync(driverId, DriverSelfSignupUpdateCommand.APPROVE, payload);

        setStep(DriverSelfSignupStep.REIMBURSEMENT);

      } catch (error) {
        handleOpenErrorAlert(handleApiErrorResponse(error));
      } finally {
        setIsSubmitting(false);
      }
    },
    [driverId, handleOpenErrorAlert, setIsNavigationAllowing, setStep, validatedDriverSubmitFormData]
  );


  const handleSubmitVehicleStepAsync = useCallback(async () => {
    if (!driverId || !isVehicleSelected) {
      dispatch(setDriverFormSuccessMessageHelper(driverFullName));
      handleCompleteFlow();
      return;
    }

    try {
      setIsSubmitting(true);
      await handleSubmitAddScheduleAsync(driverId);

      dispatch(setDriverFormSuccessMessageHelper(driverFullName));
      handleCompleteFlow();
    } catch (error) {
      console.error(error);
      handleOpenErrorAlert(handleApiErrorResponse(error));
    } finally {
      setIsSubmitting(false);
    }
  }, [dispatch, driverFullName, driverId, handleCompleteFlow, handleOpenErrorAlert, handleSubmitAddScheduleAsync, isVehicleSelected]);


  /**
   *
   */
  const handleSubmitDriverReimbursementStep = useCallback(async (formData: DriverFormState) => {
    const { boundsToSubmit, isReimbursementActive } = driverFormReimbursementStepStates;

    const { fixedRate } = formData;
    if (!driverId || !fixedRate || !isReimbursementActive) {
      setStep(DriverSelfSignupStep.VEHICLE);
      return;
    }

    if (!boundsToSubmit) {
      handleOpenErrorAlert("Bounds must be selected");
      return;
    }


    try {
      setIsSubmitting(true);
      await driverFormReimbursementStepStates.handleSubmitEnableReimbursementCallback(driverId, fixedRate);
      setStep(DriverSelfSignupStep.VEHICLE);

    } catch (err) {
      handleOpenErrorAlert(handleApiErrorResponse(err));
      console.error(err);
    } finally {
      setIsSubmitting(false);
    }
  }, [driverFormReimbursementStepStates, driverId, handleOpenErrorAlert, setStep]);


  /**
   *
   */
  const handleFormSubmit = useCallback(async (formData: DriverFormState) => {
    await trigger();

    if (isChargingSetupStep) {
      setValidatedDriverSubmitFormData(formData);
      setIsChargingStepConfirmDialogOpen(true);
      return;
    }

    if (isReimbursementStep) {
      await handleSubmitDriverReimbursementStep(formData);
      return;
    }

    if (isVehicleSetupStep) {
      await handleSubmitVehicleStepAsync();
      return;
    }


  }, [handleSubmitDriverReimbursementStep, handleSubmitVehicleStepAsync, isChargingSetupStep, isReimbursementStep, isVehicleSetupStep, trigger]);


  /*
*  Handlers
* */


  const handleBackButtonPressed = useCallback(() => {
    history.goBack();
  }, [history]);


  /*
  *  Render Values
  * */

  const currentStep = useMemo(() => {
    switch (step) {
      case DriverSelfSignupStep.CHARGING:
        return <DriverFormChargingStep isApprovalForm {...commonFormStepProps} />;
      case DriverSelfSignupStep.REIMBURSEMENT:
        return <DriverFormReimbursementSetupStep
          {...commonFormStepProps} {...driverFormReimbursementStepStates} />;
      case DriverSelfSignupStep.VEHICLE:
        return <DriverFormVehicleSetupStep
          driverFormVehicleSetupStates={driverFormVehicleSetupStates}
          {...commonFormStepProps}
        />;
      default:
        return <>Unknown step</>;
    }
  }, [commonFormStepProps, driverFormReimbursementStepStates, driverFormVehicleSetupStates, step]);


  return (
    <div>
      <Form className="w-100 " onSubmit={handleSubmit(handleFormSubmit)}>
        <Prompt
          when={formState.isDirty && !isNavigationAllowing}
          message={(location) => {
            if (formState.isDirty) {
              setForwardingLocationAfterConfirm(location.pathname);
              setIsExitFormWarningModalOpen(true);
              return false;
            }
            return true;
          }}
        />
        <Card className={"mb-3"}>
          {currentStep}
        </Card>

        <Row className={isChargingSetupStep ? "justify-between" : "justify-end"}>
          {isChargingSetupStep && <>
            <AlertConfirmDialog
              title={`Are you sure to approve driver ?`}
              descriptionOverride={
                <>
                  <NeutralBodyLargeText100 className={"mb-3"}>
                    Once you approve the driver, this action cannot be undone.
                  </NeutralBodyLargeText100>
                  <InfoBadge
                    text={"After approving the driver, you can configure optional settings, such as home charging and vehicle assignments."} />
                </>
              }
              isDialogOpen={isChargingStepConfirmDialogOpen}
              overrideButtons={
                <>
                  <div className="mr-2">
                    <CancelButton onClick={() => setIsChargingStepConfirmDialogOpen(false)} />
                  </div>

                  <PrimaryButton
                    type={"button"}
                    text="Approve & Continue"
                    isLoading={isSubmitting}
                    onClick={handleFormApproveSubmit}
                  />
                </>
              }
            />
            <OutlinedBackButton onBackButtonClicked={handleBackButtonPressed} />
            <PrimaryButton
              className="ml-2"
              text={"Approve"}
              isLoading={isSubmitting}
            />
          </>}


          {isReimbursementStep &&
            <PrimaryButton className="ml-2"
                           text={isReimbursementActive ? "Save & continue" : "Skip"}
                           isLoading={isSubmitting} />
          }


          {isVehicleSetupStep &&
            <PrimaryButton className="ml-2"
                           text={isVehicleSelected ? "Save" : "Skip"}
                           isLoading={isSubmitting} />

          }

        </Row>
      </Form>
    </div>
  );
}

export default DriverSelfSignupApprovalForm;
