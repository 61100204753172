import Row from "components/custom/Row";
import { Link } from "react-router-dom";
import { Vehicle } from "types/vehicle.types";
import { getVehicleSummaryName } from "utils/vehicle.utils";

type Props = {
  vehicles: Vehicle[];
  isForwardActive?: boolean;
};

function VehicleList({ vehicles, isForwardActive }: Props) {
  return (
    <Row className="h-auto align-items-center py-2">
      {vehicles?.map((v, ix) => (
        <Link key={ix} to={`/vehicles/${v.id}`} className="list-badge">
          {getVehicleSummaryName(v)}
        </Link>
      ))}
    </Row>
  );
}

export default VehicleList;
