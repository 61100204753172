import Col from "components/custom/Col";
import Row from "components/custom/Row";
import InfoIcon from "components/icon/icons/InfoIcon";
import SustainabilityScoreIcon from "components/icon/icons/SustainabilityScoreIcon";
import { BlackCaptionText100, BlackCaptionText200, GreenBodyText200, NeutralBody2Text100 } from "components/text/Text";
import { CardTooltip } from "components/tooltip/MuiTooltip";
import { useHeaderStatsAsync } from "hooks/data/header-data-accessor.hooks";
import SustainabilityScoreCar from "assets/images/dashboard/SustainabilityScoreCar.png";
import Skeleton from "react-loading-skeleton";
import { formatNumber } from "utils/charging.utils";
import { AppColors } from "../../utils/colors.utils";

type Props = {};

function SustainabilityScore({}: Props) {
  const { headerStats, isLoading } = useHeaderStatsAsync();

  const generalScoreDisplay = formatNumber(headerStats?.sustainabilityScore.total);

  if (isLoading) {
    return <Skeleton className="w-100px" height={20} count={1} />;
  }

  return (
    <CardTooltip
      title={
        <Row className="p-1 align-items-center">
          <img width={127} height={95} className="mr-3" src={SustainabilityScoreCar} alt="Sustainability Score " />
          <Col>
            <Row className="mb-1">
              <SustainabilityScoreIcon fill="#23D206" />
              <GreenBodyText200 className="ml-1">Sustainability Score</GreenBodyText200>
            </Row>
            <BlackCaptionText100>
              Your score in 365 days by driving {headerStats?.vehicleStats?.total} electrical vehicles. Track your CO₂
              savings easily! Our score compares EVs to gas cars, using:
            </BlackCaptionText100>
            <BlackCaptionText200 className="my-1">
              CO₂ Saved (kg) = EV Energy (kWh) / Tesla Model 3 Consumption (0.24 kWh/mile) * CO₂ Factor (8.8 kg/gallon
              vs. Toyota Camry’s 28 mpg).
            </BlackCaptionText200>
            <BlackCaptionText200>Simple, yet powerful – your step towards a greener earth! 💚</BlackCaptionText200>
          </Col>
        </Row>
      }
      placement="top-start"
    >
      <div className="d-flex align-items-center cursor-pointer sustainability-score-block">
        <SustainabilityScoreIcon fill={AppColors.neutral100} />
        <NeutralBody2Text100 className="ml-1">{generalScoreDisplay}</NeutralBody2Text100>
        <InfoIcon fill={AppColors.neutral100} className="ml-1" width={15} style={{ paddingBottom: 1 }} />
      </div>
    </CardTooltip>
  );
}

export default SustainabilityScore;
