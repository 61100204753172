import ClipboardWithCopyIcon from "components/clipboard/ClipboardWithCopyIcon";
import React, { useCallback, useState } from "react";
import { useHistory } from "react-router";
import { useAlertManager } from "../../hooks/alert.hooks";
import { updateChargingPayerToOrgAsync } from "../../services/charging.service";
import { ChargingPaymentState, ChargingState, ChargingUpdateCommands } from "../../types/charging-sessions.types";
import { PayerType } from "../../types/payment.types";
import { handleApiErrorResponse } from "../../utils";
import { getDriverPaymentResponsibilityLabelByPayerType } from "../../utils/driver.utils";
import { getFullNameByFirstNameAndLastName } from "../../utils/format.utils";
import InfoBadge from "../badge/InfoBadge";
import CancelButton from "../button/CancelButton";
import HoverUnderlinedTextButton from "../button/HoverUnderlinedTextButton";
import MessageButton from "../button/MessageButton";
import PrimaryButton from "../button/PrimaryButton";
import UnderlinedTextButton from "../button/UnderlinedTextButton";
import Card from "../card/Card";
import Row from "../custom/Row";
import VehiclePlaceholderIcon from "../icon/icons/VehiclePlaceholderIcon";
import AlertConfirmDialog from "../notification/AlertConfirmDialog";
import { BlackBodyText100 } from "../text/Text";
import ChargingSessionsDetailsPageCardBlock from "./ChargingSessionsDetailsPageCardBlock";


export default function DetailsPageUserCard({ chargingSessionDetails, refetch }) {
  const [isPayerConfirmationOpen, setIsPayerConfirmationOpen] = useState(false);
  const { user, vehicle } = chargingSessionDetails;

  const { handleOpenSuccessAlert, handleOpenErrorAlert } = useAlertManager();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const history = useHistory();


  const handleUserViewClicked = useCallback(() => {
    if (!user?.id) {
      return;
    }

    history.push(`/drivers/${user?.id}`);
  }, [user?.id, history]);


  const handleUpdatePayerDialogConfirmed = useCallback(async () => {
    try {
      setIsSubmitting(true);
      await updateChargingPayerToOrgAsync(chargingSessionDetails.id, {
        command: ChargingUpdateCommands.UPDATE_AS_ORGANIZATION_PAYS
      });

      refetch();
      handleOpenSuccessAlert("Payer is updated successfully.");
    } catch (e) {
      console.error(e);
      handleOpenErrorAlert(handleApiErrorResponse(e));
    } finally {
      setIsSubmitting(false);
    }
  }, [chargingSessionDetails.id, handleOpenErrorAlert, handleOpenSuccessAlert, refetch]);


  if (!vehicle && !user) {
    return <></>;
  }

  const isPayerDriver = chargingSessionDetails.payer === PayerType.DRIVER;
  const isNotPaid = chargingSessionDetails.paymentState !== ChargingPaymentState.PAID;
  const isStateDone = chargingSessionDetails.state = ChargingState.COMPLETED;

  const isPayerToOrgVisible = isPayerDriver && isNotPaid && isStateDone;


  return (
    <Card>
      {vehicle && (
        <>
          <Row className="align-center mb-1">
            <div className="w-48px h-48px mr-1">
              <VehiclePlaceholderIcon />
            </div>
            <UnderlinedTextButton
              href={`/vehicles/${vehicle?.id}`}
              textClassName={"text-black-100 fs-16px"}
              text={vehicle?.name} />
          </Row>

          <ChargingSessionsDetailsPageCardBlock
            leftLabel="Vehicle"
            leftText={vehicle?.carBrand}
            rightLabel="Model"
            rightText={vehicle?.carModel}
          />
        </>
      )}


      {vehicle && (
        <ChargingSessionsDetailsPageCardBlock
          leftLabel="License Plate"
          leftText={vehicle?.plateNumber ?? "-"}
          rightLabel={"VIN"}
          rightTextOverwrite={<ClipboardWithCopyIcon TextComponent={BlackBodyText100} text={vehicle?.vin} />}
        />
      )}

      <>
        <AlertConfirmDialog
          isDialogOpen={isPayerConfirmationOpen}
          title="Are you sure you want to change the payment responsibility for this session? "
          descriptionOverride={<InfoBadge
            text={"Switching to \"Org Pays\" will cancel the driver's invoice and the session will be charged to the organization.\n" +
              "\n" +
              "This change will only apply to this session. To adjust the driver's payment responsibility for future sessions, please visit the driver detail page."} />}
          toggleIsDialogOpen={() => setIsPayerConfirmationOpen(false)}
          overrideButtons={
            <>
              <div className="mr-2">
                <CancelButton onClick={() => setIsPayerConfirmationOpen(false)} />
              </div>

              <PrimaryButton isLoading={isSubmitting} text={"Confirm"} onClick={handleUpdatePayerDialogConfirmed} />
            </>
          }
        />

        <ChargingSessionsDetailsPageCardBlock
          isBorderTopVisible={!!vehicle}
          leftLabel="Driver name"
          leftTextOverwrite={
            user ? <UnderlinedTextButton
              textClassName={"text-black-100 fs-16px"}
              href={`/drivers/${user?.id}`}
              text={getFullNameByFirstNameAndLastName(user?.firstName, user?.lastName)} /> : "-"
          }
          rightBeforeText={
            <>
              <MessageButton userId={user?.id} height={36} />
            </>
          }
        />

        <ChargingSessionsDetailsPageCardBlock

          leftLabel="Payment Responsibility"
          leftTextOverwrite={<Row>
            <BlackBodyText100 className={"mr-2 word-wrap-normal w-max-100 text-truncate"}>
              {getDriverPaymentResponsibilityLabelByPayerType(chargingSessionDetails.payer)}
            </BlackBodyText100>
            {isPayerToOrgVisible && (
              <HoverUnderlinedTextButton text={"Switch to Org pays"}
                                         onClick={() => setIsPayerConfirmationOpen(true)} />)}
          </Row>
          }
        />

      </>


    </Card>
  );
}

/**
 * onClick={() => history.push(`/edit-vehicle/${row.id}`)}
 */
