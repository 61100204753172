// React
import React, { useMemo } from "react";
import { useHistory } from "react-router";

// 3rd party
import { useTranslation } from "react-i18next";

// Hooks & Utils
import { useChargingSessionCommonTableColumns } from "../../hooks/charging-session-hooks";
import { useChargingListAsync } from "../../hooks/data/charging-data-accessor.hooks";

// Types
import { Charging } from "../../types/charging-sessions.types";

// Components
import PaginatedTableCsvLinkButton from "components/button/PaginatedTableCsvLinkButton";
import FilterSearchInput from "components/filter/FilterSearchInput";
import PaginatedTable from "components/table/PaginatedTable";
import { useStripeInvoiceUrlAccessor } from "hooks/invoice.hooks";
import { useUrlPagination } from "hooks/pagination.hooks";
import { getChargingSessionsAsync } from "services/charging.service";
import { getChargingSessionRowForCsv } from "utils/charging.utils";
import Row from "../custom/Row";
import ChargingDataTablePlaceholder from "./ChargingDataTablePlaceholder";
import ChargingFilterMenu from "./filter/ChargingFilterMenu";

export type ChargingListTableColumn = {
  name: string;
  selector?: (row?: Charging) => React.JSX.Element | string | any;
  width?: string;
  cell?: (row?: any) => React.JSX.Element | string;
};

const ChargingSessionsDataTable = () => {
  /**
   * Hooks
   */

  const { t } = useTranslation("common");

  const history = useHistory();

  const { tablePageRowSize } = useUrlPagination();

  const { chargingList, chargingListMeta, isLoading } = useChargingListAsync();

  const commonTableColumns = useChargingSessionCommonTableColumns();

  const { handleForwardToStripeAsync, isForwarding } = useStripeInvoiceUrlAccessor();

  const columns = useMemo(
    () => [
      commonTableColumns.driver,
      commonTableColumns.state,
      commonTableColumns.vehicle,
      commonTableColumns.source,
      commonTableColumns.chargerName,
      commonTableColumns.energy,
      commonTableColumns.cost,
      commonTableColumns.payStat,
      commonTableColumns.stripeInvoiceId(handleForwardToStripeAsync, isForwarding),
      commonTableColumns.whoPays,
      commonTableColumns.date,
      commonTableColumns.action
    ],
    [commonTableColumns, handleForwardToStripeAsync, isForwarding]
  ) as ChargingListTableColumn[];

  /**
   * Handlers
   */

  /**
   * Effects
   */

  /**
   * Render
   */

  return (
    <React.Fragment>
      <Row className="justify-between my-2 align-center">
        <FilterSearchInput placeholder="Search name, vehicle or charger (min 2 char.)" />

        <PaginatedTableCsvLinkButton
          fileName="charging-sessions"
          csvRowFormatter={getChargingSessionRowForCsv}
          totalPages={chargingListMeta?.pagination.totalPages ?? 0}
          rowSizePerPage={tablePageRowSize}
          fetchListAsync={getChargingSessionsAsync}
        />
      </Row>

      <ChargingFilterMenu />

      <PaginatedTable
        noDataPlaceholder={
          <ChargingDataTablePlaceholder description={t("vehicle.vehicle_details.sessions_table.placeholder.title")} />
        }
        paginationTotalRows={chargingListMeta?.pagination.totalElements}
        getRoutePath={id => `/charging-sessions/${id}`}
        columns={columns}
        data={chargingList}
        isLoading={isLoading}
      />
    </React.Fragment>
  );
};

export default ChargingSessionsDataTable;
