// React
import SamsaraStarterGuide from "assets/images/vehicle/SamsaraStarterGuide.png";
// Types
// Components
import PrimaryButton from "components/button/PrimaryButton";
import Card from "components/card/Card";
import Col from "components/custom/Col";
import Row from "components/custom/Row";
import { BlackBodyText200 } from "components/text/Text";
// Third Party
// Services && Hooks && Utils
import { useSamsaraConnection } from "hooks/vehicle.hooks";
import AddVehicleFormStarterNeedHelpButtonAndModal from "./AddVehicleFlowSelectionNeedHelpButtonAndModal";

type Props = {};


export default function AddVehicleTeslaFlowStarter(props: Props) {
  const { handleOpenSamsaraConnectionFlow } = useSamsaraConnection();


  return (
    <div className="w-100 center ">
      <Card padding="p-4" className="wide-md-fix mt-2">
        <Row className="mb-3">
          <img className={"object-fit-cover"} src={SamsaraStarterGuide}
               alt={"Samsara guide"} />
        </Row>

        <Row className="mt-4 align-items-center justify-between">
          <Col md="6" className="d-flex justify-content-start ">
            <AddVehicleFormStarterNeedHelpButtonAndModal />
          </Col>
          <PrimaryButton text={"Next"} onClick={handleOpenSamsaraConnectionFlow} />
        </Row>
      </Card>
    </div>
      );
      }
