import FilterEditorCard from "components/filter/FilterEditorCard";
import FilterMenuCheckbox from "components/filter/FilterMenuCheckbox";
import { useFilterUrlQuery, useSortFilterOptionsBySelectedItems } from "hooks/filter.hooks";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ChargingDriverFilterOptionKey, ChargingFilters } from "types/charging-sessions.types";
import { useAllDriversAsync } from "../../../hooks/data/driver-data-accessor.hooks";
import { Driver } from "../../../types/driver.types";
import { getDriverFullName } from "../../../utils/driver.utils";
import Card from "../../card/Card";
import SearchInput from "../../input/SearchInput";
import CircularLoadingSpinner from "../../loading/CircularLoadingSpinner";

type Props = {};

function ChargingFilterDriverSelector(props: Props) {
  const {
    filterQuery,
    setFilterQuery,
    handleSetQueryWithoutResettingPagination
  } = useFilterUrlQuery<ChargingFilters>();
  const [filterSearchValue, setFilterSearchValue] = useState("");

  const driversOnQuery = useMemo(() => {
    return filterQuery?.user?.$in ?? [];
  }, [filterQuery?.user?.$in]);

  const [selectedUsers, setSelectedUsers] = useState<(string)[]>(driversOnQuery);
  const [variationCount, setVariationCount] = useState(0);

  const { isLoading, allDrivers } = useAllDriversAsync();

  const [driverOptions, setDriverOptions] = useState<Driver[]>(allDrivers);

  useEffect(() => {
    setSelectedUsers(driversOnQuery);
    setVariationCount(driversOnQuery.length);
  }, [filterQuery, driversOnQuery, setSelectedUsers]);

  const isSelectedUser = useCallback(
    (driver: string) => {
      return selectedUsers.includes(driver);
    },
    [selectedUsers]
  );


  const isSelectedUserByQuery = useCallback(
    (driver: Driver) => {
      return driversOnQuery.includes(driver.id);
    },
    [driversOnQuery]
  );

  useSortFilterOptionsBySelectedItems<Driver>(setDriverOptions, isSelectedUserByQuery);

  const handleDriverToggled = useCallback(
    (driver: string) => {
      if (isSelectedUser(driver)) {
        setSelectedUsers(selectedUsers.filter((n) => n !== driver));
        return;
      }
      setSelectedUsers([...selectedUsers, driver])
    },
    [isSelectedUser, selectedUsers]
  );


  const handleSetNetworksOnQuery = useCallback(
    (users: string[]) => {
      setFilterQuery((q) => {
        return {
          user: {
            $in: users
          }
        };
      });
    },
    [setFilterQuery]
  );

  const handleClearButtonPressed = useCallback(() => {
    setSelectedUsers([]);
    handleSetNetworksOnQuery([]);
    setVariationCount(0);
  }, [handleSetNetworksOnQuery]);

  const handleApplyButtonPressed = useCallback(() => {
    handleSetNetworksOnQuery(selectedUsers);
    setVariationCount(selectedUsers.length);
  }, [handleSetNetworksOnQuery, selectedUsers]);

  useEffect(() => {
    if (!isLoading) {
      setDriverOptions(allDrivers);
    }
  }, [allDrivers, isLoading]);


  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (!filterSearchValue) {
      setDriverOptions(allDrivers);
      return;
    }

    const lowerCaseSearchValue = filterSearchValue.toLowerCase();
    setDriverOptions(allDrivers.filter(v => getDriverFullName(v).toLowerCase().includes(lowerCaseSearchValue)));
  }, [allDrivers, filterSearchValue, isLoading]);

  return (
    <FilterEditorCard
      title="Driver"
      label="Driver"
      description="Driver on charging record"
      variationCount={variationCount}
      onApplyPressed={handleApplyButtonPressed}
      onClearPressed={handleClearButtonPressed}
    >

      {isLoading ? <CircularLoadingSpinner heightClassName={"h-200px"} size={30} /> :
        <>
          <Card padding={"p-0 mb-2 mt-1"} className={"w-300px"}>
            <SearchInput
              isClearable
              className="mb-2 mb-md-0 "
              onChange={(e) => setFilterSearchValue(e.target.value)}
              value={filterSearchValue}
              placeholder={"Search driver"}
            />
          </Card>


          <div className={"h-max-200px w-425px overflow-auto"}>
            <FilterMenuCheckbox
              textClassName={"text-truncate"}
              onToggle={()=>handleDriverToggled(ChargingDriverFilterOptionKey.NO_ASSIGNED_DRIVER)}
              isChecked={isSelectedUser(ChargingDriverFilterOptionKey.NO_ASSIGNED_DRIVER)}
              label={"No assigned driver"}
            />
            <div className="horizontal-border-line my-1 w-100" />
            {driverOptions.map((driver, ix) => (
              <React.Fragment key={ix}>
                <FilterMenuCheckbox
                  textClassName={"text-truncate"}
                  onToggle={() => handleDriverToggled(driver.id)}
                  isChecked={isSelectedUser(driver.id)}
                  label={getDriverFullName(driver)}
                />
              </React.Fragment>
            ))}
          </div>


        </>
      }
    </FilterEditorCard>
  );
}

export default ChargingFilterDriverSelector;
