import { useCallback, useEffect, useState } from "react";
import { getQueryWithDefaultPagination } from "utils/query.utils";
import { PaginationMeta } from "../../types/api.types";
import { useLocation } from "react-router";
import { OrganizationDepotChargingLocation } from "../../types/depot-charging-location.types";
import { getDepotChargingLocationListAsync } from "../../services/depot-charging-location.service";

export const useDepotChargingLocationListAsync = () => {
  const [list, setList] = useState<Array<OrganizationDepotChargingLocation>>([]);
  const [meta, setMeta] = useState<PaginationMeta | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);

  const { search } = useLocation();


  const getListAsyncCallback = useCallback(
    async (search: string) => {
      try {
        setIsLoading(true);
        const queryString = getQueryWithDefaultPagination(search);
        const res = await getDepotChargingLocationListAsync(queryString);
        setList(res.data);
        setMeta(res.meta);

      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    },
    []
  );


  useEffect(() => {
    getListAsyncCallback(search);
  }, [getListAsyncCallback, search]);


  return { list, meta, isLoading, refetch: () => getListAsyncCallback(search) };
};