import Row from "components/custom/Row";
import { AddVehicleFlowType } from "../../../../types/vehicle.types";
import Col from "../../../custom/Col";
import CenteredHeader from "../../../header/CenteredHeader";
import AddVehicleStarterSelectionCard from "./AddVehicleFlowSelectionCard";
import AddVehicleStarterInfoModal from "./AddVehicleFlowSelectionInfoModal";

type Props = {};

export default function AddVehicleFlowSelection(props: Props) {
  return (
    <div className="d-flex flex-1 flex-grow-1 h-100 w-100 justify-center align-center my-2 flex-column">
      <CenteredHeader title="Add New Vehicle" description="What type of vehicle would you like to add?" />
      <Row className="h-auto w-100">
        <Col md="6" className="justify-center align-items-center flex-column w-100 pr-md-3">
          <AddVehicleStarterSelectionCard type={AddVehicleFlowType.TESLA} />
          <AddVehicleStarterSelectionCard type={AddVehicleFlowType.SMARTCAR} />
          <AddVehicleStarterSelectionCard type={AddVehicleFlowType.MANUAL} />
        </Col>

        <Col md="6" className="justify-start align-items-start flex-column w-100 pl-md-3">
          <AddVehicleStarterSelectionCard type={AddVehicleFlowType.SAMSARA} />
          <AddVehicleStarterSelectionCard type={AddVehicleFlowType.GEOTAB} />
        </Col>
      </Row>
      <div className="mt-4">
        {" "}
        <AddVehicleStarterInfoModal helpButtonText="Need help deciding?" />
      </div>
    </div>
  );
}
