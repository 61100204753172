import Drawer from "@mui/material/Drawer";
import ArchiveButton from "components/button/ArchiveButton";
import ArchiveLinkButton from "components/button/ArchiveLinkButton";
import CancelButton from "components/button/CancelButton";
import Row from "components/custom/Row";
import QuestionMarkIcon from "components/icon/icons/QuestionMarkIcon";
import XIcon from "components/icon/icons/XIcon";
import AlertConfirmDialog from "components/notification/AlertConfirmDialog";
import { BlackBody2Text100, BlackBodyText200, BlackH6Text100 } from "components/text/Text";
import { useAppDispatch, useAppSelector } from "hooks/redux.hooks";
import parse from "html-react-parser";
import { useCallback, useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { setInboxMessageAsArchived, setInboxMessageAsRead, setSelectedInboxMessage } from "redux/slices/inboxSlice";
import { reduceUnreadMessageCount } from "redux/slices/userSlice";
import { archiveInboxMessagesAsync, readInboxMessagesAsync } from "services/inbox.services";
import { InboxMessage, InboxMessageType } from "types/inbox.types";
import { getLocalFormattedDateForTables } from "utils/date.utils";
import { useBindInboxMessageDetailLinks } from "../../hooks/inbox.hooks";
import InboxMessageIcon from "./InboxMessageIcon";

export type InboxDrawerState = { isOpen: boolean; message?: InboxMessage | undefined };

type InboxMessageDrawerProps = {};

export default function InboxMessageDrawer(props: InboxMessageDrawerProps) {
  const message = useAppSelector((state) => state.inbox.selectedMessage);
  const isOpen = !!message;

  const dispatch = useAppDispatch();
  useBindInboxMessageDetailLinks(message);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);

  const isArchived = message?.archived;
  const isRead = message?.read;
  const messageId = message?.id;

  const handleCloseDrawer = useCallback(() => {
    dispatch(setSelectedInboxMessage(null));
  }, [dispatch]);

  const handleReadMessageAsync = useCallback(async () => {
    if (isOpen && messageId && !isRead) {
      try {
        await readInboxMessagesAsync({ ids: [messageId] });
        dispatch(setInboxMessageAsRead(messageId));
        dispatch(reduceUnreadMessageCount());
      } catch (error) {
        console.error(error);
      }
    }
  }, [dispatch, isOpen, isRead, messageId]);

  const handleArchiveMessageAsync = useCallback(async () => {
    if (!isArchived && messageId) {
      try {
        setIsSubmitting(true);
        await archiveInboxMessagesAsync({ ids: [messageId] });
        dispatch(setInboxMessageAsArchived(messageId));
        setIsConfirmDialogOpen(false);
        handleCloseDrawer();
      } catch (error) {
        console.error(error);
      } finally {
        setIsSubmitting(false);
      }
    }
  }, [isArchived, messageId, dispatch, handleCloseDrawer]);


  useEffect(() => {
    handleReadMessageAsync();
  }, [handleReadMessageAsync]);


  return (
    <ErrorBoundary fallback={<>An error occurred</>}>
      <Drawer anchor={"right"} open={isOpen} onClose={handleCloseDrawer}>
        <AlertConfirmDialog
          icon={<QuestionMarkIcon />}
          isDialogOpen={isConfirmDialogOpen}
          title="Are you sure you want to archive this message? "
          description="It will remain accessible for viewing in the 'Archived Messages' tab."
          overrideButtons={
            <Row className="justify-content-end w-100">
              <CancelButton className="mr-2" onClick={() => setIsConfirmDialogOpen(false)} />
              <ArchiveButton onClick={handleArchiveMessageAsync} isLoading={isSubmitting} />
            </Row>
          }
        />
        <div className="w-100 w-max-100 h-100 p-4 w-475px d-flex flex-column">
          <div data-testid="modal-close-button" onClick={handleCloseDrawer} className="modal-close-button">
            <XIcon />
          </div>
          <div className="d-flex flex-column flex-1 flex-grow-1">
            <div>
              <InboxMessageIcon isLarge type={message?.data.__typename as InboxMessageType} level={message?.level} />
            </div>

            <BlackBodyText200 className="my-2">{getLocalFormattedDateForTables(message?.createdAt)}</BlackBodyText200>
            {message?.title && <BlackH6Text100>{parse(message.title)}</BlackH6Text100>}

            {message?.body && (
              <div className="flex-grow-auto py-2 inbox-message-body">
                <BlackBody2Text100>{parse(message.body)}</BlackBody2Text100>
                {/**  <InboxMessageChargingCard /> */}
              </div>
            )}
          </div>

          {!isArchived && (
            <div className=" d-flex align-items-center justify-content-start h-48px ">
              <ArchiveLinkButton onClicked={() => setIsConfirmDialogOpen(true)} />
            </div>
          )}
        </div>
      </Drawer>
    </ErrorBoundary>
  );
}