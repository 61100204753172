import classNames from "classnames";
import UnpaidInvoiceAlertBox from "components/invoice/UnpaidInvoiceAlertBox";
import { useSyncSidebarOrganizationDataWithInterval } from "hooks/menu.hooks";
import { useState } from "react";
import NavbarToggleIcon from "../../components/icon/icons/NavbarToggleIcon";
import { MuiTooltipWithCloseOnHover } from "../../components/tooltip/MuiTooltip";
import Logo from "../logo/Logo";
import Menu from "../menu/Menu";
import Toggle from "./Toggle";

const Sidebar = ({ fixed, theme, className, sidebarToggle, mobileView, ...props }) => {
  const { unpaidInvoiceCount, unreadMessageCount, pendingDriversCount } = useSyncSidebarOrganizationDataWithInterval();
  const [collapseSidebar, setSidebar] = useState(false);
  const [mouseEnter, setMouseEnter] = useState(false);


  const toggleCollapse = () => {
    setSidebar(!collapseSidebar);
  };

  const handleMouseEnter = () => setMouseEnter(true);
  const handleMouseLeave = () => setMouseEnter(false);

  const classes = classNames({
    "nk-sidebar": true,
    "nk-sidebar-fixed": fixed,
    "is-compact": collapseSidebar,
    [`is-light`]: theme === "white",
    [`is-${theme}`]: theme !== "white" && theme !== "light",
    [`${className}`]: className
  });

  return (
    <div className={classes}>
      <div style={{ overflow: "visible" }} className="nk-sidebar-element nk-sidebar-head">
        <div className="nk-sidebar-brand center">
          <Logo isCompact={collapseSidebar} />
        </div>
        <div className="nk-menu-trigger mr-n2">
          <Toggle className="nk-nav-toggle nk-quick-nav-icon d-xl-none mr-n2" icon="arrow-left" click={sidebarToggle} />
        </div>
      </div>
      <div className="nk-sidebar-content " onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <div className="nk-sidebar-menu h-100 overflow-y-auto ">
          <Menu sidebarData={{ unreadMessageCount, pendingDriversCount }} sidebarToggle={sidebarToggle}
                mobileView={mobileView} />
          {unpaidInvoiceCount > 0 && (
            <div className="nk-menu-item p-3">
              <UnpaidInvoiceAlertBox isCollapsed={collapseSidebar} />
            </div>
          )}
        </div>

        <div
          className={`nk-toggle-icon-container p-2  d-none  d-xl-flex ${
            collapseSidebar ? "justify-content-center" : "justify-content-end"
          }`}
        >
          <MuiTooltipWithCloseOnHover title={collapseSidebar ? "Show side bar" : "Collapse side bar"} placement="right">
            <div onClick={toggleCollapse} className="nk-toggle-icon-badge">
              <NavbarToggleIcon
                id="navbar-toggle-icon"
                className={`nk-nav-compact nk-quick-nav-icon d-none d-xl-inline-flex ${
                  collapseSidebar ? "compact-active rotate-180-degree" : ""
                }`}
              />
            </div>
          </MuiTooltipWithCloseOnHover>
        </div>
      </div>
    </div>
  );
};
export default Sidebar;