import React, { Dispatch, SetStateAction, SyntheticEvent, useCallback, useState } from "react";
import { useForm } from "react-hook-form";

import { Auth } from "aws-amplify";
import { BlackH3Text100 } from "../../text/Text";
import AuthFormInput from "./AuthFormInput";
import { handleApiErrorResponse, handleAwsErrorResponse } from "utils";
import { AuthSignUpFormFlowSteps, AuthSignUpFormState } from "types/auth.types";

import { formatPhoneNumberByDialCodeAndNumber } from "utils/format.utils";
import AuthPasswordInputs, { PasswordFormValues, ValidatePasswordRef } from "./AuthPasswordInputs";
import SignUpButton from "components/button/SignUpButton";
import { useTranslation } from "react-i18next";
import { useAlertManager } from "hooks/alert.hooks";

type Props = {
  readonly setFormFlowStep: Dispatch<SetStateAction<AuthSignUpFormFlowSteps>>;
  readonly onFormStateChanged: Dispatch<SetStateAction<AuthSignUpFormState>>;
  readonly registerFormState: AuthSignUpFormState;
};

type Email = {
  readonly email: string;
};

interface FormValues extends PasswordFormValues, Email {
}

function SignUpFormFlowCredentialsStep({ onFormStateChanged, setFormFlowStep, registerFormState }: Props) {
  const { register, handleSubmit, trigger, errors, getValues } = useForm<FormValues>({
    mode: "all"
  });

  const { t } = useTranslation("common");

  const validatePasswordImperativeRef = React.useRef<ValidatePasswordRef>(null);

  const { handleOpenErrorAlert } = useAlertManager();
  const [isLoading, setIsLoading] = useState(false);

  /**
   *
   */
  const SignUpAsync = useCallback(
    async (values: FormValues) => {
      try {
        const isValid = validatePasswordImperativeRef.current?.validatePassword();

        if (!isValid) {
          return;
        }

        setIsLoading(true);
        const phoneNumberWithDialCode = formatPhoneNumberByDialCodeAndNumber(
          registerFormState.dialCode,
          registerFormState.phoneNumber
        );
        const response = await Auth.signUp({
          username: values.email,
          password: values.password.trim(),
          attributes: {
            name: registerFormState.name,
            "custom:company": registerFormState.companyName,
            phone_number: phoneNumberWithDialCode
          },
          autoSignIn: {
            enabled: true
          }
        });
        onFormStateChanged((oldState) => {
          return {
            ...oldState,
            emailAddressCodeSent: { original: values.email, encrypted: response?.codeDeliveryDetails?.Destination }
          };
        });
        setFormFlowStep(AuthSignUpFormFlowSteps.CONFIRMATION);
      } catch (error) {
        handleOpenErrorAlert(handleAwsErrorResponse(error));
      } finally {
        setIsLoading(false);
      }
    },
    [onFormStateChanged, registerFormState, setFormFlowStep, handleOpenErrorAlert]
  );

  /**
   *
   */
  const handleFormSubmit = useCallback(
    async (e: SyntheticEvent<HTMLFormElement, SubmitEvent>) => {
      e.preventDefault();

      await trigger();

      handleSubmit(SignUpAsync)(e);
    },
    [SignUpAsync, handleSubmit, trigger]
  );

  return (
    <form onSubmit={handleFormSubmit}>
      <div className="pb-4">
        <BlackH3Text100>{t("auth.sign_up.flow_steps.credentials.title")}</BlackH3Text100>
      </div>

      <AuthFormInput
        aria-label="email"
        register={register}
        inputMode="email"
        placeholder="Work email address"
        label="Email"
        required
        name="email"
        type="email"
        className="w-max-100 w-100"
        defaultValue={registerFormState.emailAddressCodeSent?.original}
      />

      <AuthPasswordInputs getFormValues={getValues} ref={validatePasswordImperativeRef} register={register} />

      <SignUpButton isDisabled={isLoading} isLoading={isLoading} type="submit" className={"mt-4 w-100"} />
    </form>
  );
}

export default SignUpFormFlowCredentialsStep;
