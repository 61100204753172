import classNames from "classnames";
import { ColProps, Col as ReactstrapCol } from "reactstrap";

// Col with zero padding
export default function Col(props: ColProps) {
  return (
    <ReactstrapCol {...props} className={classNames("p-0", props.className)}>
      {props?.children}
    </ReactstrapCol>
  );
}
